/** @format */

/* eslint-disable no-control-regex */
/* eslint-disable no-useless-escape */

export const emailRegex =
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u0041-\u005A\u0061-\u007A])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u0041-\u005A\u0061-\u007A])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u0041-\u005A\u0061-\u007A])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u0041-\u005A\u0061-\u007A]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u0041-\u005A\u0061-\u007A])|(([a-z]|\d|[\u0041-\u005A\u0061-\u007A])([a-z]|\d|-|\.|_|~|[\u0041-\u005A\u0061-\u007A])*([a-z]|\d|[\u0041-\u005A\u0061-\u007A])))\.)+(([a-z]|[\u0041-\u005A\u0061-\u007A])|(([a-z]|[\u0041-\u005A\u0061-\u007A])([a-z]|\d|-|\.|_|~|[\u0041-\u005A\u0061-\u007A])*([a-z]|[\u0041-\u005A\u0061-\u007A])))$/i;
// export const  emailRegex = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,20})$/i

export const strongPassword =
  /(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,128}/g;

export const numberOnly = /^[0-9]+$/;

export const FULL_NAME_REGEX = /^[a-zA-Z ,.'-]+$/i;

export const FULL_NAME_SPECIAL_SYMBOLS_REGEX =
  /[`!@#$%^&*()_+=[\]{};:"\\|<>/?~]/;

export const ONE_SPACES_REGEX = /\s/;

export const TWO_SPACES_REGEX = /\s{2,}/;

export const NUMBERS_REGEX = /\d/;

export const COMPANY_NAME_REGEX =
  /^[a-zA-Z0-9 ,.\-'!@#$%^&*()_+=`[\]{};:"\\|<>/?~]+$/;

export const APARTMENT_REGEX = /^[a-zA-Z0-9 ]+$/i;

export const CITY_REGEX = /^[a-zA-Z .'-]+$/i;

export const LATIN_LETTERS_ONLY_REGEX = /^[a-zA-Z]+$/i;

export const CARD_NAME_REGEX = /^[a-zA-Z ,'-]+$/i;
