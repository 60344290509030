/** @format */

import { fetchPost, fetchGet } from "../../config/request";
import {
  ADD_PARTNER_URL,
  CLEAR_NOTIFICATIONS,
  CONTACT_US,
  CREATE_PERMISSION_URL,
  EDIT_PERMISSION_URL,
  GET_INVOICE,
  GET_MESSAGE_BY_ORDER_ID,
  GET_NOTIFICATIONS,
  GET_ORDER_BY_ID,
  GET_ORDER_ITEMS_URL,
  GET_ORDER_URL,
  GET_PERMISSIONS_URL,
  GET_ROLES_URL,
  SHARE_ORDER,
  UNSEEN_NOTIFICATION_COUNT,
  UPDATE_NOTIFICATION_COUNT,
  BACK_OFFICE_EDIT,
  BACK_OFFICE_ROLE,
  BACK_OFFICE_DELETE,
  GET_ALL_PARTNERS_URL,
  GET_ALL_ORDERS_URL,
  GET_ORDER_STAT,
  CREATE_CUSTOMER,
  GET_USERS_URL,
  GET_PRICING,
  CREATE_USER_URL,
  UPDATE_CUSTOMER_URL,
  EDIT_USER_URL,
  GET_PARTNER_PERMISSIONS_URL,
  GET_PARTNER_ROLES_URL,
  CREATE_PARTNER_PERMISSION_URL,
  PARTNER_ROLE,
  PARTNER_ROLE_EDIT,
  PARTNER_ROLE_DELETE,
  EDIT_PARTNER_PERMISSION_URL,
  GET_ALL_STATUSES_URL,
  GET_ALL_SERVICE_TYPE_URL,
  DELETE_ORDER_URL,
} from "../../config/Constants";
import {
  getUsersPending,
  getUsersError,
  getUsersSuccess,
  addUserPending,
  addUserError,
  addUserSuccess,
} from "../../actions/HomeActions";
import {
  activeOrdersError,
  activeOrdersPending,
  activeOrdersSuccess,
  getAllOrdersError,
  getAllOrdersPending,
  getAllOrdersSuccess,
  getOrderByIdError,
  getOrderByIdSuccess,
  getOrderInvoiceError,
  getOrderInvoiceSuccess,
  getOrderItems,
  getOrderItemsError,
  getOrderItemsSuccess,
  getOrderStatistics,
  getPricing,
  shareOrderError,
  shareOrderSuccess,
} from "../../actions/MyOrdersActions";

import {
  clearNotificationsError,
  clearNotificationsSuccess,
  getMessagesByOrderIdError,
  getMessagesByOrderIdSuccess,
  getNotificationsError,
  getNotificationsOnClickError,
  getNotificationsOnClickPending,
  getNotificationsOnClickSuccess,
  getNotificationsSuccess,
  getUnseenNotificationCountError,
  getUnseenNotificationCountSuccess,
  setLoadMore,
  updateNotificationCountError,
  updateNotificationCountSuccess,
} from "../../actions/CommonAction";
import { contactUsError, contactUsPending } from "../../actions/ContactActions";
import {
  getRolesError,
  getRolesPending,
  getRolesSuccess,
  rolesError,
  rolesPending,
  rolesSuccess,
  updateRolesError,
  updateRolesPending,
  updateRolesSuccess,
  deleteRolesError,
  deleteRolesPending,
  deleteRolesSuccess,
  getPartnerRolesPending,
  getPartnerRolesError,
  getPartnerRolesSuccess,
} from "../../actions/RolesAction";
import {
  addPermissionError,
  addPermissionPending,
  addPermissionSuccess,
  getPartnerPermissionsError,
  getPartnerPermissionsPending,
  getPartnerPermissionsSuccess,
  getPermissionsError,
  getPermissionsPending,
  getPermissionsSuccess,
} from "../../actions/PermissionsAction";
import {
  addPartnerError,
  addPartnerPending,
  addPartnerSuccess,
  getAllPartnersError,
  getAllPartnersPending,
  getAllPartnersSuccess,
} from "../../actions/PartnerActions";

import {
  addCustomerError,
  addCustomerPending,
  addCustomerSuccess,
  deleteCustomerError,
  deleteCustomerPending,
  deleteCustomerSuccess,
} from "../../actions/CustomerActions";
import {
  getAllStatusesError,
  getAllStatusesPending,
  getAllStatusesSuccess,
} from "../../actions/StatusActions";
import {
  getAllServiceTypeError,
  getAllServiceTypePending,
  getAllServiceTypeSuccess,
} from "../../actions/ServiceTypeActions";
import { updateServiceActionError } from "../../actions/ProfileActions";

export const getOrderDetails = async (dispatch, orderId) => {
  dispatch(getOrderItems());
  const orderData = await fetchGet(
    `${GET_ORDER_ITEMS_URL}?order_id=${orderId}`
  );
  if (orderData?.error) return dispatch(getOrderItemsError(orderData?.error));
  dispatch(getOrderItemsSuccess(orderData));
};

export const shareOrder = async (dispatch, data, orderId) => {
  const orderData = await fetchPost(`${SHARE_ORDER}`, {
    orderId: orderId,
    receiver_email: data.email,
    subject: data.subject,
    from: data.from,
    to: data.to,
  });
  if (orderData?.error) return dispatch(shareOrderError(orderData?.error));
  else {
    dispatch(shareOrderSuccess(orderData));
  }
};

export const getNotifications = async (dispatch, user_id, offset) => {
  const notification = await fetchPost(`${GET_NOTIFICATIONS}`, {
    receiver_id: user_id,
    receiver_type: "customer",
    offset,
    limit: 10,
  });
  if (notification?.error)
    return dispatch(getNotificationsError(notification?.error));
  else {
    dispatch(getNotificationsSuccess(notification));
    if (notification.messages.length === 0) dispatch(setLoadMore(false));
  }
};

export const clearNotifications = async (dispatch, user_id) => {
  const notification = await fetchPost(`${CLEAR_NOTIFICATIONS}`, {
    receiver_id: user_id,
    receiver_type: "customer",
  });
  if (notification?.error)
    return dispatch(clearNotificationsError(notification?.error));
  else {
    dispatch(clearNotificationsSuccess(notification));
  }
};

export const getNotificationsOnClick = async (dispatch, user_id, offset) => {
  dispatch(getNotificationsOnClickPending());
  const notification = await fetchPost(`${GET_NOTIFICATIONS}`, {
    receiver_id: user_id,
    receiver_type: "customer",
    offset,
    limit: 6,
  });
  if (notification?.error)
    return dispatch(getNotificationsOnClickError(notification?.error));
  else {
    dispatch(getNotificationsOnClickSuccess(notification));
    if (notification.messages.length === 0) dispatch(setLoadMore(false));
  }
};

export const updateNotificationCount = async (dispatch, user_id) => {
  const updateNotification = await fetchGet(
    `${UPDATE_NOTIFICATION_COUNT}?receiver_id=${user_id}&receiver_type=customer`
  );
  if (updateNotification?.error)
    return dispatch(updateNotificationCountError(updateNotification?.error));
  else {
    dispatch(updateNotificationCountSuccess(updateNotification));
  }
};

export const getUnseenNotificationCount = async (dispatch, user_id) => {
  const unseenNotification = await fetchGet(
    `${UNSEEN_NOTIFICATION_COUNT}?receiver_id=${user_id}&receiver_type=customer`
  );
  if (unseenNotification?.error)
    return dispatch(getUnseenNotificationCountError(unseenNotification?.error));
  else {
    dispatch(getUnseenNotificationCountSuccess(unseenNotification));
  }
};

export const getMessagesByOrderId = async (dispatch, orderId, offset) => {
  const messagesByOrderId = await fetchGet(
    `${GET_MESSAGE_BY_ORDER_ID}?offset=${offset}&limit=500&order_id=${orderId}`
  );
  if (messagesByOrderId?.error)
    return dispatch(getMessagesByOrderIdError(messagesByOrderId?.error));
  else {
    dispatch(getMessagesByOrderIdSuccess(messagesByOrderId));
  }
};

export const getOrderById = async (dispatch, orderId) => {
  const orderDetail = await fetchGet(`${GET_ORDER_BY_ID}/${orderId}`);
  if (orderDetail.error) dispatch(getOrderByIdError(orderDetail.error));
  else dispatch(getOrderByIdSuccess(orderDetail));
};

export const getAllRoles = async (dispatch, offset, limit) => {
  dispatch(getRolesPending());
  const rolesList = await fetchGet(
    `${GET_ROLES_URL}?offset=${offset}&limit=${limit}`
  );
  if (rolesList.error) dispatch(getRolesError(rolesList.error));
  dispatch(getRolesSuccess(rolesList));
};

export const getAllPartnerRoles = async (dispatch, offset, limit) => {
  dispatch(getPartnerRolesPending());
  const rolesList = await fetchGet(
    `${GET_PARTNER_ROLES_URL}?offset=${offset}&limit=${limit}`
  );
  if (rolesList.error) dispatch(getPartnerRolesError(rolesList.error));
  dispatch(getPartnerRolesSuccess(rolesList));
};

export const getAllPricing = async (dispatch) => {
  const pricing = await fetchGet(`${GET_PRICING}`);
  dispatch(getPricing(pricing));
};

export const getAllBackOfficeUsers = async (dispatch, offset, limit) => {
  dispatch(getUsersPending());
  const usersList = await fetchGet(
    `${GET_USERS_URL}?offset=${offset}&limit=${limit}`
  );
  if (usersList.error) dispatch(getUsersError(usersList.error));
  dispatch(getUsersSuccess(usersList));
};

export const getAllPermission = async (dispatch, offset, limit) => {
  dispatch(getPermissionsPending());
  const permissionList = await fetchGet(
    `${GET_PERMISSIONS_URL}?offset=${offset}&limit=${limit}`
  );
  if (permissionList.error) dispatch(getPermissionsError(permissionList.error));
  dispatch(getPermissionsSuccess(permissionList));
};

export const getAllPartnerPermissions = async (dispatch, offset, limit) => {
  dispatch(getPartnerPermissionsPending());
  const permissionList = await fetchGet(
    `${GET_PARTNER_PERMISSIONS_URL}?offset=${offset}&limit=${limit}`
  );
  if (permissionList.error)
    dispatch(getPartnerPermissionsError(permissionList.error));
  dispatch(getPartnerPermissionsSuccess(permissionList));
};

export const getAllPartners = async (dispatch, offset, limit) => {
  dispatch(getAllPartnersPending());
  const partnersList = await fetchGet(
    `${GET_ALL_PARTNERS_URL}?offset=${offset}&limit=${limit}`
  );
  if (partnersList.error) dispatch(getAllPartnersError(partnersList.error));
  dispatch(getAllPartnersSuccess(partnersList));
};

export const getAllStatuses = async (
  dispatch,
  service_type_id,
  fromHandleType
) => {
  if (!fromHandleType) dispatch(getAllStatusesPending());
  const statusList = await fetchGet(
    `${GET_ALL_STATUSES_URL}?service_id=${service_type_id}`
  );
  if (statusList.error) dispatch(getAllStatusesError(statusList.error));
  dispatch(getAllStatusesSuccess(statusList));
};

export const getAllServiceType = async (dispatch) => {
  dispatch(getAllServiceTypePending());
  const serviceTypeList = await fetchGet(`${GET_ALL_SERVICE_TYPE_URL}`);
  if (serviceTypeList.error)
    dispatch(getAllServiceTypeError(serviceTypeList.error));
  dispatch(getAllServiceTypeSuccess(serviceTypeList));
};

export const getAllOrders = async (dispatch, offset, limit) => {
  dispatch(getAllOrdersPending());
  const ordersList = await fetchGet(
    `${GET_ALL_ORDERS_URL}?offset=${offset}&limit=${limit}`
  );
  if (ordersList.error) dispatch(getAllOrdersError(ordersList.error));
  dispatch(getAllOrdersSuccess(ordersList));
};

export const getOrderStats = async (dispatch) => {
  const orderStats = await fetchGet(`${GET_ORDER_STAT}`);
  if (orderStats.error) return false;
  dispatch(getOrderStatistics(orderStats));
};

export const createPartner = async (dispatch, file, addressValue, state) => {
  delete addressValue.is_default_billing;
  dispatch(addPartnerPending());
  const partner = await fetchPost(ADD_PARTNER_URL, {
    company_image: file,
    company_name: state?.c_name,
    company_email: state?.c_email,
    company_number: state?.c_phone.toString().replace(/[- )(]/g, ""),
    company_fax: state?.c_fax,
    user_name: state?.name,
    user_email: state?.email,
    user_number: state?.phone.toString().replace(/[- )(]/g, ""),
    user_title: state?.title,
    partner_address: addressValue,
  });
  if (partner.error) {
    dispatch(addPartnerError(partner.error));
    return false;
  } else {
    dispatch(addPartnerSuccess(partner));
    return true;
  }
};

export const createCustomer = async (
  dispatch,
  state,
  file,
  wallet,
  pricing,
  status
) => {
  dispatch(addCustomerPending());
  const body = {
    customer_name: state?.customer_name,
    company_name: state?.c_name,
    email: state?.c_email,
    phone_number: state?.c_phone,
    back_office_user_id: JSON.parse(localStorage.getItem("user_data")).id,
    image: file,
    password: state?.password,
    is_wallet_active: wallet === "2" ? true : false,
    pricing_type: pricing.toString(),
    status: status === "2" ? true : false,
  };
  if (body.image === null) {
    delete body.image;
  }
  const customer = await fetchPost(CREATE_CUSTOMER, body);
  if (customer.error) {
    dispatch(addCustomerError(customer.error));
    return false;
  } else {
    dispatch(addCustomerSuccess(customer));
    return true;
  }
};

export const updateCustomer = async (
  dispatch,
  state,
  file,
  wallet,
  pricing,
  status,
  selectedCustomer
) => {
  dispatch(addCustomerPending());
  const body = {
    customer_name: state?.customer_name,
    company_name: state?.c_name,
    email: state?.c_email,
    phone_number: state?.c_phone,
    id: selectedCustomer?.id,
    image: file,
    password: state?.password,
    is_wallet_active: wallet === "2" ? true : false,
    pricing_type: pricing.toString(),
    status: status === "2" ? true : false,
  };
  if (body.image === null) {
    delete body.image;
  }
  const customer = await fetchPost(UPDATE_CUSTOMER_URL, body);
  if (customer.error) {
    dispatch(addCustomerError(customer.error));
    return false;
  } else {
    dispatch(addCustomerSuccess(customer));
    return true;
  }
};

export const getMyActiveOrders = async (dispatch, offset, limit) => {
  dispatch(activeOrdersPending(offset));
  const activeOrderList = await fetchPost(GET_ORDER_URL, {
    customer_id: JSON.parse(localStorage.getItem("user_data")).id,
    isStatusWithComplete: false,
    offset,
    limit,
  });

  if (activeOrderList.error)
    return dispatch(activeOrdersError(activeOrderList.error));
  dispatch(activeOrdersSuccess(activeOrderList));
};

export const getOrderInvoice = async (dispatch, orderId) => {
  const orderDetail = await fetchGet(`${GET_INVOICE}/${orderId}`);

  const file = new Blob([orderDetail], { type: "application/pdf" });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
  if (orderDetail.error) dispatch(getOrderInvoiceError(orderDetail.error));
  else dispatch(getOrderInvoiceSuccess(orderDetail));
};

export async function onDeleteOrder(dispatch, selectedId) {
  try {
    dispatch(deleteCustomerPending());
    const deleteCustomer = await fetchPost(DELETE_ORDER_URL, {
      id: selectedId.toString(),
    });

    if (deleteCustomer.error) {
      dispatch(deleteCustomerError(deleteCustomer.error));
      return false;
    }
    await getAllOrders(dispatch, 0, 20);
    dispatch(deleteCustomerSuccess(deleteCustomer.message));
    return true;
  } catch (e) {
    dispatch(updateServiceActionError(e));
  }
}

export const pendingPaymentFilter = (orders) => {
  return orders?.filter(
    (order) =>
      order?.status?.[order?.status?.length - 1].internal_name ===
      "Pending Payment"
  );
};

export const onContactUs = async (dispatch, state) => {
  dispatch(contactUsPending());
  const body = {
    name: JSON.parse(localStorage.getItem("user_data"))?.customer_name,
    email: state?.email,
    message: state?.query,
    phone: state?.phone?.replace(/[- )(]/g, ""),
  };
  const contact = await fetchPost(`${CONTACT_US}`, body);
  if (contact?.error) {
    dispatch(contactUsError(contact?.error));
    return false;
  } else {
    return true;
  }
};

export const onAddPermissionSubmit = async (dispatch, state, isPartner) => {
  dispatch(addPermissionPending());
  const body = {
    slug: state.slug,
    name: state.name,
  };
  let permission;
  if (isPartner) {
    permission = await fetchPost(`${CREATE_PARTNER_PERMISSION_URL}`, body);
  } else {
    permission = await fetchPost(`${CREATE_PERMISSION_URL}`, body);
  }
  if (permission?.error) {
    dispatch(addPermissionError(permission?.error));
    return false;
  } else {
    dispatch(addPermissionSuccess(permission));
    return true;
  }
};

export const onAddUserSubmit = async (dispatch, state, image, role_list) => {
  dispatch(addUserPending());
  const body = {
    name: state.name,
    number: state.phone.toString().replace(/[- )(]/g, ""),
    email: state.email,
    password: state.password,
    status: true,
    role_list,
    username: state.username,
    image: image,
  };
  if (body.image === null) {
    delete body.image;
  }
  const user = await fetchPost(`${CREATE_USER_URL}`, body);
  if (user?.error) {
    dispatch(addUserError(user?.error));
    return false;
  } else {
    dispatch(addUserSuccess(user));
    return true;
  }
};

export const onEditUserSubmit = async (
  dispatch,
  state,
  image,
  role_list,
  selectedUser
) => {
  dispatch(addUserPending());
  const body = {
    password: state?.password,
    username: state?.username,
    image,
    back_office_user_id: selectedUser?.id,
    name: state?.name,
    number: state?.phone.toString().replace(/[- )(]/g, ""),
    email: state?.email,
    status: true,
    role_list,
  };
  if (body.image === null) {
    delete body.image;
  }
  const user = await fetchPost(`${EDIT_USER_URL}`, body);
  if (user?.error) {
    dispatch(addUserError(user?.error));
    return false;
  } else {
    dispatch(addUserSuccess(user));
    return true;
  }
};

export async function newRole(dispatch, state, sort, isPartner) {
  const body = {
    name: state?.name,
    slug: state?.slug,
    permission_list: sort,
  };
  try {
    dispatch(rolesPending());
    let newRole;
    if (isPartner) {
      newRole = await fetchPost(PARTNER_ROLE, body);
    } else {
      newRole = await fetchPost(BACK_OFFICE_ROLE, body);
    }

    if (newRole.error) return dispatch(rolesError(newRole.error));
    dispatch(rolesSuccess(newRole));
    return true;
  } catch (e) {
    return dispatch(rolesError(e));
  }
}

export async function updateRole(
  dispatch,
  selectedPartnerId,
  state,
  sort,
  id,
  isPartner
) {
  const body = {
    id: id,
    name: state?.name || selectedPartnerId?.name,
    slug: state?.slug || selectedPartnerId?.slug,
    permission_list: sort,
  };
  try {
    dispatch(updateRolesPending());
    let newRole;
    if (isPartner) {
      newRole = await fetchPost(PARTNER_ROLE_EDIT, body);
    } else {
      newRole = await fetchPost(BACK_OFFICE_EDIT, body);
    }

    if (newRole.error) return dispatch(updateRolesError(newRole.error));
    dispatch(updateRolesSuccess(newRole));
    return true;
  } catch (e) {
    return dispatch(updateRolesError(e));
  }
}

export async function deleteRole(dispatch, payload, isPartner) {
  const body = {
    id: payload?.data?.id,
  };
  try {
    dispatch(deleteRolesPending());
    let newRole;
    if (isPartner) {
      newRole = await fetchPost(PARTNER_ROLE_DELETE, body);
    } else {
      newRole = await fetchPost(BACK_OFFICE_DELETE, body);
    }
    if (newRole.error) return dispatch(deleteRolesError(newRole.error));
    dispatch(deleteRolesSuccess(newRole));
  } catch (e) {
    dispatch(deleteRolesError(e));
  }
}

export const onEditPermissionSubmit = async (
  dispatch,
  state,
  selectedPermission,
  isPartner
) => {
  dispatch(addPermissionPending());
  const body = {
    id: selectedPermission.id,
    slug: state.slug,
    name: state.name,
  };
  let permission;
  if (isPartner) {
    permission = await fetchPost(`${EDIT_PARTNER_PERMISSION_URL}`, body);
  } else {
    permission = await fetchPost(`${EDIT_PERMISSION_URL}`, body);
  }

  if (permission?.error) {
    dispatch(addPermissionError(permission?.error));
    return false;
  } else {
    dispatch(addPermissionSuccess(permission));
    return true;
  }
};
