/** @format */

import * as ActionTypes from "./ActionTypes";

export const resetError = () => ({
  type: ActionTypes.RESET_ERROR,
});

export const addCustomerPending = () => ({
  type: ActionTypes.ADD_CUSTOMER_PENDING,
});

export const addCustomerError = (error) => ({
  type: ActionTypes.ADD_CUSTOMER_ERROR,
  error: error,
});

export const addCustomerSuccess = (data) => ({
  type: ActionTypes.ADD_CUSTOMER_SUCCESS,
  data,
});

export const getAllCustomersPending = () => ({
  type: ActionTypes.GET_ALL_CUSTOMERS_PENDING,
});

export const getAllCustomersError = (error) => ({
  type: ActionTypes.GET_ALL_CUSTOMERS_ERROR,
  error: error,
});

export const getAllCustomersSuccess = (data) => ({
  type: ActionTypes.GET_ALL_CUSTOMERS_SUCCESS,
  data,
});

export const deleteCustomerPending = () => ({
  type: ActionTypes.DELETE_CUSTOMER_PENDING,
});

export const deleteCustomerError = (error) => ({
  type: ActionTypes.DELETE_CUSTOMER_ERROR,
  error: error,
});

export const deleteCustomerSuccess = (data) => ({
  type: ActionTypes.DELETE_CUSTOMER_SUCCESS,
  data,
});

export const setSelectedRow = (data) => ({
  type: ActionTypes.SET_SELECTED_ROW,
  data,
});

export const discardSelectedRow = () => ({
  type: ActionTypes.DISCARD_SELECTED_ROW,
});

export const setSelectedCustomer = (data) => ({
  type: ActionTypes.SET_SELECTED_CUSTOMER,
  data,
});

export const discardSelectedCustomer = () => ({
  type: ActionTypes.DISCARD_SELECTED_CUSTOMER,
});

export const setSelectedTab = (data) => ({
  type: ActionTypes.SET_SELECTED_TAB,
  data,
});

export const resetSelectedTab = () => ({
  type: ActionTypes.RESET_SELECTED_TAB,
});

export const setSelectedCustomerTab = (data) => ({
  type: ActionTypes.SET_SELECTED_CUSTOMER_TAB,
  data,
});

export const resetSelectedCustomerTab = () => ({
  type: ActionTypes.RESET_SELECTED_CUSTOMER_TAB,
});

export const getCustomerSuccess = (data) => ({
  type: ActionTypes.GET_CUSTOMERS_SUCCESS,
  data,
});

export const getCustomerOrderSuccess = (data) => ({
  type: ActionTypes.GET_CUSTOMER_ORDER_SUCCESS,
  data,
});

export const getCustomerOrderError = (error) => ({
  type: ActionTypes.GET_CUSTOMER_ORDER_ERROR,
  error,
});
