import {createTheme} from "@mui/material";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 560,
      sm: 768,
      md: 1023,
      lg: 1250,
      xl: 1536,
    },
  },
});
