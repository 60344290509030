/** @format */

import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import * as colors from "../../assets/css/Colors";
import { boxShadow } from "../../config/Constants";
import { useDispatch, useSelector } from "react-redux";
import { CommonError, Loader } from "../GeneralComponents";
import { rolesError } from "../../actions/RolesAction";
import {
  getAllPermission,
  newRole,
  updateRole,
  getAllRoles,
} from "../ordersHistory/services";

import { toast } from "react-toastify";
import {
  VALIDATE_ROLE_NAME,
  VALIDATE_ROLE_SLUG,
  VALIDATE_ROLE,
} from "../../config/validation";
import {
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
  TextField,
} from "@mui/material";
const PartnerInfo = ({ onClose, selectedPartnerId, setSelectedPartner }) => {
  const styles = localStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    getAllPermission(dispatch, 0, 50);
  }, []);
  const [sort, setSort] = useState([]);
  const { permissionList } = useSelector((state) => state.permissions);
  const { roleAddErrors, isLoading } = useSelector((state) => state.roles);
  const [state, setState] = useState({
    slug: "",
    name: "",
    role: "",
    errorMessage: {
      slug: "",
      name: "",
      role: "",
    },
  });

  useEffect(() => {
    if (selectedPartnerId) {
      setState({
        name: selectedPartnerId.name,
        slug: selectedPartnerId.slug,
        role: selectedPartnerId.backOfficePermissions,
        errorMessage: {
          name: "",
          slug: "",
          role: "",
        },
      });
      let selectedIds = [];
      selectedPartnerId.backOfficePermissions.map((permissions) => {
        selectedIds.push(permissions.id);
      });
      setSort(selectedIds);
    }
  }, [selectedPartnerId]);

  const onChange = (key, value) => {
    setState({
      ...state,
      [key]: value,
      errorMessage: { ...state.errorMessage, [key]: "", apiError: "" },
    });
    return true;
  };
  const AddRole = async (id) => {
    checkValidate();
    let response;
    if (state.validation && id) {
      response = await updateRole(
        dispatch,
        selectedPartnerId,
        state,
        sort,
        id,
        false
      );
    } else if (state.validation) {
      response = await newRole(dispatch, state, sort, false);
    }
    if (response === true) {
      toast.success(`Role ${id ? "updated" : "added"}  successfully`);
      onClose();
      setState({
        slug: "",
        name: "",
        role: "",
        errorMessage: {
          slug: "",
          name: "",
          role: "",
        },
      });
      setSelectedPartner(null);
      await getAllRoles(dispatch, 0, 50);
    } else {
      toast.alert("There is an error");
    }
  };
  const checkValidate = () => {
    let errorMessage = { ...state.errorMessage };
    errorMessage.name = VALIDATE_ROLE_NAME(state?.name);
    errorMessage.slug = VALIDATE_ROLE_SLUG(state?.slug);
    errorMessage.role = VALIDATE_ROLE(state?.role);
    if (!sort || sort.length === 0) {
      errorMessage.role = "Please select at least one permission";
    } else {
      errorMessage.role = "";
    }
    setState({ ...state, errorMessage });
    if (
      errorMessage.slug ||
      errorMessage.name ||
      errorMessage.role ||
      !sort ||
      sort.length === 0
    ) {
      state.validation = false;
      return false;
    }
    state.validation = true;
    return true;
  };

  const handleSort = (event) => {
    setSort(event.target.value);
  };
  const closeModal = async () => {
    onClose();
    dispatch(rolesError(""));
  };
  const handleSelectAll = () => {
    setSort(permissionList.map((option) => option.id));
  };

  return (
    <div className={styles.profileBody}>
      <TextField
        placeholder='Input Slug'
        label='Slug'
        fullWidth
        variant='filled'
        color='grey'
        className={styles.input}
        inputProps={{
          maxLength: 25,
        }}
        onChange={(e) => onChange("slug", e.target.value)}
        value={state?.slug}
        error={!!state?.errorMessage?.slug}
        helperText={state?.errorMessage?.slug}
      />
      <TextField
        placeholder='Input Name'
        label='Name'
        fullWidth
        variant='filled'
        color='grey'
        className={styles.input}
        inputProps={{
          maxLength: 25,
        }}
        onChange={(e) => onChange("name", e.target.value)}
        value={state?.name}
        error={!!state?.errorMessage?.name}
        helperText={state?.errorMessage?.name}
      />
      <FormControl
        error={Boolean(state?.errorMessage?.role)}
        className={styles.formcontroller}
        style={{ height: "100px", width: "100%" }}>
        <InputLabel>Select Role</InputLabel>
        <Select
          labelId='select'
          id='select'
          value={sort}
          multiple
          displayEmpty
          onChange={handleSort}
          sx={{ fontSize: "12px", fontWeight: "normal" }}
          className={styles.select}
          error={!!state?.errorMessage?.role}
          helperText={state?.errorMessage?.role}>
          <MenuItem onClick={handleSelectAll}>Select All</MenuItem>
          {permissionList?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
        {state?.errorMessage?.role && (
          <FormHelperText>{state.errorMessage.role}</FormHelperText>
        )}
      </FormControl>
      {isLoading && <Loader />}
      {roleAddErrors && <CommonError type='textError' error={roleAddErrors} />}
      <div style={{ display: "block", textAlign: "center" }}>
        <button
          className={styles.payButton}
          style={{
            background: "#BDBDBD",
          }}
          onClick={closeModal}>
          Cancel
        </button>
        <button
          className={styles.payButton}
          style={{
            marginLeft: "70px",
          }}
          onClick={() => AddRole(selectedPartnerId?.id)}>
          {selectedPartnerId ? "Update" : "Add"}
        </button>
      </div>
    </div>
  );
};
const localStyles = makeStyles((theme) => {
  return {
    input: {
      marginBottom: "20px",
      "& .MuiInputBase-root": {
        boxShadow: boxShadow,
        fontFamily: "Montserrat, sans-serif",
      },
    },
    formcontroller: {
      "& .MuiPaper-root": {
        margin: "100px",
      },
    },
    profilePhoto: {
      borderRadius: "50%",
      border: "4px solid #c90000",
      width: "150px",
      height: "150px",
      boxSizing: "border-box",
      objectFit: "contain",
      cursor: "pointer",
      marginBottom: "30px",
    },
    profileBody: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px 70px",
      [theme.breakpoints.down("md")]: {
        padding: "20px 40px",
      },
      [theme.breakpoints.between("md", "lg")]: {
        padding: "20px 30px",
      },
    },
    select: {
      height: "50px",
      display: "flex",
      alignItems: "center",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "5px",
      },
    },
    payButton: {
      border: "none",
      background: colors.icon_color,
      color: colors.text_white,
      fontSize: "20px",
      padding: "15px",
      width: "175px",
      borderRadius: "5px",
      cursor: "pointer",
      margin: "20px 0",
      ["@media  (max-width: 725px)"]: {
        width: "150px",
        padding: "5px",
      },
    },
  };
});
export default PartnerInfo;
