/** @format */

import * as Actions from "../actions/ActionTypes";
const CommonReducer = (
  state = {
    isLoading: false,
    inputDisabled: true,
    showModal: false,
    showNotesModal: false,
    bannerImageList: [],
    error: undefined,
    isDropdownOpen: false,
    isDropdownSetValue: null,
    formCopy: null,
    formCopyLoader: false,
    formCopyError: undefined,
    cutomerNotes: "",
    privacyPolicyData: null,
    privacyPolicyDataLoader: true,
    privacyPolicyDataError: undefined,
    termsCondData: null,
    termsCondDataLoader: true,
    termsCondDataError: undefined,
    notificationStatus: false,
    screenStatus: false,
    formCopyList: null,
    formCopyListLoader: false,
    formCopyListError: false,
    logoutData: null,
    logoutLoader: false,
    logoutError: undefined,
    notifications: [],
    unseenNotificationCount: null,
    loadMore: true,
    messages: [],
    sendMessageError: undefined,
    receiveMessageError: undefined,
    messageCount: 0,
    msgSeenOfOrderId: undefined,
    openedOrder: undefined,
    roomJoined: false,
    notificationLoader: false,
    activeMenu: "settings:",
  },
  action
) => {
  switch (action.type) {
    case Actions.GET_BANNER_IMAGES:
      return Object.assign({}, state, {
        isLoading: true,
        bannerImageList: [],
        error: undefined,
      });
    case Actions.GET_BANNER_IMAGES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        bannerImageList: action.bannerList,
        error: undefined,
      });
    case Actions.GET_BANNER_IMAGES_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        bannerImageList: [],
        error: action.error,
      });
    case Actions.SET_DROPDOWN_OPEN:
      return Object.assign({}, state, {
        isDropdownOpen: action.isDropdownOpen,
      });
    case Actions.SET_DROPDOWN_VALUE:
      return Object.assign({}, state, {
        isDropdownSetValue: action.isDropdownSetValue,
      });
    case Actions.IS_INPUT_TRUE:
      return Object.assign({}, state, {
        inputDisabled: false,
      });

    case Actions.IS_INPUT_FALSE:
      return Object.assign({}, state, {
        inputDisabled: true,
      });

    case Actions.GET_FORM_COPY_BY_NAME:
      return Object.assign({}, state, {
        formCopy: null,
        formCopyLoader: true,
        formCopyError: undefined,
      });
    case Actions.GET_FORM_COPY_BY_NAME_SUCCESS:
      return Object.assign({}, state, {
        formCopy: action.formCopy,
        formCopyLoader: false,
        formCopyError: undefined,
      });
    case Actions.GET_FORM_COPY_BY_NAME_ERROR:
      return Object.assign({}, state, {
        formCopy: null,
        formCopyLoader: false,
        formCopyError: action.error,
      });
    case Actions.CUSTOMER_NOTES_DATA:
      return Object.assign({}, state, {
        cutomerNotes: action.customerData,
      });
    case Actions.GET_PRIVACY_POLICY:
      return Object.assign({}, state, {
        privacyPolicyData: null,
        privacyPolicyDataLoader: true,
        privacyPolicyDataError: undefined,
      });
    case Actions.GET_PRIVACY_POLICY_SUCCESS:
      return Object.assign({}, state, {
        privacyPolicyData: action.privacyData,
        privacyPolicyDataLoader: false,
        privacyPolicyDataError: undefined,
      });
    case Actions.GET_PRIVACY_POLICY_ERROR:
      return Object.assign({}, state, {
        privacyPolicyData: null,
        privacyPolicyDataLoader: false,
        privacyPolicyDataError: action.error,
      });

    case Actions.GET_TERMS_CONDITION:
      return Object.assign({}, state, {
        termsCondData: null,
        termsCondDataLoader: true,
        termsCondDataError: undefined,
      });
    case Actions.GET_TERMS_CONDITION_SUCCESS:
      return Object.assign({}, state, {
        termsCondData: action.termsData,
        termsCondDataLoader: false,
        termsCondDataError: undefined,
      });
    case Actions.GET_TERMS_CONDITION_ERROR:
      return Object.assign({}, state, {
        termsCondData: null,
        termsCondDataLoader: false,
        termsCondDataError: action.error,
      });
    case Actions.IS_FROM_NOTIFICATION: {
      return Object.assign({}, state, {
        notificationStatus: action.notificationStatus,
      });
    }
    case Actions.GO_TO_ORDER_TAB:
      return Object.assign({}, state, {
        screenStatus: action.screenStatus,
      });
    case Actions.GET_FORM_COPY:
      return Object.assign({}, state, {
        formCopyList: null,
        formCopyListLoader: true,
        formCopyListError: undefined,
      });
    case Actions.GET_FORM_COPY_SUCCESS:
      return Object.assign({}, state, {
        formCopyList: action.formCopyList,
        formCopyListLoader: false,
        formCopyListError: undefined,
      });
    case Actions.GET_FORM_COPY_ERROR:
      return Object.assign({}, state, {
        formCopyList: null,
        formCopyListLoader: false,
        formCopyListError: action.errro,
      });
    case Actions.LOGOUT_API_CALL:
      return Object.assign({}, state, {
        logoutData: null,
        logoutLoader: true,
        logoutError: undefined,
      });
    case Actions.LOGOUT_API_SUCCESS:
      return Object.assign({}, state, {
        logoutData: action.data,
        logoutLoader: false,
        logoutError: undefined,
        notifications: [],
      });
    case Actions.LOGOUT_API_ERROR:
      return Object.assign({}, state, {
        logoutData: null,
        logoutLoader: false,
        logoutError: action.error,
      });
    case Actions.TOGGLE_MODAL:
      return Object.assign({}, state, {
        showModal: !state.showModal,
      });

    case Actions.GET_NOTIFICATIONS_SUCCESS: {
      return Object.assign({}, state, {
        notifications: state.notifications.concat(action.data.messages),
        notificationLoader: false,
      });
    }
    case Actions.GET_NOTIFICATIONS_ON_CLICK_PENDING:
    case Actions.GET_NOTIFICATIONS_PENDING:
      return Object.assign({}, state, {
        notifications: [],
        notificationLoader: true,
      });
    case Actions.GET_NOTIFICATIONS_ON_CLICK_ERROR:
    case Actions.CLEAR_NOTIFICATIONS_SUCCESS:
    case Actions.GET_NOTIFICATIONS_ERROR:
      return Object.assign({}, state, {
        notifications: [],
        notificationLoader: false,
      });
    case Actions.GET_NOTIFICATIONS_ON_CLICK_SUCCESS: {
      return Object.assign({}, state, {
        notifications: action.data.messages,
        notificationLoader: false,
      });
    }
    case Actions.GET_NOTIFICATION_COUNT_SUCCESS:
    case Actions.UPDATE_NOTIFICATION_COUNT_ERROR:
      return Object.assign({}, state, {
        unseenNotificationCount: action.data.count,
      });

    case Actions.GET_NOTIFICATION_COUNT_ERROR:
      return Object.assign({}, state, {
        unseenNotificationCount: null,
      });
    case Actions.UPDATE_NOTIFICATION_COUNT_SUCCESS:
      return Object.assign({}, state, {
        unseenNotificationCount: 0,
      });
    case Actions.GET_MESSAGE_BY_ORDER_ID_ERROR:
    case Actions.GET_MESSAGE_BY_ORDER_ID_PENDING:
      return Object.assign({}, state, {
        messages: [],
        count: 0,
      });
    case Actions.GET_MESSAGE_BY_ORDER_ID_SUCCESS: {
      if (
        JSON.stringify(state.messages) != JSON.stringify(action.data.messages)
      ) {
        return Object.assign({}, state, {
          messages: action.data.messages,
          count: action.data.count,
        });
      }
      return Object.assign({}, state, {
        messages: state.messages,
      });
    }
    case Actions.SEND_MESSAGE_SUCCESS:
      return Object.assign({}, state, {
        messages:
          state.messages.findIndex((item) => item.id === action?.data?.id) ===
          -1
            ? state.messages.concat(action.data)
            : state.messages,
      });
    case Actions.SEND_MESSAGE_ERROR:
      return Object.assign({}, state, {
        messages: state.messages,
        sendMessageError: action.data.message,
      });
    case Actions.CLEAR_NOTIFICATIONS_PENDING:
    case Actions.CLEAR_NOTIFICATIONS_ERROR:
      return Object.assign({}, state, {
        notifications: state.notifications,
      });
    case Actions.SET_LOAD_MORE:
      return Object.assign({}, state, {
        loadMore: action.data,
      });
    case Actions.UPDATE_UNSEEN_MSG_COUNT:
      return Object.assign({}, state, {
        msgSeenOfOrderId: action.data,
      });
    case Actions.OPENED_ORDER:
      return Object.assign({}, state, {
        openedOrder: action.data,
      });
    case Actions.JOIN_ROOM:
      return Object.assign({}, state, {
        roomJoined: true,
      });
    case Actions.LEAVE_ROOM:
      return Object.assign({}, state, {
        roomJoined: false,
      });
    case Actions.SET_ACTIVE_MENU:
      return Object.assign({}, state, {
        activeMenu: action.data,
      });
    default:
      return state;
  }
};

export default CommonReducer;
