/** @format */

import * as ActionTypes from "./ActionTypes";

export const contactUsError = (error) => ({
  type: ActionTypes.CONTACT_US_ERROR,
  error,
});

export const contactUsPending = (error) => ({
  type: ActionTypes.CONTACT_US_PENDING,
  error,
});
