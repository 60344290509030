/** @format */

import moment from "moment";
import {
  GET_USER_URL,
  media_url,
  REACT_APP_MEDIA_URL,
  user_image,
} from "./Constants";
import { fetchGet } from "./request";

export const getUser = async (user_id) => {
  const response = await fetchGet(`${GET_USER_URL}?customerId=${user_id}`);
  await localStorage.setItem("user_data", JSON.stringify(response));
};

export const getExpiryDateFormat = (value) => {
  return value
    .replace(/[^0-9]/g, "")
    .replace(/^([2-9])$/g, "0$1")
    .replace(/^(1{1})([3-9]{1})$/g, "0$1/$2")
    .replace(/^0{1,}/g, "0")
    .replace(/^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, "$1/$2");
};

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const checkExistingNotes = (showEditedOrder, notes) => {
  if (showEditedOrder) {
    return (
      (notes[notes.length - 1].customer_notes != null &&
        notes[notes.length - 1].customer_notes != "") ||
      (notes[notes.length - 1].internal_notes != null &&
        notes[notes.length - 1].internal_notes != "") ||
      (notes[notes.length - 1].partner_notes != null &&
        notes[notes.length - 1].partner_notes != "")
    );
  } else {
    return (
      (notes?.customer_notes != null && notes?.customer_notes != "") ||
      (notes?.internal_notes != null && notes?.internal_notes != "") ||
      (notes?.partner_notes != null && notes?.partner_notes != "")
    );
  }
};

export const checkIfImagesExists = (statusList) => {
  return (
    statusList?.customer_status_images !== null ||
    statusList?.internal_status_images !== null ||
    statusList?.partner_status_images !== null
  );
};

export const checkIfNotesExists = (statusList) => {
  return (
    statusList?.customer_notes ||
    statusList?.internal_notes ||
    statusList?.partner_notes
  );
};

export const getMessageTime = (time) => {
  return new Date(time).toLocaleTimeString(navigator.language, {
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const getProfilePicture = (profile) => {
  if (profile) {
    if (profile?.includes(media_url)) {
      return profile;
    } else {
      return `${REACT_APP_MEDIA_URL}${profile}`;
    }
  }
  return user_image;
};

//
// export const home = async () => {
//   const user_id = await AsyncStorage.getItem("user_id");
//   if (user_id) {
//     await getUser(user_id);
//     return true;
//   }
//   return false;
// };
//
// export function getRegionForCoordinates(points) {
//   // points should be an array of { latitude: X, longitude: Y }
//   let minX, maxX, minY, maxY;
//
//   // init first point
//   (point => {
//     minX = point.latitude;
//     maxX = point.latitude;
//     minY = point.longitude;
//     maxY = point.longitude;
//   })(points[0]);
//
//   // calculate rect
//   points.map(point => {
//     minX = Math.min(minX, point.latitude);
//     maxX = Math.max(maxX, point.latitude);
//     minY = Math.min(minY, point.longitude);
//     maxY = Math.max(maxY, point.longitude);
//   });
//
//   // const midX = (minX + maxX) / 2;
//   // const midY = (minY + maxY) / 2;
//   const deltaX = maxX - minX;
//   const deltaY = maxY - minY;
//   return { latitudeDelta: deltaX, longitudeDelta: deltaY };
// }
//
export const setOrderDetails = (serviceDetails, addressDetails) => {
  const data = localStorage.getItem("order_details_service");
  if (!data) {
    localStorage.setItem(
      "order_details_service",
      JSON.stringify(serviceDetails)
    );
    localStorage.setItem(
      "order_details_address",
      JSON.stringify(addressDetails)
    );
  }
};
//
// export const deleteOrderDetails = async () => {
//   await AsyncStorage.removeItem("order_details_service");
//   await AsyncStorage.removeItem("order_details_address");
// };
//
// export const setOrderDetailsAddress = async addressDetails => {
//   await AsyncStorage.setItem(
//     "order_details_address",
//     JSON.stringify(addressDetails),
//   );
// };
//
// export const formatCardNumber = value => {
//   return value
//     .replace(/[^\dA-Z]/g, "")
//     .replace(/(.{4})/g, "$1 ")
//     .trim();
// };
//
export const getStripeTokeValue = async (props, details) => {
  try {
    let options = {
      type: "Card",
      name: "",
      currency: "usd",
    };
    const response = await props.stripe.createToken(details, options);

    return response;
  } catch (e) {
    return { error: e.message };
  }
};

export const dateFormatter = (date) => {
  const dates = date?.substring(8, 10);
  const month = date?.substring(5, 7);
  const year = date?.substring(0, 4);
  return month + "/" + dates + "/" + year;
};

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

//
// export const checkCardvalue = (cardValue, cardErrorMessage) => {
//   let errorMessage = { ...cardErrorMessage };
//
//   if (cardValue.cardNumber.length !== 19) {
//     errorMessage = { ...errorMessage, cardNumber: strings.invalid_card };
//   }
//   if (!cardValue.cardNumber) {
//     errorMessage = { ...errorMessage, cardNumber: strings.empty_card };
//   }
//   if (cardValue.mm.length !== 2 || cardValue.mm < 1 || cardValue.mm > 12) {
//     errorMessage = { ...errorMessage, mm: strings.invalid_month };
//   }
//   if (!cardValue.mm) {
//     errorMessage = { ...errorMessage, mm: strings.empty_month };
//   }
//   if (
//     cardValue.mm.length === 2 &&
//     cardValue.yy.length === 2 &&
//     cardValue.mm < moment().format("MM") &&
//     cardValue.yy === moment().format("YY")
//   ) {
//     errorMessage = { ...errorMessage, mm: strings.card_exp };
//   }
//
//   if (cardValue.yy.length !== 2) {
//     errorMessage = { ...errorMessage, yy: strings.invalid_year };
//   }
//   if (!cardValue.yy) {
//     errorMessage = { ...errorMessage, yy: strings.empty_year };
//   }
//   if (cardValue.yy.length === 2 && cardValue.yy < moment().format("YY")) {
//     errorMessage = { ...errorMessage, yy: strings.card_exp };
//   }
//   if (cardValue.cvc.length !== 3) {
//     errorMessage = { ...errorMessage, cvc: strings.invalid_cvv };
//   }
//   if (!cardValue.cvc) {
//     errorMessage = { ...errorMessage, cvc: strings.empty_cvv };
//   }
//   if (!cardValue.nameOnCard) {
//     errorMessage = { ...errorMessage, nameOnCard: strings.empty_name_on_card };
//   }
//   if (
//     errorMessage.cardNumber ||
//     errorMessage.mm ||
//     errorMessage.yy ||
//     errorMessage.cvc ||
//     errorMessage.nameOnCard
//   )
//     return { isError: true, errorMessage };
//   return { isError: false, errorMessage: null };
// };
//
export const getLocalTimeZone = (dateTime, format) => {
  const gmtDateTime = moment.utc(dateTime, "YYYY-MM-DD HH:mm");

  return gmtDateTime.local().format(format);
};
//
// export const onCheckAddressValue = (
//   errorMessage,
//   addressValue,
//   isSnackbar = true,
// ) => {
//   const formErrors = {
//     ...errorMessage,
//     city_long_name: strings.empty_city,
//     state_long_name: strings.empty_state,
//     pin_code: strings.pin_code_error,
//     address_name: strings.address_name_error,
//   };
//
//   if (!addressValue.line_1) {
//     formErrors.line_1 = strings.address_line_1_error;
//   }
//
//   formErrors.line_2 = VALIDATE_APARTMENT(addressValue.line_2);
//   formErrors.city_long_name = VALIDATE_CITY(addressValue.city_long_name);
//   formErrors.state_long_name = VALIDATE_STATE(addressValue.state_short_name);
//   formErrors.pin_code = VALIDATE_ZIP_CODE(addressValue.pin_code);
//   formErrors.address_name = VALIDATE_ADDRESS_NAME(addressValue.address_name);
//   formErrors.address_note = VALIDATE_ADDRESS_NOTE(addressValue.address_note);
//
//   for (const formErrorKey of Object.keys(formErrors)) {
//     if (formErrors[formErrorKey]) {
//       return { isError: true, errorMessage: formErrors };
//     }
//   }
//
//   return { isError: false, errorMessage: null };
// };
//
export const formatPhoneNumber = (number) => {
  if (!number) return false;

  number = number.replace(/[^0-9]/g, "");

  const formatNumber = ("" + number).replace(/\D/g, "");
  const match = formatNumber.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  return number;
};
//
// export const HideKeyboard = ({ children }) => (
//   <TouchableWithoutFeedback
//     accessible={false}
//     style={{ flex: 1 }}
//     onPress={() => Keyboard.dismiss()}>
//     <View>{children}</View>
//   </TouchableWithoutFeedback>
// );
//
// export const getDeviceId = async () => {
//   return DeviceInfo.getUniqueId();
// };
//
export const appendZero = (number) => {
  if (!number) return null;
  return number.toString().padStart(3, "0");
};

export const getInitial = (name) => {
  return name
    ?.split(" ")
    .map((word, i) => (i < 2 ? word[0] : ""))
    .join("");
};

export const formatDate = (date) => {
  return moment(date).format("MM/DD/YYYY");
};
