/** @format */

import * as colors from "./assets/css/Colors";
import { bold, normal, semiBold } from "./config/Constants";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      width: "100%",
      backgroundColor: colors.theme_bg,
      fontWeight: normal,
    },
    global_scroll_view: {
      width: "100%",
      height: "100%",
      alignSelf: "center",
      paddingBottom: 120,
      paddingHorizontal: 0,
      margin: 0,
      backgroundColor: colors.theme_bg,
    },
    mainAllCardStyles: {
      borderRadius: 10,
      backgroundColor: colors.theme_bg_three,
      marginBottom: 50,
      marginTop: 20,
      marginHorizontal: 20,
      padding: 20,
    },
    viewBodyGlobal: {
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    btnOuterContainerGlobal: {
      flex: 1,
      flexDirection: "row",
    },
    btnInnerContainerGlobal: {
      width: "100%",
      flex: 1,
      top: 10,
    },
    btnTextGlobal: {
      fontSize: 16,
    },
    btnScndTextGlobal: {
      fontSize: 16,
      color: "#fff",
    },
    singleButtonGlobal: {
      backgroundColor: colors.theme_bg_red,
      color: colors.text_color,
      fontWeight: bold,
      marginBottom: 20,
      paddingVertical: 14,
      alignSelf: "stretch",
      borderRadius: 5,
    },
    mainBtnGlobal: {
      marginTop: 10,
      backgroundColor: colors.theme_bg_red,
      boxShadow: "0px 3px 6px #00000029",
      borderRadius: "5px",
      width: "100%",
      border: "none",
      height: 58,
      padding: "12px",
      color: colors.theme_fg,
      cursor: "pointer",
      fontSize: 20,
      "&:disabled": {
        background: colors.description_text_color,
        color: colors.shopping_cart,
      },
    },
    secondBtnGlobal: {
      marginTop: 20,
      backgroundColor: "#777",
      marginLeft: 15,
      paddingVertical: 14,
      borderRadius: 5,
    },
    paymentListStyles: {
      flex: 1,
      marginLeft: 15,
      marginRight: 15,
    },
    cardListMainStyles: {
      borderRadius: 10,
      backgroundColor: colors.theme_bg_three,
      marginLeft: 15,
      marginRight: 15,
    },
    header: {
      backgroundColor: colors.theme_bg,
    },
    icon: {
      color: colors.theme_fg,
    },
    header_body: {
      flex: 3,
      justifyContent: "center",
    },
    stdText: {
      fontSize: 14,
      color: colors.text_color,
      alignSelf: "center",
    },
    subText: {
      fontSize: 12,
      color: colors.text_color,
    },
    title: {
      alignSelf: "center",
      color: colors.text_color,
      fontSize: 16,
      fontWeight: "bold",
    },
    footer: {
      backgroundColor: colors.theme_bg,
    },
    footer_content: {
      width: "90%",
      justifyContent: "center",
      backgroundColor: colors.theme_bg,
    },
    done: {
      backgroundColor: colors.theme_button,
    },
    body_title: {
      marginTop: 20,
    },
    global_btn_red: {
      backgroundColor: colors.theme_bg_red,
      paddingVertical: 12,
      width: "100%",
    },
    global_btn_title_red: {
      flex: 1,
      color: colors.theme_bg_three,
      textAlign: "center",
    },
    global_btn_theme_bg: {
      backgroundColor: colors.theme_bg,
      padding: 10,
      width: "100%",
    },
    global_btn_title_theme_bg: {
      flex: 1,
      color: colors.text_color,
      textAlign: "center",
    },
    global_scroll_style: {
      width: "100%",
      height: "80%",
    },

    scroll_view_style: {
      width: "100%",
      alignSelf: "center",
      height: "100%",
      backgroundColor: colors.theme_bg,
    },
    errorTest: {
      marginTop: 2,
      padding: 2,
      color: colors.text_red,
    },
    p_10: {
      padding: 10,
    },
    m_30: {
      marginTop: 20,
    },
    m_10: {
      marginTop: 10,
    },
    card_last_item: {
      marginBottom: "240px",
    },
    divider: {
      alignSelf: "center",
      backgroundColor: "#ededed",
      width: "100%",
      height: 3,
      margin: "20px",
      border: "none",
    },
    divider1: {
      alignSelf: "center",
      backgroundColor: "#ededed",
      width: "100%",
      height: 3,
      margin: "10px",
      border: "none",
    },
    common_button_style: {
      backgroundColor: colors.top_red_section,
      width: 300,
      marginBottom: 40,
      padding: 10,
    },
    global_selected_card: {
      backgroundColor: colors.theme_bg_red,
    },
    global_selected_text: {
      color: colors.text_white,
    },
    global_text: {
      color: colors.text_color,
    },
    global_button_view: {
      flexDirection: "row",
      padding: 10,
      width: "80%",
      alignSelf: "center",
    },
    googleSSOBtn: {
      backgroundColor: "#4C8BF5",
      marginBottom: 20,
      flexDirection: "row",
      alignItems: "center",
      borderColor: "#fff",
      height: 76,
      boxShadow: "0px 3px 6px #00000029",
      borderRadius: "5px",
      width: "100%",
      borderWidth: 1,
      display: "flex",
      cursor: "pointer",
      [theme.breakpoints.down("md")]: {
        height: "58px",
      },
    },
    modalContainer: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0,0.5)",
      height: "100%",
    },
    modalHeader: {
      fontSize: 16,
      fontWeight: "bold",
      marginBottom: 10,
    },
    inputHeader: {
      color: colors.top_red_section,
      fontWeight: bold,
      fontSize: 28,
    },
    arrow: {
      position: "absolute",
      top: "20px",
      left: "20px",
      cursor: "pointer",
    },
    modal: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "400px",
      background: colors.theme_fg,
      borderRadius: "5px",
      position: "absolute",
      outline: "none",
      padding: "40px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        width: "270px",
      },
    },
    modalHead: {
      fontWeight: semiBold,
      fontSize: "20px",
      marginBottom: "20px",
    },
    modalButtons: {
      marginTop: "20px",
      display: "flex",
    },
    yesButton: {
      padding: "15px 50px",
      background: colors.icon_color,
      color: colors.text_white,
      cursor: "pointer",
      border: "none",
      borderRadius: "5px",
      marginRight: "20px",
      fontSize: "20px",
    },
    noButton: {
      padding: "15px 50px",
      background: colors.dark_gray,
      color: colors.text_white,
      cursor: "pointer",
      border: "none",
      borderRadius: "5px",
      fontSize: "20px",
    },
    tabs: {
      "& .MuiTab-root": {
        fontWeight: semiBold,
        fontSize: "24px",
        lineHeight: "35px",
        color: colors.text_white,
        textTransform: "none",
        [theme.breakpoints.between("md", "lg")]: {
          fontSize: "16px",
        },
      },
      "& .MuiTab-root.Mui-selected": {
        color: colors.text_white,
      },
      "& .MuiTabs-indicator": {
        background: colors.text_white,
      },
      background: colors.icon_color,
      boxShadow: "0px 3px 6px #00000029",
      borderRadius: "5px 5px 0 0",
      position: "relative",
      zIndex: 2,
    },
    logoCard: {
      alignContent: "center",
      alignItems: "center",
      marginTop: "-140px",
      marginBottom: "20px",
      [theme.breakpoints.down("md")]: {
        marginTop: "-115px",
        marginBottom: 0,
      },
    },
  };
});

export default useStyles;
