/** @format */

import * as ActionTypes from "./ActionTypes";

export const serviceActionPending = () => ({
  type: ActionTypes.HOME_SERVICE_PENDING,
});

export const serviceActionError = (error) => ({
  type: ActionTypes.HOME_SERVICE_ERROR,
  error: error,
});

export const serviceActionSuccess = (data) => ({
  type: ActionTypes.HOME_SERVICE_SUCCESS,
  data: data,
});

export const serviceActiveOrdersPending = (offset) => ({
  type: ActionTypes.HOME_ACTIVE_ORDERS_PENDING,
  offset,
});

export const serviceActiveOrdersError = (error) => ({
  type: ActionTypes.HOME_ACTIVE_ORDERS_ERROR,
  error: error,
});

export const serviceActiveOrdersSuccess = (data) => ({
  type: ActionTypes.HOME_ACTIVE_ORDERS_SUCCESS,
  data: data,
});

export const getMainParentId = (mainParentId) => ({
  type: ActionTypes.MAIN_PARENT_ID,
  mainParentId,
});

export const setTabValue = (data) => ({
  type: ActionTypes.SET_TAB_VALUE,
  data,
});
export const resetTabValue = () => ({
  type: ActionTypes.RESET_TAB_VALUE,
});

export const addUserPending = () => ({
  type: ActionTypes.ADD_USER_PENDING,
});

export const addUserError = (error) => ({
  type: ActionTypes.ADD_USER_ERROR,
  error: error,
});

export const addUserSuccess = (data) => ({
  type: ActionTypes.ADD_USER_SUCCESS,
  data,
});

export const getUsersPending = () => ({
  type: ActionTypes.GET_USERS_PENDING,
});

export const getUsersError = (error) => ({
  type: ActionTypes.GET_USERS_ERROR,
  error: error,
});

export const getUsersSuccess = (data) => ({
  type: ActionTypes.GET_USERS_SUCCESS,
  data,
});

export const deleteUserPending = () => ({
  type: ActionTypes.DELETE_USER_PENDING,
});

export const deleteUserError = (error) => ({
  type: ActionTypes.DELETE_USER_ERROR,
  error: error,
});

export const deleteUserSuccess = (data) => ({
  type: ActionTypes.DELETE_USER_SUCCESS,
  data,
});

export const clearAddUserError = () => ({
  type: ActionTypes.CLEAR_ADD_USER_ERROR,
});

export const addPartnerUserPending = () => ({
  type: ActionTypes.ADD_PARTNER_USER_PENDING,
});

export const addPartnerUserSuccess = (data) => ({
  type: ActionTypes.ADD_PARTNER_USER_SUCCESS,
  data,
});

export const addPartnerUserError = (error) => ({
  type: ActionTypes.ADD_PARTNER_USER_ERROR,
  error,
});
