/** @format */

import * as ActionTypes from "./ActionTypes";

export const getPermissionsPending = () => ({
  type: ActionTypes.GET_PERMISSIONS_PENDING,
});

export const getPermissionsError = (error) => ({
  type: ActionTypes.GET_PERMISSIONS_ERROR,
  error: error,
});

export const getPermissionsSuccess = (data) => ({
  type: ActionTypes.GET_PERMISSIONS_SUCCESS,
  data,
});

export const getPartnerPermissionsPending = () => ({
  type: ActionTypes.GET_PARTNER_PERMISSIONS_PENDING,
});

export const getPartnerPermissionsError = (error) => ({
  type: ActionTypes.GET_PARTNER_PERMISSIONS_ERROR,
  error: error,
});

export const getPartnerPermissionsSuccess = (data) => ({
  type: ActionTypes.GET_PARTNER_PERMISSIONS_SUCCESS,
  data,
});

export const addPermissionPending = () => ({
  type: ActionTypes.ADD_PERMISSION_PENDING,
});

export const addPermissionError = (error) => ({
  type: ActionTypes.ADD_PERMISSION_ERROR,
  error: error,
});

export const addPermissionSuccess = (data) => ({
  type: ActionTypes.ADD_PERMISSION_SUCCESS,
  data,
});

export const deletePermissionPending = () => ({
  type: ActionTypes.DELETE_PERMISSION_PENDING,
});

export const deletePermissionError = (error) => ({
  type: ActionTypes.DELETE_PERMISSION_ERROR,
  error: error,
});

export const deletePermissionSuccess = (data) => ({
  type: ActionTypes.DELETE_PERMISSION_SUCCESS,
  data,
});

export const deletePrtnerPermissionPending = () => ({
  type: ActionTypes.DELETE_PARTNER_PERMISSION_PENDING,
});

export const deletePrtnerPermissionError = (error) => ({
  type: ActionTypes.DELETE_PARTNER_PERMISSION_ERROR,
  error: error,
});

export const deletePrtnerPermissionSuccess = (data) => ({
  type: ActionTypes.DELETE_PARTNER_PERMISSION_SUCCESS,
  data,
});
