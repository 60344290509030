/** @format */

import * as ActionTypes from "./ActionTypes";

export const discardErrors = () => ({
  type: ActionTypes.DISCARD_ERRORS,
});

export const addServiceTypePending = () => ({
  type: ActionTypes.ADD_SERVICE_TYPE_PENDING,
});

export const addServiceTypeError = (error) => ({
  type: ActionTypes.ADD_SERVICE_TYPE_ERROR,
  error: error,
});

export const addServiceTypeSuccess = (data) => ({
  type: ActionTypes.ADD_SERVICE_TYPE_SUCCESS,
  data,
});

export const getAllServiceTypePending = () => ({
  type: ActionTypes.GET_ALL_SERVICE_TYPE_PENDING,
});

export const getAllServiceTypeError = (error) => ({
  type: ActionTypes.GET_ALL_SERVICE_TYPE_ERROR,
  error: error,
});

export const getAllServiceTypeSuccess = (data) => ({
  type: ActionTypes.GET_ALL_SERVICE_TYPE_SUCCESS,
  data,
});

export const deleteServiceTypePending = () => ({
  type: ActionTypes.DELETE_SERVICE_TYPE_PENDING,
});

export const deleteServiceTypeError = (error) => ({
  type: ActionTypes.DELETE_SERVICE_TYPE_ERROR,
  error: error,
});

export const deleteServiceTypeSuccess = (data) => ({
  type: ActionTypes.DELETE_SERVICE_TYPE_SUCCESS,
  data,
});
