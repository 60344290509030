/** @format */

import { isInputFalse } from "../../actions/CommonAction";
import {
  profileErrorMessage,
  updateCustomerError,
  updateCustomerSuccess,
  updateProfilePictureError,
  updateProfilePictureSuccess,
  updateServiceActionError,
  updateServiceActionPending,
  updateServiceActionSuccess,
} from "../../actions/ProfileActions";
import {
  CREATE_ADDRESS_URL,
  CREATE_SERVICE_AREA_REQUEST_URL,
  DELETE_ADDRESS,
  DELETE_CARD_URL,
  GET_BILLING_ADDRESS_URL,
  GET_CUSTOMER_ADDRESS,
  GET_USER_URL,
  RESET_PASSWORD_URL,
  FORGOT_PASSWORD_URL,
  UPDATE_CUSTOMER,
  UPLOAD_PROFILE_IMAGE,
  DELETE_PERMISSION_URL,
  DELETE_USER_URL,
  DELETE_PARTNER_PERMISSION_URL,
  UPDATE_PARTNER_USER,
  ADD_PARTNER_USER,
  GET_PARTNER_USERS_URL,
  DELETE_PARTNER_USER,
  GET_PARTNER_PROFILE,
  UPDATE_PARTNER,
  temp_hours,
  ADD_PARTNER_ADDRESS,
} from "../../config/Constants";
import { getUser } from "../../config/Helper";
import {
  fetchDelete,
  fetchGet,
  fetchPatch,
  fetchPost,
} from "../../config/request";
import {
  onSubmitResetRequestError,
  onSubmitResetRequestSuccess,
  resetPasswordValueError,
} from "../../actions/ResetPasswordActions";
import {
  getAddressValue,
  getBillingAddress,
  getBillingAddressError,
  getBillingAddressSuccess,
  serviceActionPending,
  serviceActionError,
  serviceActionSuccess,
  editServiceActionPending,
  editServiceActionError,
  editServiceActionSuccess,
  partnerProfileError,
  partnerProfileSuccess,
} from "../../actions/AddressActions";
import {
  listServiceActionError,
  listServiceActionPending,
  listServiceActionSuccess,
} from "../../actions/AddressListActions";

import {
  requestServiceAreaError,
  requestServiceAreaPending,
  requestServiceAreaSuccess,
} from "../../actions/ServicesAction";
import {
  getAllBackOfficeUsers,
  getAllPartnerPermissions,
  getAllPartners,
  getAllPermission,
} from "../ordersHistory/services";
import {
  deletePermissionError,
  deletePermissionPending,
  deletePermissionSuccess,
} from "../../actions/PermissionsAction";
import {
  addPartnerUserError,
  addPartnerUserPending,
  addPartnerUserSuccess,
  deleteUserError,
  deleteUserPending,
  deleteUserSuccess,
} from "../../actions/HomeActions";
import {
  deletePartnerUsersError,
  deletePartnerUsersPending,
  deletePartnerUsersSuccess,
  getPartnerUsersError,
  getPartnerUsersPending,
  getPartnerUsersSuccess,
} from "../../actions/RolesAction";

export async function getSignedLink(dispatch, response, profileError) {
  try {
    const imageUri = await fetchPost(UPLOAD_PROFILE_IMAGE, {
      customer_id: JSON.parse(localStorage.getItem("user_data"))?.id,
      file: {
        base64: response.url.split(",")[1],
        fileName: response.file.name,
        type: response.file.type,
      },
    });
    if (imageUri.error) {
      dispatch(
        profileErrorMessage({
          ...profileError,
          api_error: imageUri.error,
        })
      );
      return dispatch(updateProfilePictureError());
    }
    await getUser(JSON.parse(localStorage.getItem("user_data"))?.id);
    dispatch(updateProfilePictureSuccess());
  } catch (e) {
    dispatch(
      profileErrorMessage({
        ...profileError,
        api_error: "some error",
      })
    );
    return e;
  }
}

export async function updateProfile(
  dispatch,
  profileInput,
  profileError,
  selectedPartner
) {
  try {
    const body = {
      email: profileInput?.org_email
        ? profileInput?.org_email
        : selectedPartner?.email,
      phone_number: profileInput?.org_phone
        ? profileInput?.org_phone.replace(/\D+/g, "")
        : selectedPartner?.phone_number.replace(/\D+/g, ""),
      delivery_boy_name: profileInput?.org_name
        ? profileInput?.org_name
        : selectedPartner?.delivery_boy_name,
    };
    const update = await fetchPatch(
      `${UPDATE_PARTNER}/${selectedPartner?.id}`,
      body
    );

    if (update?.error) {
      dispatch(
        profileErrorMessage({
          ...profileError,
          api_error: update?.error,
        })
      );
      dispatch(updateServiceActionError(update?.error));
      return false;
    }

    await getAllPartners(dispatch, 0, 20);
    dispatch(isInputFalse());
    dispatch(updateServiceActionSuccess());
    return true;
  } catch (e) {
    dispatch(
      profileErrorMessage({
        ...profileError,
        api_error: "some error",
      })
    );
    return dispatch(updateServiceActionError(e));
  }
}

export async function updateCustomerProfile(
  dispatch,
  userData,
  companyName,
  phoneNumber
) {
  try {
    const body = {
      customer_id: userData.id,
      customer_name: userData.customer_name,
      phone_number: phoneNumber,
      email: userData.email,
      company_name: companyName,
    };

    const update = await fetchPost(UPDATE_CUSTOMER, body);
    if (update.error) {
      dispatch(updateCustomerError());
    } else {
      const response = await fetchGet(
        `${GET_USER_URL}?customerId=${userData.id}`
      );

      localStorage.setItem("user_data", JSON.stringify(response));
      dispatch(
        updateCustomerSuccess({
          customer_id: userData.id,
          customer_name: userData.customer_name,
          phone_number: phoneNumber,
          email: userData.email,
          company_name: companyName,
        })
      );
      return true;
    }
  } catch (e) {
    return { error: e };
  }
}

export async function submitResetPassword(
  dispatch,
  formData,
  resetPasswordError
) {
  try {
    const body = {
      password: formData.new_password,
      confirmPassword: formData.confirm_password,
      customer_id: JSON.parse(localStorage.getItem("user_data")).id,
    };
    const reqResetPassword = await fetchPost(RESET_PASSWORD_URL, body);
    if (reqResetPassword.error) {
      dispatch(onSubmitResetRequestError(reqResetPassword.error));
      dispatch(
        resetPasswordValueError({
          ...resetPasswordError,
          api_error: reqResetPassword.error,
        })
      );
      return false;
    }
    dispatch(onSubmitResetRequestSuccess(reqResetPassword.message));
    return true;
  } catch (e) {
    dispatch(onSubmitResetRequestError(e));
    dispatch(
      resetPasswordValueError({
        ...resetPasswordError,
        api_error: "some error",
      })
    );
    return e;
  }
}

export async function submitForgotPassword(
  dispatch,
  formData,
  resetPasswordError,
  token
) {
  try {
    const body = {
      password: formData.new_password,
      code: token,
    };
    const reqResetPassword = await fetchPost(FORGOT_PASSWORD_URL, body);
    if (reqResetPassword.error) {
      dispatch(onSubmitResetRequestError(reqResetPassword.error));
      dispatch(
        resetPasswordValueError({
          ...resetPasswordError,
          api_error: reqResetPassword.error,
        })
      );
      return false;
    }
    dispatch(onSubmitResetRequestSuccess(reqResetPassword.message));
    return true;
  } catch (e) {
    dispatch(onSubmitResetRequestError(e));
    dispatch(
      resetPasswordValueError({
        ...resetPasswordError,
        api_error: "some error",
      })
    );
    return e;
  }
}

export async function getCustomerBillingAddress(dispatch, user_id) {
  try {
    dispatch(getBillingAddress());
    const response = await fetchGet(
      `${GET_BILLING_ADDRESS_URL}?customer_id=${user_id}`
    );
    if (response.error) dispatch(getBillingAddressError(response.error));
    else dispatch(getBillingAddressSuccess(response));
  } catch (e) {
    dispatch(getBillingAddressError(e));
    return { error: e };
  }
}

export async function getAddressList(dispatch, isFromCheckout = false) {
  try {
    dispatch(listServiceActionPending());
    const addressList = await fetchGet(
      `${GET_CUSTOMER_ADDRESS}?customer_id=${
        JSON.parse(localStorage.getItem("user_data")).id
      }`
    );

    if (addressList.error) dispatch(listServiceActionError(addressList.error));
    else {
      if (isFromCheckout) {
        addressList[addressList.length - 1].isSelected = true;
        addressList[addressList.length - 1].isUpdated = true;
        dispatch(getAddressValue(addressList[addressList.length - 1]));
      }
      dispatch(listServiceActionSuccess(addressList));
    }
  } catch (e) {
    dispatch(listServiceActionError(e));
    return { error: e };
  }
}

export async function onRequestArea(dispatch, state) {
  try {
    dispatch(requestServiceAreaPending());
    const body = {
      state: state?.stateAddress,
      city: state?.city,
      notes: state?.notes,
      customer_id: JSON.parse(localStorage.getItem("user_data")).id,
    };

    const requestArea = await fetchPost(CREATE_SERVICE_AREA_REQUEST_URL, body);

    if (requestArea.error) {
      dispatch(requestServiceAreaError(requestArea.error));
      return false;
    } else {
      dispatch(requestServiceAreaSuccess(requestArea));

      return true;
    }
  } catch (e) {
    return dispatch(serviceActionError(e));
  }
}

export async function onSaveAddress(
  dispatch,
  addressValue,
  isBilling,
  customer,
  addCustomerAddress,
  customerDetails,
  addressState,
  selectedPartner
) {
  try {
    dispatch(serviceActionPending());

    delete addressValue.is_default_billing;

    let body;
    let saveAddress;

    if (!addCustomerAddress) {
      let serviceHours = [];
      addressState.working_hours.forEach((item) => {
        const tempItem = {
          ...item,
          opening_hours:
            item.opening_hours != ""
              ? item.opening_hours.toISOString().split("T")[1].slice(0, 5)
              : "",
          closing_hours:
            item.closing_hours != ""
              ? item.closing_hours.toISOString().split("T")[1].slice(0, 5)
              : "",
        };
        serviceHours.push(tempItem);
      });

      body = {
        ...addressValue,
        address_name: addressValue.address_name || "",
        address_note: addressValue.address_note || "",
        partner_id: selectedPartner.id,
        line_2: addressValue.line_2 || "",
        service_hours: serviceHours,
      };
      saveAddress = await fetchPost(ADD_PARTNER_ADDRESS, body);
    } else {
      body = {
        ...addressValue,
        address_name: addressValue.address_name || "",
        address_note: addressValue.address_note || "",
        customer_id: JSON.parse(
          localStorage.getItem("user_data")
        ).id.toString(),
        is_default_billing: isBilling,
        line_2: addressValue.line_2 || "",
      };

      saveAddress = await fetchPost(CREATE_ADDRESS_URL, body);
    }

    if (saveAddress.error) {
      dispatch(serviceActionError(saveAddress.error));
      return false;
    } else {
      await getAddressList(dispatch, isBilling);

      if (isBilling) {
        await getCustomerBillingAddress(
          dispatch,
          JSON.parse(localStorage.getItem("user_data")).id.toString()
        );
      }

      dispatch(serviceActionSuccess(saveAddress.message));

      return true;
    }
  } catch (e) {
    return dispatch(serviceActionError(e));
  }
}

export async function onUpdateAddress(dispatch, addressValue) {
  dispatch(editServiceActionPending());

  let service_hours = [];
  addressValue.serviceHours.forEach((item) => {
    const tempItem = {
      ...item,
      opening_hours:
        typeof item.opening_hours === "object"
          ? item.opening_hours.toISOString().split("T")[1].slice(0, 5)
          : item.opening_hours,
      closing_hours:
        typeof item.closing_hours === "object"
          ? item.closing_hours.toISOString().split("T")[1].slice(0, 5)
          : item.closing_hours,
    };

    delete tempItem.service_location_id;
    delete tempItem.created_at;
    delete tempItem.updated_at;

    service_hours.push(tempItem);
  });
  let update = await fetchPatch(`${"serviceLocation"}`, {
    location_id: addressValue.address_id,
    address_name: addressValue.address_name,
    line_1: addressValue.line_1,
    line_2: addressValue.line_2 || "",
    state_long_name: addressValue.state_long_name,
    state_short_name: addressValue.state_short_name,
    city_long_name: addressValue.city_long_name,
    pin_code: addressValue.pin_code,
    address_note: addressValue.address_note,
    address: addressValue.address,
    latitude: addressValue.latitude,
    longitude: addressValue.longitude,
    city_short_name: addressValue.city_short_name,
    country_long_name: addressValue.country_long_name,
    country_short_name: addressValue.country_short_name,
    service_hours: service_hours,
  });

  if (update.error) {
    return dispatch(editServiceActionError(update.error));
  }
  await getAddressList(dispatch, false);
  if (addressValue.is_default_billing)
    getCustomerBillingAddress(
      dispatch,
      JSON.parse(localStorage.getItem("user_data")).id.toString()
    );
  dispatch(editServiceActionSuccess(update.message));
}

export async function onDeleteAddress(dispatch, addressId) {
  try {
    dispatch(updateServiceActionPending());
    const deleteAddress = await fetchPost(DELETE_ADDRESS, {
      address_id: addressId.toString(),
    });

    if (deleteAddress.error)
      return dispatch(updateServiceActionError(deleteAddress.error));
    await getAddressList(dispatch, false);
    dispatch(updateServiceActionSuccess(deleteAddress.message));
  } catch (e) {
    dispatch(updateServiceActionError(e));
  }
}

export async function onDeletePermission(dispatch, selectedId) {
  try {
    dispatch(deletePermissionPending());
    const deletePermission = await fetchPost(DELETE_PERMISSION_URL, {
      id: selectedId.toString(),
    });

    if (deletePermission.error) {
      dispatch(deletePermissionError(deletePermission.error));
      return false;
    }
    await getAllPermission(dispatch, 0, 50);
    dispatch(deletePermissionSuccess(deletePermission.message));
    return true;
  } catch (e) {
    dispatch(updateServiceActionError(e));
  }
}

export async function getPartnerProfile(dispatch, selectedPartner) {
  try {
    const partnerProfile = await fetchGet(
      `${GET_PARTNER_PROFILE}?partner_id=${selectedPartner?.id}`
    );
    if (partnerProfile.error) {
      dispatch(partnerProfileError(partnerProfile.error));
    } else {
      partnerProfile.address.map((address) => {
        if (address.serviceHours.length === 0) {
          address.serviceHours = temp_hours;
        }
      });
      dispatch(partnerProfileSuccess(partnerProfile));
    }
  } catch (e) {
    return dispatch(serviceActionError(e));
  }
}

export const getAllPartnerUsers = async (dispatch, selectedPartner) => {
  dispatch(getPartnerUsersPending());
  const usersList = await fetchGet(
    `${GET_PARTNER_USERS_URL}?partner_id=${selectedPartner?.id}`
  );

  if (usersList.error) dispatch(getPartnerUsersError(usersList.error));
  dispatch(getPartnerUsersSuccess(usersList.data));
};

export async function onDeletePartnerUser(dispatch, selectedUser) {
  try {
    dispatch(deletePartnerUsersPending());
    const deletePartnerUser = await fetchDelete(
      `${DELETE_PARTNER_USER}/${selectedUser.id}`
    );
    if (deletePartnerUser.error) {
      dispatch(deletePartnerUsersError(deletePartnerUser.error));
      return false;
    } else {
      dispatch(deletePartnerUsersSuccess());
      return true;
    }
  } catch (e) {
    dispatch(updateServiceActionError(e));
  }
}

export const addNewPartnerUser = async (
  dispatch,
  permissions_list,
  role_id,
  state,
  selectedPartner
) => {
  dispatch(addPartnerUserPending());
  const body = {
    name: state?.customer_name,
    email: state?.email,
    number: state?.phone_number?.toString().replace(/[- )(]/g, ""),
    partner_id: selectedPartner?.id,
    role_id,
    permissions_list,
  };
  const partnerUser = await fetchPost(ADD_PARTNER_USER, body);
  if (partnerUser.error) {
    dispatch(addPartnerUserError(partnerUser.error));
    return false;
  }
  dispatch(addPartnerUserSuccess(partnerUser));
  return true;
};

export const updatePartnerUser = async (
  dispatch,
  permissions_list,
  role_id,
  state,
  selectedUser
) => {
  dispatch(addPartnerUserPending());
  const body = {
    name: state?.customer_name,
    email: state?.email,
    number: state?.phone_number?.toString().replace(/[- )(]/g, ""),
    partner_user_id: selectedUser?.id,
    role_id,
    permissions_list,
  };
  const partnerUser = await fetchPost(UPDATE_PARTNER_USER, body);
  if (partnerUser.error) {
    dispatch(addPartnerUserError(partnerUser.error));
    return false;
  }
  dispatch(addPartnerUserSuccess(partnerUser));
  return true;
};

export async function onDeletePartnerPermission(dispatch, selectedId) {
  try {
    dispatch(deletePermissionPending());
    const deletePermission = await fetchPost(DELETE_PARTNER_PERMISSION_URL, {
      id: selectedId.toString(),
    });

    if (deletePermission.error) {
      dispatch(deletePermissionError(deletePermission.error));
      return false;
    }
    await getAllPartnerPermissions(dispatch, 0, 20);
    dispatch(deletePermissionSuccess(deletePermission.message));
    return true;
  } catch (e) {
    dispatch(updateServiceActionError(e));
  }
}

export async function onDeleteUser(dispatch, selectedId) {
  try {
    dispatch(deleteUserPending());
    const deleteUser = await fetchPost(DELETE_USER_URL, {
      user_id: selectedId.toString(),
    });

    if (deleteUser.error) {
      dispatch(deleteUserError(deleteUser.error));
      return false;
    }
    await getAllBackOfficeUsers(dispatch, 0, 20);
    dispatch(deleteUserSuccess(deleteUser.message));
    return true;
  } catch (e) {
    dispatch(updateServiceActionError(e));
  }
}

export async function onDeleteCard(dispatch, stripeId) {
  try {
    dispatch(updateServiceActionPending());
    const deleteCard = await fetchPost(DELETE_CARD_URL, {
      stripeCardId: stripeId,
      customer_id: JSON.parse(localStorage.getItem("user_data")).id,
    });

    if (deleteCard.error)
      return dispatch(updateServiceActionError(deleteCard.error));
    dispatch(updateServiceActionSuccess(deleteCard.message));
  } catch (e) {
    dispatch(updateServiceActionError(e));
  }
}
