/** @format */

import * as Actions from "../actions/ActionTypes";
const MyOrdersReducer = (
  state = {
    isLoading: false,
    error: undefined,
    orderList: [],
    message: undefined,
    status: undefined,
    filter_type: 0,
    orderId: undefined,
    orderItems: null,
    orderStatusLoader: false,
    orderStatusList: [],
    tabPage: 0,
    orderCount: 0,
    notificationOrderId: undefined,
    activeOrder: [],
    orderError: undefined,
    activeOrderLoading: false,
    successMessage: null,
    sharedOrder: null,
    orderDetail: undefined,
    originalOrder: undefined,
    originalOrderItems: undefined,
    orderInvoiceError: undefined,
    orderPayment: undefined,
    changeStatusError: undefined,
    changeStatusLoader: false,
    walletError: undefined,
    cardError: undefined,
    newOrder: 0,
    pendingOrder: 0,
    completedOrder: 0,
  },
  action
) => {
  switch (action.type) {
    case Actions.MYORDERS_LIST_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        error: undefined,
        orderList: action.offset === 0 ? [] : [...state.orderList],
      });
    case Actions.MYORDERS_LIST_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });
    case Actions.MYORDERS_LIST_SUCCESS: {
      let order = [...state.orderList];
      order = order.concat(action.data.getOrder);
      return Object.assign({}, state, {
        isLoading: false,
        orderList: order,
        orderCount: action.data.count,
        error: undefined,
      });
    }
    case Actions.FILTER_TYPE:
      return Object.assign({}, state, {
        filter_type: action.data,
      });
    case Actions.GET_ORDER_ID:
      return Object.assign({}, state, {
        orderId: action.orderId,
      });
    case Actions.GET_ORDER_ITEMS:
      return Object.assign({}, state, {
        isLoading: true,
        orderItems: null,
        error: undefined,
      });
    case Actions.GET_ORDER_ITEMS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        orderItems: action.data,
        error: undefined,
      });
    case Actions.GET_ORDER_ITEMS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        orderItems: null,
        error: action.error,
      });
    case Actions.GET_ORDER_STATUS:
      return Object.assign({}, state, {
        orderStatusLoader: true,
        orderStatusList: [],
        error: undefined,
      });

    case Actions.GET_ORDER_STATUS_SUCCESS:
      return Object.assign({}, state, {
        orderStatusLoader: false,
        orderStatusList: action.orderStatusList,
        error: undefined,
      });
    case Actions.GET_ORDER_STATUS_ERROR:
      return Object.assign({}, state, {
        orderStatusLoader: false,
        orderStatusList: [],
        error: action.error,
      });
    case Actions.ORDER_TAB_PAGE:
      return Object.assign({}, state, {
        tabPage: action.page,
      });
    case Actions.NOTIFICATION_ORDER_ID:
      return Object.assign({}, state, {
        notificationOrderId: action.notificationOrderId,
      });
    case Actions.ACTIVE_ORDERS_PENDING:
      return Object.assign({}, state, {
        activeOrderLoading: true,
        activeOrder: action.offset === 0 ? [] : [...state.activeOrder],
        orderError: undefined,
        activeOrderCount: action.offset === 0 ? 0 : state.activeOrderCount,
      });
    case Actions.ACTIVE_ORDERS_SUCCESS: {
      let order = [...state.activeOrder];
      order = order.concat(action.data.getOrder);
      return Object.assign({}, state, {
        activeOrderLoading: false,
        activeOrder: order,
        orderError: undefined,
        activeOrderCount: action.data.count,
      });
    }
    case Actions.ACTIVE_ORDERS_ERROR:
      return Object.assign({}, state, {
        activeOrderLoading: false,
        orderError: action.error,
        activeOrder: [],
      });
    case Actions.SHARE_ORDER_PENDING:
    case Actions.SHARE_ORDER_ERROR:
      return Object.assign({}, state, {
        successMessage: null,
      });
    case Actions.SHARE_ORDER_SUCCESS:
      return Object.assign({}, state, {
        sharedOrder: action.data,
        successMessage: "Your order has been successfully shared",
      });
    case Actions.GET_ORDER_BY_ID_PENDING:
    case Actions.GET_ORDER_BY_ID_ERROR:
      return Object.assign({}, state, {
        orderDetail: undefined,
      });
    case Actions.GET_ORDER_BY_ID_SUCCESS: {
      return Object.assign({}, state, {
        orderDetail: action.data.order,
        originalOrder: action?.data?.originalOrder,
        originalOrderItems: action.data.originalOrderItems,
        orderPayment: action.data.orderPayment,
      });
    }
    case Actions.GET_ORDER_INVOICE_SUCCESS:
      return Object.assign({}, state, {
        orderInvoiceError: undefined,
      });
    case Actions.GET_ORDER_INVOICE_ERROR:
      return Object.assign({}, state, {
        orderInvoiceError: action.error,
      });
    case Actions.CHANGE_ORDER_STATUS_PENDING:
      return Object.assign({}, state, {
        changeStatusLoader: true,
      });
    case Actions.CHANGE_ORDER_STATUS_ERROR:
      return Object.assign({}, state, {
        changeStatusError: action.error,
        changeStatusLoader: false,
      });
    case Actions.CHANGE_ORDER_STATUS_SUCCESS:
      return Object.assign({}, state, {
        changeStatusError: undefined,
        changeStatusLoader: false,
      });
    case Actions.GET_WALLET_ERROR:
      return Object.assign({}, state, {
        walletError: action.error,
      });
    case Actions.CARD_PAYMENT_ERROR:
      return Object.assign({}, state, {
        cardError: action.error,
      });
    case Actions.ORDER_STATUS_CHANGED: {
      const orderIndex = state.activeOrder.findIndex(
        (item) => item.order_id === action?.data?.order_id
      );
      state.activeOrder[orderIndex] = action.data;
      return Object.assign({}, state, {
        activeOrder: state.activeOrder,
      });
    }
    case Actions.NEW_ORDER_CREATED:
      state.activeOrder.unshift(action.data);
      return Object.assign({}, state, {
        activeOrder: state.activeOrder,
      });

    case Actions.GET_ALL_ORDERS_PENDING: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }
    case Actions.GET_ALL_ORDERS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        orderCount: action.data.count,
        orderList: action.data.orders,
      });
    case Actions.GET_ALL_ORDERS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        orderList: action.error,
      });
    case Actions.GET_ORDER_STATS:
      return Object.assign({}, state, {
        newOrder: action.data.today,
        pendingOrder: action.data.pending,
        completedOrder: action.data.completed,
      });
    case Actions.GET_PRICING:
      return Object.assign({}, state, {
        pricing: action.data,
      });
    default:
      return state;
  }
};

export default MyOrdersReducer;
