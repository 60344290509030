/** @format */

import * as ActionTypes from "./ActionTypes";

export const serviceActionPending = () => ({
  type: ActionTypes.CREATE_ADDRESS_PENDING,
});

export const serviceActionError = (error) => ({
  type: ActionTypes.CREATE_ADDRESS_ERROR,
  error: error,
});

export const serviceActionSuccess = (data) => ({
  type: ActionTypes.CREATE_ADDRESS_SUCCESS,
  data: data,
});

export const editServiceActionPending = () => ({
  type: ActionTypes.EDIT_ADDRESS_PENDING,
});

export const editServiceActionError = (error) => ({
  type: ActionTypes.EDIT_ADDRESS_ERROR,
  error: error,
});

export const editServiceActionSuccess = (data) => ({
  type: ActionTypes.EDIT_ADDRESS_SUCCESS,
  data: data,
});

export const updateServiceActionPending = () => ({
  type: ActionTypes.UPDATE_ADDRESS_PENDING,
});

export const updateServiceActionError = (error) => ({
  type: ActionTypes.UPDATE_ADDRESS_ERROR,
  error: error,
});

export const updateServiceActionSuccess = (data) => ({
  type: ActionTypes.UPDATE_ADDRESS_SUCCESS,
  data: data,
});

export const getAddressValue = (value) => ({
  type: ActionTypes.GET_ADDRESS_VALUES,
  value,
});

export const getAddressId = (addressId) => ({
  type: ActionTypes.GET_ADDRESS_ID,
  addressId,
});

export const getAddressIdSuccess = (addressData) => ({
  type: ActionTypes.GET_ADDRESS_ID_SUCCESS,
  addressData,
});

export const getAddressIdError = (error) => ({
  type: ActionTypes.GET_ADDRESS_ID_ERROR,
  error,
});

export const setShowAdditionalDetails = (isShow) => ({
  type: ActionTypes.SHOW_ADDITIONALDETAILS,
  isShow,
});

export const setErrorMessage = (errorMessage) => ({
  type: ActionTypes.SET_ERROR_MESSAGE,
  errorMessage,
});

export const getBillingAddress = () => ({
  type: ActionTypes.GET_BILLING_ADDRESS,
});
export const getBillingAddressSuccess = (billingAddress) => ({
  type: ActionTypes.GET_BILLING_ADDRESS_SUCCESS,
  billingAddress,
});
export const getBillingAddressError = (error) => ({
  type: ActionTypes.GET_BILLING_ADDRESS_ERROR,
  error,
});

export const clearAddressValue = () => ({
  type: ActionTypes.CLEAR_ADDRESS_VALUE,
});

export const partnerProfileSuccess = (data) => ({
  type: ActionTypes.PARTNER_PROFILE_SUCCESS,
  data,
});

export const partnerProfileError = (error) => ({
  type: ActionTypes.PARTNER_PROFILE_ERROR,
  error,
});

export const toggleWorkingDay = (data) => ({
  type: ActionTypes.TOGGLE_WORKING_DAY,
  data,
});

export const setClosingHours = (data) => ({
  type: ActionTypes.SET_CLOSING_HOURS,
  data,
});

export const setOpeningHours = (data) => ({
  type: ActionTypes.SET_OPENING_HOURS,
  data,
});

export const editWorkingDay = (data) => ({
  type: ActionTypes.EDIT_WORKING_DAY,
  data,
});

export const editClosingHours = (data) => ({
  type: ActionTypes.EDIT_CLOSING_HOURS,
  data,
});

export const editOpeningHours = (data) => ({
  type: ActionTypes.EDIT_OPENING_HOURS,
  data,
});

export const clearWorkingHours = () => ({
  type: ActionTypes.CLEAR_WORKING_HOURS,
});
