/** @format */

import * as Actions from "../actions/ActionTypes";
const HomeReducer = (
  state = {
    isLoading: false,
    error: undefined,
    data: [],
    activeOrder: [],
    message: undefined,
    status: undefined,
    mainParentId: undefined,
    orderError: undefined,
    activeOrderLoading: false,
    usersList: [],
    userAddErrors: undefined,
    usersCount: 0,
    deleteUserLoading: false,
    userDeleteErrors: undefined,
    addPermissionLoading: false,
    addUserErrors: undefined,
    addUserError: undefined,
    addUserLoading: false,
  },
  action
) => {
  switch (action.type) {
    case Actions.HOME_SERVICE_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        data: [],
      });
    case Actions.HOME_SERVICE_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        data: [],
      });
    case Actions.HOME_SERVICE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        data: action.data,
      });
    case Actions.HOME_ACTIVE_ORDERS_PENDING:
      return Object.assign({}, state, {
        activeOrderLoading: true,
        activeOrder: action.offset === 0 ? [] : [...state.activeOrder],
        orderError: undefined,
        activeOrderCount: action.offset === 0 ? 0 : state.activeOrderCount,
      });
    case Actions.HOME_ACTIVE_ORDERS_SUCCESS: {
      let order = [...state.activeOrder];
      order = order.concat(action.data.getOrder);
      return Object.assign({}, state, {
        activeOrderLoading: false,
        activeOrder: order,
        orderError: undefined,
        activeOrderCount: action.data.count,
      });
    }
    case Actions.HOME_ACTIVE_ORDERS_ERROR:
      return Object.assign({}, state, {
        activeOrderLoading: false,
        orderError: action.error,
        activeOrder: [],
      });
    case Actions.MAIN_PARENT_ID:
      return Object.assign({}, state, {
        mainParentId: action.mainParentId,
      });
    case Actions.SET_TAB_VALUE:
      return Object.assign({}, state, {
        tabValue: action.data,
      });

    case Actions.RESET_TAB_VALUE:
      return Object.assign({}, state, {
        tabValue: undefined,
      });
    case Actions.GET_USERS_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        userAddErrors: undefined,
        usersList: [],
        usersCount: 0,
      });
    case Actions.GET_USERS_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        userAddErrors: undefined,
        usersList: action.data.users,
        usersCount: action.data.count,
      });
    }
    case Actions.GET_USERS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        userAddErrors: action.error,
        usersList: [],
        usersCount: 0,
      });
    case Actions.DELETE_USER_PENDING: {
      return Object.assign({}, state, {
        deleteUserLoading: true,
      });
    }
    case Actions.DELETE_USER_SUCCESS:
      return Object.assign({}, state, {
        deleteUserLoading: false,
      });
    case Actions.DELETE_USER_ERROR:
      return Object.assign({}, state, {
        deleteUserLoading: false,
        userDeleteErrors: action.error,
      });
    case Actions.ADD_USER_PENDING: {
      return Object.assign({}, state, {
        addPermissionLoading: true,
      });
    }
    case Actions.ADD_USER_SUCCESS:
      return Object.assign({}, state, {
        addPermissionLoading: false,
      });
    case Actions.ADD_USER_ERROR:
      return Object.assign({}, state, {
        addPermissionLoading: false,
        addUserErrors: action.error,
      });
    case Actions.CLEAR_ADD_USER_ERROR:
      return Object.assign({}, state, {
        addUserError: undefined,
      });
    case Actions.ADD_PARTNER_USER_PENDING:
      return Object.assign({}, state, {
        addUserLoading: true,
      });
    case Actions.ADD_PARTNER_USER_SUCCESS:
      return Object.assign({}, state, {
        addUserLoading: false,
        addUserError: undefined,
      });
    case Actions.ADD_PARTNER_USER_ERROR:
      return Object.assign({}, state, {
        addUserLoading: false,
        addUserError: action.error,
      });
    default:
      return state;
  }
};

export default HomeReducer;
