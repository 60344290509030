/** @format */

import * as Actions from "../actions/ActionTypes";
const ServiceTypeReducer = (
  state = {
    isLoading: false,
    serviceTypeAddErrors: undefined,
    serviceTypeList: [],
    getAllServiceTypeLoading: false,
    getAllServiceTypeErrors: undefined,
    deleteServiceTypeLoading: false,
    serviceTypeDeleteErrors: undefined,
  },
  action
) => {
  switch (action.type) {
    case Actions.ADD_SERVICE_TYPE_PENDING: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }
    case Actions.DISCARD_ERRORS: {
      return Object.assign({}, state, {
        serviceTypeAddErrors: undefined,
        getAllServiceTypeErrors: undefined,
        serviceTypeDeleteErrors: undefined,
      });
    }
    case Actions.ADD_SERVICE_TYPE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case Actions.ADD_SERVICE_TYPE_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        serviceTypeAddErrors: action.error,
      });
    case Actions.GET_ALL_SERVICE_TYPE_PENDING: {
      return Object.assign({}, state, {
        getAllServiceTypeLoading: true,
      });
    }
    case Actions.GET_ALL_SERVICE_TYPE_SUCCESS:
      return Object.assign({}, state, {
        getAllServiceTypeLoading: false,
        serviceTypeList: action.data,
      });
    case Actions.GET_ALL_SERVICE_TYPE_ERROR:
      return Object.assign({}, state, {
        getAllServiceTypeLoading: false,
        getAllServiceTypeErrors: action.error,
      });
    case Actions.DELETE_SERVICE_TYPE_PENDING: {
      return Object.assign({}, state, {
        deleteServiceTypeLoading: true,
      });
    }
    case Actions.DELETE_SERVICE_TYPE_SUCCESS:
      return Object.assign({}, state, {
        deleteServiceTypeLoading: false,
      });
    case Actions.DELETE_SERVICE_TYPE_ERROR:
      return Object.assign({}, state, {
        deleteServiceTypeLoading: false,
        serviceTypeDeleteErrors: action.error,
      });
    default:
      return state;
  }
};

export default ServiceTypeReducer;
