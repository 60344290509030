import * as ActionTypes from "./ActionTypes";

export const checkIsAuth = () => ({
  type: ActionTypes.IS_AUTH,
});

export const checkIsAuthSuccess = () => ({
  type: ActionTypes.IS_AUTH_SUCCESS,
});

export const checkIsAuthError = () => ({
  type: ActionTypes.IS_AUTH_ERROR,
});

export const getInitScreenName=(screenName)=>({
  type: ActionTypes.INIT_SCREEN_NAME,
  screenName
})
