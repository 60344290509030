/** @format */

/* eslint-disable quotes */

import React, { useContext, useEffect, useState } from "react";

import InfiniteScroll from "react-infinite-scroller";

import { makeStyles } from "@mui/styles";
import {
  smallLogo99,
  normal,
  IMAGE_URL,
  no_notifications,
  logout_alert,
} from "../../config/Constants";
import { useNavigate } from "react-router-dom";
import * as colors from "../../assets/css/Colors";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Avatar, Badge, Modal, Popover } from "@mui/material";
import { checkIsAuthError } from "../../actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { appendZero, getInitial } from "../../config/Helper";
import { auth } from "../../firebase";
import { signOut } from "firebase/auth";
import { resetProfileData } from "../../actions/ProfileActions";
import { DPFContext } from "../../context";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import MobileMenu from "./MobileMenu";
import {
  clearNotifications,
  getNotifications,
  getNotificationsOnClick,
  getUnseenNotificationCount,
  updateNotificationCount,
} from "../ordersHistory/services";
import Tooltip from "@mui/material/Tooltip";

import { setErrorValue } from "../../actions/LoginActions";
import { setActiveMenu } from "../../actions/CommonAction";
import { setTabValue } from "../../actions/HomeActions";
import { Loader } from "../GeneralComponents";

import useStyles from "../../GlobalStyle";

const Header = ({ socket }) => {
  const dispatch = useDispatch();
  const styles = localStyles();
  const GlobalStyles = useStyles();

  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user_data"));
  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const {
    notifications,
    unseenNotificationCount,
    loadMore,
    notificationLoader,
    activeMenu,
  } = useSelector((state) => state.common);

  const {
    setAccount,
    setOrderDetails,
    setOrdersSort,
    setSsoError,
    account,
    setContact,
    thanksModal,
    setThanksModal,
  } = useContext(DPFContext);
  const matches = useMediaQuery("(max-width:1023px)");
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    if (window.location.href.includes("/orders")) {
      dispatch(setActiveMenu("orders"));
    } else if (window.location.href.includes("/settings")) {
      dispatch(setActiveMenu("settings"));
    } else if (window.location.href.includes("/customers")) {
      dispatch(setActiveMenu("customers"));
    } else if (window.location.href.includes("/partners")) {
      dispatch(setActiveMenu("partners"));
    } else {
      dispatch(setActiveMenu("settings"));
    }
  }, [window.location.href]);

  const showUserMenu = (event) => {
    setUserAnchorEl(event.currentTarget);
  };

  const closeUserMenu = () => {
    setUserAnchorEl(null);
  };

  const showNotificationList = (event) => {
    updateNotificationCount(dispatch, localStorage.getItem("user_id"));
    getNotificationsOnClick(dispatch, localStorage.getItem("user_id"), 0);
    setNotificationAnchorEl(event.currentTarget);
  };

  const closeNotificationList = () => {
    setNotificationAnchorEl(null);
  };

  useEffect(() => {
    getNotifications(dispatch, localStorage.getItem("user_id"), 0);
    getUnseenNotificationCount(dispatch, localStorage.getItem("user_id"));
  }, []);

  const userMenu = Boolean(userAnchorEl);
  const userId = userMenu ? "user-popover" : undefined;
  const notificationList = Boolean(notificationAnchorEl);
  const notificationId = notificationList ? "notification-popover" : undefined;

  const openChatBubble = () => {
    setContact(true);
    setAccount(null);
    setOrderDetails(null);
    setOrdersSort(null);
    closeMenu();
    closeUserMenu();
  };

  const logOut = async () => {
    socket?.disconnect();
    await signOut(auth);
    dispatch(setErrorValue());
    setSsoError(null);
    dispatch(resetProfileData());
    dispatch(checkIsAuthError());

    localStorage.removeItem("orderOpened");
    localStorage.removeItem("previouslyOpened");
    localStorage.removeItem("user_data");
    localStorage.removeItem("order_details_service");
    localStorage.removeItem("order_details_address");
    localStorage.removeItem("user_id");
    localStorage.removeItem("customer_name");
    localStorage.removeItem("id");
    toast.success(logout_alert);
  };

  const openAccount = () => {
    navigate("/dashboard");
    setOrderDetails(null);
    setAccount(true);
    closeMenu();
    closeUserMenu();
  };

  const closeMenu = () => {
    setOpenMenu(false);
  };

  const goToMainPage = () => {
    navigate("/dashboard");
    setAccount(null);
    dispatch(setActiveMenu(null));
  };

  const loadMoreData = (page) => {
    getNotifications(dispatch, localStorage.getItem("user_id"), 10 * page);
  };

  const handleClearNotifications = () => {
    clearNotifications(dispatch, localStorage.getItem("user_id"));
  };

  const openChatModule = (type, id) => {
    if (type === "CHAT_NOTIFICATION") {
      if (window.location.href.includes("/new")) navigate("/dashboard");
      setOrderDetails(id);
      closeNotificationList();
      dispatch(setTabValue(2));
    }
  };

  const messagePreview = (notification) => {
    return notification?.message?.messageType === "text" ? (
      `${notification?.message?.message}`
    ) : (
      <img
        src={notification?.message?.message}
        alt='Shared a file'
        className={styles.imgDiv}></img>
    );
  };
  const navigateUrl = (menu) => {
    navigate(`/${menu}`);
    dispatch(setActiveMenu(menu));
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftBlock}>
          <div className={styles.logo} onClick={goToMainPage}>
            <Tooltip title='Home'>
              <img src={smallLogo99} alt='' width={matches ? 60 : 100} />
            </Tooltip>
          </div>
          {!matches && (
            <>
              <div
                className={
                  activeMenu === "orders" ? styles.focus : styles.headerMenuList
                }
                onClick={() => navigateUrl("orders")}>
                <span>Orders</span>
              </div>
              <div
                className={
                  activeMenu === "customers"
                    ? styles.focus
                    : styles.headerMenuList
                }
                onClick={() => navigateUrl("customers")}>
                <span>Customers</span>
              </div>
              <div
                className={
                  activeMenu === "partners"
                    ? styles.focus
                    : styles.headerMenuList
                }
                onClick={() => navigateUrl("partners")}>
                <span>Partners</span>
              </div>
              <div
                className={
                  activeMenu === "settings"
                    ? styles.focus
                    : styles.headerMenuList
                }
                onClick={() => navigateUrl("settings")}>
                <span>Settings</span>
              </div>
            </>
          )}
        </div>
        <div className={styles.rightBlock}>
          <div className={styles.notificationsIcon}>
            <Badge
              badgeContent={unseenNotificationCount}
              classes={{ badge: styles.badgeSize }}>
              <Tooltip title='Notifications'>
                <NotificationsIcon
                  sx={{
                    height: "40px",
                    width: "40px",
                    color: colors.theme_fg,
                  }}
                  onClick={showNotificationList}
                />
              </Tooltip>
            </Badge>
            <Popover
              id={notificationId}
              open={notificationList}
              anchorEl={notificationAnchorEl}
              onClose={closeNotificationList}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              sx={{ top: "5px" }}
              className={styles.notificationPaper}>
              <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={(page) => loadMoreData(page)}
                hasMore={loadMore}
                useWindow={false}>
                <div className={styles.notifications}>
                  {notificationLoader ? (
                    <Loader />
                  ) : notifications.length > 0 ? (
                    notifications?.map((notification) => (
                      <div
                        key={notification?.id}
                        className={styles.notification}
                        style={
                          notification?.notification_type ===
                          "CHAT_NOTIFICATION"
                            ? { cursor: "pointer" }
                            : {}
                        }
                        onClick={() =>
                          openChatModule(
                            notification?.notification_type,
                            notification?.order_id
                          )
                        }>
                        <NotificationsIcon />
                        <div className={styles.notificationInfo}>
                          <span className={styles.notificationName}>
                            {notification?.notification_type === "ORDER_CREATE"
                              ? "ORDER CREATED"
                              : notification?.notification_type ===
                                "CHAT_NOTIFICATION"
                              ? `NEW CHAT (Order #${
                                  notification?.order_id
                                }${appendZero(notification?.order?.order_id)})`
                              : "ORDER UPDATED"}
                          </span>
                          <span
                            className={styles.notificationDescription}
                            style={
                              notification?.notification_type ===
                              "CHAT_NOTIFICATION"
                                ? notification?.message?.messageType === "text"
                                  ? {}
                                  : { height: "150px" }
                                : {}
                            }>
                            {notification?.notification_type === "ORDER_CREATE"
                              ? `Your order #${
                                  notification?.order_id
                                }${appendZero(
                                  notification?.order?.order_id
                                )} has been successfully created`
                              : notification?.notification_type ===
                                "CHAT_NOTIFICATION"
                              ? messagePreview(notification)
                              : `Your order #${
                                  notification?.order_id
                                }${appendZero(
                                  notification?.order?.order_id
                                )} has been successfully updated`}
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className={styles.noNotification}>{no_notifications}</p>
                  )}
                </div>
              </InfiniteScroll>
              {notifications.length > 0 && (
                <p
                  className={styles.clearNotification}
                  onClick={() => {
                    handleClearNotifications();
                  }}>
                  Clear Notifications
                </p>
              )}
            </Popover>
          </div>
          {matches ? (
            <>
              <MenuIcon
                fontSize={"large"}
                sx={{ color: colors.theme_fg }}
                onClick={() => setOpenMenu(!openMenu)}
              />
              <MobileMenu
                openMenu={openMenu}
                closeMenu={closeMenu}
                openAccount={openAccount}
                logOut={logOut}
              />
            </>
          ) : (
            <>
              <div className={styles.userInfo} onClick={showUserMenu}>
                <Avatar
                  variant='rounded'
                  src={`${IMAGE_URL}${user?.profile_picture}`}
                  sx={{ width: "40px", height: "40px" }}>
                  {getInitial(user.name)}
                </Avatar>
                <div className={styles.info}>
                  <span className={styles.name}>{user?.name}</span>
                  {user.company && <span>{user.company.name}</span>}
                </div>
                <ArrowDropDownIcon className={userAnchorEl && styles.active} />
              </div>
              <Popover
                id={userId}
                open={userMenu}
                anchorEl={userAnchorEl}
                onClose={closeUserMenu}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                sx={{ top: "5px" }}>
                <div className={styles.userMenu}>
                  <span
                    onClick={openAccount}
                    className={styles.userMenuItem}
                    style={account ? { backgroundColor: "#c9c0c0" } : {}}>
                    Account
                  </span>
                  <span onClick={closeUserMenu} className={styles.userMenuItem}>
                    <a
                      href={"https://www.99dpf.com/"}
                      target='_blank'
                      rel='noreferrer'>
                      FAQ
                    </a>
                  </span>
                  <span
                    onClick={openChatBubble}
                    className={styles.userMenuItem}
                    style={{ paddingRight: 10 }}>
                    $99DPF Support
                  </span>
                  <span onClick={logOut} className={styles.userMenuItem}>
                    Log out
                  </span>
                </div>
              </Popover>
            </>
          )}
        </div>
      </div>
      <Modal open={thanksModal} onClose={() => setThanksModal(false)}>
        <div className={GlobalStyles.modal}>
          <CheckCircleIcon style={{ color: "green", fontSize: "64px" }} />
          <span className={styles.mainTitle}>We have received your email</span>
          <span className={styles.subTitle}>
            Our agent will contact you shortly on your provided email address
          </span>
        </div>
      </Modal>
    </>
  );
};

const localStyles = makeStyles((theme) => {
  return {
    container: {
      height: 80,
      background: colors.top_red_section,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingRight: "40px",
      fontWeight: normal,
      boxShadow: "0px 3px 6px #00000029",
      position: "sticky",
      top: 0,
      zIndex: 5,
      [theme.breakpoints.down("md")]: {
        paddingRight: "20px",
      },
    },
    mainTitle: {
      fontSize: "18px",
      fontWeight: "bold",
      marginTop: "10px",
    },
    subTitle: {
      color: "gray",
      fontWeight: "bold",
      fontSize: "14px",
      marginTop: "5px",
      textAlign: "center",
    },
    imgDiv: {
      width: "130px",
      height: "130px",
      marginBottom: "5px",
      objectFit: "cover",
    },
    noNotification: {
      marginTop: "35%",
      textAlign: "center",
    },
    leftBlock: {
      display: "flex",
      alignItems: "center",
    },
    rightBlock: {
      display: "flex",
      alignItems: "center",
    },
    logo: {
      width: 100,
      cursor: "pointer",
      margin: "40px 40px 0",
      [theme.breakpoints.down("md")]: {
        margin: "5px 15px 0",
        width: 60,
      },
    },
    circularLoader: {
      color: colors.top_red_section,
      marginLeft: "45%",
      marginTop: "30%",
    },
    headerMenuList: {
      fontSize: 20,
      lineHeight: "30px",
      cursor: "pointer",
      color: "#bebebe",
      fontWeight: "600",
      paddingRight: "40px",
    },
    focus: {
      fontSize: 20,
      lineHeight: "30px",
      color: "white",
      fontWeight: "bold",
      cursor: "pointer",
      textDecoration: "underline",
      textUnderlineOffset: "10px",
      marginRight: "40px",
    },
    help: {
      width: 45,
      cursor: "pointer",
      marginRight: 40,
    },
    notificationsIcon: {
      marginRight: 40,
      cursor: "pointer",
    },

    userInfo: {
      cursor: "pointer",
      width: "auto",
      color: colors.theme_fg,
      display: "flex",
      alignItems: "center",
    },
    info: {
      paddingLeft: 10,
      display: "flex",
      flexDirection: "column",
    },
    name: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      fontSize: 20,
      lineHeight: "20px",
      width: "auto",
    },
    userMenu: {
      width: 195,
      height: 120,
      // padding: "0 5px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      fontSize: 20,
      lineHeight: "28px",
      "& span": {
        cursor: "pointer",
      },
    },
    userMenuItem: {
      paddingLeft: "10px",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    },
    active: {
      transform: "rotate(180deg)",
    },
    badgeSize: {
      fontSize: "12px !important",
      height: "18px !important",
      width: "18px !important",
      minWidth: "18px !important",
      top: "7px !important",
      right: "2px !important",
      backgroundColor: "black",
      color: "white",
    },
    notifications: {
      background: colors.theme_fg,
      padding: "10px",
      width: "300px",
      height: "300px",
    },
    clearNotification: {
      textAlign: "center",
      position: "sticky",
      top: "320px",
      backgroundColor: "white",
      paddingTop: "10px",
      paddingBottom: "10px",
      cursor: "pointer",
      marginBlockStart: "0rem",
      marginBlockEnd: "0rem",
    },
    notificationPaper: {
      "& .MuiPopover-paper": {
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-track": {
          background: colors.theme_bg,
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: colors.icon_color,
          borderRadius: "5px",
        },
      },
    },
    notification: {
      paddingBottom: "10px",
      display: "flex",
      "&& .MuiSvgIcon-root": {
        color: colors.icon_color,
        height: "40px",
        width: "40px",
        background: colors.bg_notification,
        borderRadius: "5px",
        boxShadow: "0px 3px 6px #00000029",
      },
    },
    notificationInfo: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "10px",
    },
    notificationName: {
      fontSize: "16px",
      fontWeight: "bold",
      color: colors.theme_fg_two,
    },
    notificationDescription: {
      fontSize: "14px",
      color: colors.notification_desc,
    },
    cart: {
      display: "flex",
      alignItems: "center",
      marginRight: "40px",
      marginBottom: "5px",
      color: colors.theme_fg,
      cursor: "pointer",
      "& span": {
        fontSize: "20px",
        marginRight: "5px",
      },
      [theme.breakpoints.down("md")]: {
        marginRight: "20px",
      },
    },
  };
});

export default Header;
