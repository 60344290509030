/** @format */

import * as ActionTypes from "./ActionTypes";

export const addPartnerPending = () => ({
  type: ActionTypes.ADD_PARTNER_PENDING,
});

export const discardErrors = () => ({
  type: ActionTypes.DISCARD_ERRORS,
});

export const addPartnerError = (error) => ({
  type: ActionTypes.ADD_PARTNER_ERROR,
  error: error,
});

export const addPartnerSuccess = (data) => ({
  type: ActionTypes.ADD_PARTNER_SUCCESS,
  data,
});

export const getAllPartnersPending = () => ({
  type: ActionTypes.GET_ALL_PARTNERS_PENDING,
});

export const getAllPartnersError = (error) => ({
  type: ActionTypes.GET_ALL_PARTNERS_ERROR,
  error: error,
});

export const getAllPartnersSuccess = (data) => ({
  type: ActionTypes.GET_ALL_PARTNERS_SUCCESS,
  data,
});
