/** @format */

import React, { useState, useEffect, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "./config/history";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { getNewToken } from "./firebase";
import { SERVER_URL } from "./config/Constants";
import { checkIsAuthError, checkIsAuthSuccess } from "./actions/AuthAction";
import strings from "./languages/strings";
import Header from "./components/header/Header";
import { ToastContainer } from "react-toastify";
import {
  getAllBackOfficeUsers,
  getAllPartnerPermissions,
  getAllPartnerRoles,
  getAllPermission,
  getAllPricing,
  getAllRoles,
  getAllServiceType,
} from "./components/ordersHistory/services";
import { Loader } from "./components/GeneralComponents";
import { ThemeProvider } from "@mui/styles";
import { theme } from "./theme/theme";
import io from "socket.io-client";
import Permissions from "./views/Permissions";
import Roles from "./views/Roles";
import PartnerPermission from "./views/PartnerPermissions";
import PartnerRoles from "./views/PartnerRoles";
import { PermifyProvider } from "@permify/react-role";

function App() {
  const { isAuth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const Login = React.lazy(() => import("./views/Login"));

  const Forgot = React.lazy(() => import("./views/Forgot"));
  const Dashboard = React.lazy(() => import("./views/Dashboard"));
  const Orders = React.lazy(() => import("./views/Orders"));
  const ForgotPassword = React.lazy(() => import("./views/ForgotPassword"));
  const Settings = React.lazy(() => import("./views/Settings"));
  const Partners = React.lazy(() => import("./views/Partners"));
  const Customers = React.lazy(() => import("./views/Customers"));
  const Users = React.lazy(() => import("./views/Users"));
  const Statuses = React.lazy(() => import("./views/Statuses"));
  const ServiceType = React.lazy(() => import("./views/ServiceType"));

  useEffect(() => {
    getNewToken();
    home();
  }, []);

  useEffect(() => {
    if (isAuth) {
      getAllRoles(dispatch, 0, 20);
      getAllPartnerRoles(dispatch, 0, 20);
      getAllPermission(dispatch, 0, 20);
      getAllPartnerPermissions(dispatch, 0, 20);
      getAllBackOfficeUsers(dispatch, 0, 20);
      getAllPricing(dispatch);
      getAllServiceType(dispatch);
    }
  }, [isAuth]);

  const home = async () => {
    const user_id = localStorage.getItem("user_id");
    const lngValue = localStorage.getItem("storeLang");
    if (lngValue !== null) strings.setLanguage(lngValue);
    else strings.setLanguage("en");
    if (user_id !== null) {
      setIsLoading(false);
      return dispatch(checkIsAuthSuccess());
    } else {
      dispatch(checkIsAuthError());
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  const userData = localStorage.getItem("user_data");

  let socket = null;

  if (isAuth) {
    socket = io(SERVER_URL, {
      auth: {
        token: JSON.parse(userData).auth_token,
      },
    });
  }

  return (
    <>
      <ToastContainer />
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <PermifyProvider>
            {isAuth ? (
              <>
                <Header socket={socket} />
                <Routes>
                  <Route
                    path='*'
                    element={<Navigate replace to='/settings' />}
                  />
                  <Route
                    path='/dashboard'
                    element={
                      <Suspense fallback={<Loader />}>
                        <Dashboard socket={socket} />
                      </Suspense>
                    }
                  />
                  <Route
                    path='/orders'
                    element={
                      <Suspense fallback={<Loader />}>
                        <Orders socket={socket} />
                      </Suspense>
                    }
                  />
                  <Route
                    path='/settings'
                    element={
                      <Suspense fallback={<Loader />}>
                        <Settings />
                      </Suspense>
                    }
                  />
                  <Route
                    path='/partners'
                    element={
                      <Suspense fallback={<Loader />}>
                        <Partners />
                      </Suspense>
                    }
                  />
                  <Route
                    path='/customers'
                    element={
                      <Suspense fallback={<Loader />}>
                        <Customers />
                      </Suspense>
                    }
                  />
                  <Route
                    path='/permissions'
                    element={
                      <Suspense fallback={<Loader />}>
                        <Permissions />
                      </Suspense>
                    }
                  />
                  <Route
                    path='/partner-permissions'
                    element={
                      <Suspense fallback={<Loader />}>
                        <PartnerPermission />
                      </Suspense>
                    }
                  />
                  <Route
                    path='/partner-roles'
                    element={
                      <Suspense fallback={<Loader />}>
                        <PartnerRoles />
                      </Suspense>
                    }
                  />
                  <Route
                    path='/roles'
                    element={
                      <Suspense fallback={<Loader />}>
                        <Roles />
                      </Suspense>
                    }
                  />
                  <Route
                    path='/users'
                    element={
                      <Suspense fallback={<Loader />}>
                        <Users />
                      </Suspense>
                    }
                  />
                  <Route
                    path='/statuses'
                    element={
                      <Suspense fallback={<Loader />}>
                        <Statuses />
                      </Suspense>
                    }
                  />
                  <Route
                    path='/service-type'
                    element={
                      <Suspense fallback={<Loader />}>
                        <ServiceType />
                      </Suspense>
                    }
                  />
                </Routes>
              </>
            ) : (
              <Routes>
                <Route path='*' element={<Navigate replace to='/login' />} />
                <Route
                  path='/login'
                  element={
                    <Suspense fallback={<Loader />}>
                      <Login />
                    </Suspense>
                  }
                />
                <Route
                  path='/forgot-password'
                  element={
                    <Suspense fallback={<Loader />}>
                      <ForgotPassword />
                    </Suspense>
                  }
                />
                <Route
                  path='/forgot'
                  element={
                    <Suspense fallback={<Loader />}>
                      <Forgot />
                    </Suspense>
                  }
                />
              </Routes>
            )}
          </PermifyProvider>
        </ThemeProvider>
      </Router>
    </>
  );
}

export default App;
