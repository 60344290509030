/** @format */

import React from "react";
import { toggleModal } from "../../actions/CommonAction";
import { Modal } from "@mui/material";
import useStyles from "../../GlobalStyle";
import { useDispatch } from "react-redux";
import { sure_msg_permission } from "../../config/Constants";
import { onDeletePermission } from "../account/service";
import { toast } from "react-toastify";

const DeletePermissionModal = ({ showModal, selectedId }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const onCancel = async () => {
    dispatch(toggleModal());
  };

  const onSubmit = async () => {
    const response = await onDeletePermission(dispatch, selectedId);
    if (response) toast.success("Permission deleted successfully");
    else toast.error("Something went wrong, please try again");
    dispatch(toggleModal());
  };

  return (
    <Modal open={showModal} onClose={() => dispatch(toggleModal())}>
      <div className={styles.modal}>
        <span className={styles.modalHead}>{sure_msg_permission}</span>
        <div className={styles.modalButtons}>
          <button className={styles.yesButton} onClick={() => onSubmit()}>
            Yes
          </button>
          <button className={styles.noButton} onClick={() => onCancel()}>
            No
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeletePermissionModal;
