/** @format */

import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@mui/styles";
import * as colors from "../../assets/css/Colors";
import {
  boxShadow,
  completed_orders,
  normal,
  orders_pending,
  payment_pending,
  semiBold,
  total_orders,
} from "../../config/Constants";
import {
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Popover,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  serviceActiveOrdersError,
  serviceActiveOrdersPending,
  serviceActiveOrdersSuccess,
} from "../../actions/HomeActions";
import { formatDate } from "../../config/Helper";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";

import { DPFContext } from "../../context";
import useMediaQuery from "@mui/material/useMediaQuery";
import { toggleModal } from "../../actions/CommonAction";
import {
  getAllPermission,
  onAddPermissionSubmit,
  onEditPermissionSubmit,
} from "../ordersHistory/services";
import {
  VALIDATE_ROLE_NAME,
  VALIDATE_ROLE_SLUG,
} from "../../config/validation";
import { toast } from "react-toastify";
import { CommonError, Loader } from "../GeneralComponents";
import { addPermissionError } from "../../actions/PermissionsAction";
import DeletePermissionModal from "./DeletePermissionModal";

const PermissionsHistory = (props) => {
  const styles = localStyles();
  const matches = useMediaQuery("(max-width:1023px)");
  const dispatch = useDispatch();
  const [sort, setSort] = useState("");
  const [sortedOrders, setSortedOrders] = useState([]);
  const { permissionList, addPermissionLoading, permissionAddErrors } =
    useSelector((state) => state.permissions);
  const commonState = useSelector((state) => state.common);

  const fields = matches
    ? ["Date", "Service Type", "Current Status"]
    : ["ID", "Slug", "Name", "Created At", "Updated At"];
  const { ordersSort } = useContext(DPFContext);

  const [activeSort, setActiveSort] = useState("");
  const [sortType, setSortType] = useState("new");
  const [selectedId, setSelectedId] = useState(null);
  const [selectedPermission, setSelectedPermission] = useState(null);
  const [addRole, setAddRole] = useState(null);

  const [state, setState] = useState({
    name: "",
    slug: "",
    errorMessage: {
      name: "",
      slug: "",
    },
  });

  useEffect(() => {
    setSortedOrders(permissionList);
  }, [permissionList]);

  const onChange = (key, value) => {
    setState({
      ...state,
      [key]: value,
      errorMessage: { ...state.errorMessage, [key]: "", apiError: "" },
    });
    return true;
  };

  const handleSort = (event) => {
    const type = event.target ? event.target.value : event;
    event !== "reverse" && setSort(type);
    switch (type) {
      case "ID":
        setActiveSort("ID");
        setSortedOrders(
          permissionList.sort((a, b) => {
            return a.order_id - b.order_id;
          })
        );
        break;
      case "Slug":
        setActiveSort("Slug");
        setSortedOrders(
          permissionList.sort((a, b) => {
            return a.slug.localeCompare(b.slug, {
              sensitivity: "accent",
            });
          })
        );
        break;
      case "Name":
        setActiveSort("Name");
        setSortedOrders(
          permissionList.sort((a, b) => {
            return a.name.localeCompare(b.slug, {
              sensitivity: "accent",
            });
          })
        );
        break;

      case "Created At":
        setActiveSort("Created At");
        setSortedOrders(
          permissionList.sort((a, b) => {
            return Date.parse(a.createdAt) - Date.parse(b.createdAt);
          })
        );
        break;
      case "Updated At":
        setActiveSort("Updated At");
        setSortedOrders(
          permissionList.sort((a, b) => {
            return Date.parse(a.updatedAt) - Date.parse(b.updatedAt);
          })
        );
        break;

      case "reverse":
        sortType === "new" ? setSortType("reverse") : setSortType("new");
        setSortedOrders(sortedOrders.reduce((acc, val) => [val, ...acc], []));
        break;
      default:
        setSortedOrders([...permissionList]);
    }
  };

  const handleSearch = (string) => {
    const keys = ["slug", "name"];
    const results = [];
    permissionList.forEach((order) => {
      for (const key in order) {
        if (
          keys.includes(key) &&
          order[key].toString().indexOf(string) !== -1
        ) {
          results.push(order);
        }
      }
    });
    setSortedOrders(
      results.filter(
        (order, i, arr) => i === arr.findIndex((t) => t.id === order?.id)
      )
    );
  };

  const getTableTitle = () => {
    switch (ordersSort) {
      case orders_pending:
        return "Pending Service Orders";
      case payment_pending:
        return "Pending payment";
      case completed_orders:
        return "Completed Service orders";
      case total_orders:
        return "Total Service Orders";
      default:
        return props.type;
    }
  };

  const addNewRole = (event) => {
    openAddRole(event);
    setState({
      name: "",
      slug: "",
      errorMessage: {
        name: "",
        slug: "",
      },
    });
  };

  const openAddRole = (event) => {
    setAddRole(event.currentTarget);
  };

  const closeAddRole = () => {
    setAddRole(null);
    setState({
      name: "",
      slug: "",
      errorMessage: {
        name: "",
        slug: "",
      },
    });
    dispatch(addPermissionError(undefined));
    setSelectedId(null);
    setSelectedPermission(null);
  };

  const formList = Boolean(addRole);
  const addRoleId = formList ? "add-role-popover" : undefined;

  const submitNewRole = async () => {
    checkValidate();
    let response;
    if (state.validation) {
      if (selectedPermission)
        response = await onEditPermissionSubmit(
          dispatch,
          state,
          selectedPermission,
          false
        );
      else response = await onAddPermissionSubmit(dispatch, state, false);
      if (response) {
        toast.success(
          `Permission ${selectedPermission ? "edited" : "added"} successfully`
        );
        setAddRole(null);
        setSelectedId(null);
        setSelectedPermission(null);
        setState({
          name: "",
          slug: "",
          errorMessage: {
            name: "",
            slug: "",
          },
        });
        await getAllPermission(dispatch, 0, 20);
      }
    }
  };

  const checkValidate = () => {
    let errorMessage = { ...state.errorMessage };
    errorMessage.name = VALIDATE_ROLE_NAME(state.name);
    errorMessage.slug = VALIDATE_ROLE_SLUG(state.slug);
    setState({ ...state, errorMessage });
    if (errorMessage.slug || errorMessage.name) {
      state.validation = false;
      return false;
    }
    state.validation = true;
    return true;
  };

  const handleDeletePermission = (id) => {
    dispatch(toggleModal());
    setSelectedId(id);
  };

  const handleEditPermission = (event, permission) => {
    setAddRole(event.currentTarget);
    setSelectedPermission(permission);
    setState({
      name: permission.name,
      slug: permission.slug,
      errorMessage: {
        name: "",
        slug: "",
      },
    });
  };

  return (
    <>
      {/* {isLoading ? (
        <Loader />
      ) : ( */}
      <div className={styles.container}>
        {!matches && (
          <div className={styles.headSection}>
            <div>
              <span className={styles.name}>{getTableTitle()}</span>
              <input
                className={styles.search}
                placeholder='Search Permissions'
                onChange={(e) => handleSearch(e.target.value)}
              />
              <FormControl>
                <Select
                  labelId='select'
                  id='select'
                  value={sort}
                  displayEmpty
                  onChange={handleSort}
                  sx={{ fontSize: "12px", fontWeight: normal }}
                  className={styles.select}>
                  <MenuItem value=''>
                    <em>Sort by</em>
                  </MenuItem>
                  {fields.map((title) => (
                    <MenuItem key={title} value={title}>
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div onClick={(e) => addNewRole(e)}>
              <button className={styles.addCustomer}>
                <AddIcon />
                Add Permission
              </button>
            </div>
          </div>
        )}
        {sortedOrders?.length > 0 ? (
          <>
            <TableContainer
              sx={{
                maxHeight: props.height,
                height: props.height,
                marginTop: "10px",
              }}
              className={styles.tableWrap}>
              <Table
                className={styles.table}
                stickyHeader
                aria-label='sticky table'>
                <TableHead className={styles.tableHead}>
                  <TableRow>
                    {fields.map((title) => (
                      <TableCell key={title}>
                        <div
                          className={styles.title}
                          onClick={() =>
                            handleSort(sort !== title ? title : "reverse")
                          }>
                          <span>{title}</span>
                          {activeSort === title ? (
                            sortType === "new" ? (
                              <SouthIcon className={styles.icon} />
                            ) : (
                              <NorthIcon className={styles.icon} />
                            )
                          ) : (
                            <SwapVertIcon className={styles.icon} />
                          )}
                        </div>
                      </TableCell>
                    ))}
                    <TableCell>
                      <div className={styles.title}>
                        <span>Actions</span>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedOrders.map((role) => (
                    <TableRow
                      // onClick={() => handleSelectedRow(order?.order_id)}
                      key={role?.id}
                      hover={true}>
                      {!matches && (
                        <TableCell align='center' className={styles.cell}>
                          {role?.id}
                        </TableCell>
                      )}
                      <TableCell align='center' className={styles.cell}>
                        {role?.slug}
                      </TableCell>
                      <TableCell align='center' className={styles.cell}>
                        {role?.name}
                      </TableCell>

                      <TableCell align='center' className={styles.cell}>
                        {formatDate(role.createdAt)}
                      </TableCell>

                      {!matches && (
                        <TableCell align='center' className={styles.cell}>
                          {formatDate(role.updatedAt)}
                        </TableCell>
                      )}
                      <TableCell
                        align='center'
                        // onClick={() => handleSelectedRow(order?.order_id)}
                      >
                        <div className={styles.actionContainer}>
                          <Tooltip title='Edit Permission'>
                            <EditIcon
                              className={styles.infoIcon}
                              onClick={(e) => handleEditPermission(e, role)}
                            />
                          </Tooltip>
                          <Tooltip title='Delete Permission'>
                            <DeleteIcon
                              className={styles.infoIcon}
                              onClick={() => handleDeletePermission(role?.id)}
                            />
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <TablePagination
              style={{ marginTop: 10 }}
              rowsPerPageOptions={[10, 20, 30, 50]}
              component='div'
              count={permissionCount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </>
        ) : (
          <div className={styles.noOrders}>
            <span>No {ordersSort ? ordersSort : " Permissions"} found</span>
          </div>
        )}
      </div>
      {/* )} */}
      <Popover
        id={addRoleId}
        open={addRole}
        anchorEl={addRole}
        onClose={closeAddRole}
        anchorReference='none'
        style={{
          top: "120px",
          left: "30%",
        }}
        PaperProps={{
          style: { width: "60%" },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        <div className={styles.popoverContainer}>
          <span className={styles.head}>
            {selectedPermission ? "Edit Permission" : "Add New Permission"}
          </span>
        </div>
        <div className={styles.profileBody}>
          <TextField
            placeholder='Slug'
            label='Slug'
            fullWidth
            variant='filled'
            color='grey'
            className={styles.input}
            onChange={(e) => onChange("slug", e.target.value)}
            value={state?.slug}
            error={!!state?.errorMessage?.slug}
            helperText={state?.errorMessage?.slug}
          />
          <TextField
            placeholder='Name'
            label='Name'
            fullWidth
            variant='filled'
            color='grey'
            className={styles.input}
            onChange={(e) => onChange("name", e.target.value)}
            value={state?.name}
            error={!!state?.errorMessage?.name}
            helperText={state?.errorMessage?.name}
          />
          {addPermissionLoading && <Loader />}
          {permissionAddErrors && (
            <CommonError type='textError' error={permissionAddErrors} />
          )}
          <div style={{ display: "block", textAlign: "center" }}>
            <button
              className={styles.payButton}
              style={{
                background: "#BDBDBD",
              }}
              onClick={() => closeAddRole()}>
              Cancel
            </button>
            <button
              className={styles.payButton}
              style={{
                marginLeft: "70px",
              }}
              onClick={() => submitNewRole()}>
              {selectedPermission ? "Update" : "Create"}
            </button>
          </div>
        </div>
      </Popover>
      <DeletePermissionModal
        showModal={commonState.showModal}
        selectedId={selectedId}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    isLoading: state.myOrders.isLoading,
    error: state.myOrders.error,
    data: state.myOrders.data,
    message: state.myOrders.message,
    status: state.myOrders.status,
    orderError: state.myOrders.orderError,
    activeOrders: state.myOrders.activeOrder,
    activeOrderLoading: state.myOrders.activeOrderLoading,
    activeOrderCount: state.myOrders.activeOrderCount,
    completedOrders: state.home.activeOrder,
  };
}

const mapDispatchToProps = (dispatch) => ({
  serviceActiveOrdersPending: () => dispatch(serviceActiveOrdersPending()),
  serviceActiveOrdersError: (error) =>
    dispatch(serviceActiveOrdersError(error)),
  serviceActiveOrdersSuccess: (data) =>
    dispatch(serviceActiveOrdersSuccess(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsHistory);

const localStyles = makeStyles((theme) => {
  return {
    container: {
      background: colors.theme_fg,
      gridColumnStart: 1,
      gridColumnEnd: 4,
      height: "720px",
      maxHeight: "720px",
      boxShadow: "0px 3px 6px #00000029",
      padding: "20px",
      borderRadius: "5px",
      [theme.breakpoints.down("md")]: {
        order: 0,
        marginRight: 0,
        padding: 0,
      },
      [theme.breakpoints.between("md", "lg")]: {
        marginRight: "20px",
        padding: "5px",
      },
    },
    payButton: {
      border: "none",
      background: colors.icon_color,
      color: colors.text_white,
      fontSize: "20px",
      padding: "15px",
      width: "175px",
      borderRadius: "5px",
      cursor: "pointer",
      margin: "20px 0",
      ["@media  (max-width: 725px)"]: {
        width: "150px",
        padding: "5px",
      },
    },
    input: {
      marginBottom: "20px",
      "& .MuiInputBase-root": {
        boxShadow: boxShadow,
        fontFamily: "Montserrat, sans-serif",
      },
    },
    profileBody: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px 70px",
      [theme.breakpoints.down("md")]: {
        padding: "20px 40px",
      },
      [theme.breakpoints.between("md", "lg")]: {
        padding: "20px 30px",
      },
    },
    head: {
      color: "white",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontSize: "20px",
      fontWeight: "bold",
      fontFamily: "Montserrat, sans-serif",
    },
    addCustomer: {
      backgroundColor: "#C90000",
      color: "white",
      border: "none",
      padding: "6px",
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    popoverContainer: {
      width: "100%",
      background: "#C90000",
      gridColumnStart: 1,
      gridColumnEnd: 3,
      marginRight: "40px",
      boxShadow: "0px 3px 6px #00000029",
      padding: "20px",
      borderRadius: "5px",
      marginBottom: "20px",
      [theme.breakpoints.down("md")]: {
        order: 4,
        marginRight: 0,
        padding: 0,
      },
      [theme.breakpoints.between("md", "lg")]: {
        marginRight: "20px",
        padding: "5px",
      },
    },
    badgeSize: {
      fontSize: "12px !important",
      height: "18px !important",
      width: "18px !important",
      minWidth: "18px !important",
      top: "7px !important",
      right: "2px !important",
      backgroundColor: "black",
      color: "white",
    },
    actionContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    name: {
      fontSize: "24px",
      fontWeight: semiBold,
      lineHeight: "35px",
      marginRight: "20px",
    },
    search: {
      fontSize: "12px",
      lineHeight: "18px",
      boxShadow: "0px 3px 6px #00000029",
      width: "210px",
      padding: "8px 10px",
      border: "none",
      borderRadius: "5px",
      outline: "none",
      marginRight: "20px",
    },
    headSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    select: {
      minWidth: "90px",
      height: "34px",
      display: "flex",
      alignItems: "center",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "5px",
      },
    },
    tableWrap: {
      [theme.breakpoints.down("md")]: {
        marginTop: 0,
      },
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-track": {
        background: colors.theme_bg,
        marginTop: "40px",
        borderRadius: "5px",
        [theme.breakpoints.down("md")]: {
          marginTop: "60px",
        },
      },
      "&::-webkit-scrollbar-thumb": {
        background: colors.icon_color,
        borderRadius: "5px",
      },
    },
    table: {
      "& .MuiTableHead-root": {
        backgroundColor: colors.bg_notification,
        borderRadius: "5px",
        boxShadow: "0px 3px 6px #00000029",
      },
    },
    tableHead: {
      justifyContent: "space-between",
      padding: "10px 13px",
      "& .MuiTableCell-head": {
        backgroundColor: colors.bg_notification,
        "&:first-child": {
          borderBottomLeftRadius: "5px",
          borderTopLeftRadius: "5px",
        },
        "&:last-child": {
          borderBottomRightRadius: "5px",
          borderTopRightRadius: "5px",
        },
      },
      "& .MuiTableCell-root": {
        padding: 0,
      },
    },
    title: {
      display: "flex",
      alignItems: "center",
      fontWeight: semiBold,
      fontSize: "13px",
      lineHeight: "20px",
      cursor: "pointer",
      padding: "10px 13px",
      justifyContent: "center",
      [theme.breakpoints.between("md", "lg")]: {
        fontSize: "10px",
        padding: "5px",
      },
    },
    icon: {
      "&.MuiSvgIcon-root": {
        color: colors.icon_color,
        fontSize: "17px",
      },
    },
    body: {
      padding: "10px 13px",
    },
    item: {
      padding: "20px 0",
      fontSize: "13px",
      lineHeight: "20px",
      borderBottom: "1px solid #EBEDF3",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    cell: {
      "&.MuiTableCell-root": {
        fontWeight: normal,
        padding: "9px",
        cursor: "pointer",
        [theme.breakpoints.between("md", "lg")]: {
          padding: "5px",
          fontSize: "10px",
          maxWidth: "100px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
      },
    },
    infoIcon: {
      "&.MuiSvgIcon-root": {
        color: colors.theme_fg,
        background: colors.icon_color,
        borderRadius: "5px",
        padding: "6px",
        cursor: "pointer",
        marginLeft: 10,
      },
    },
    noOrders: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "100px",
      fontSize: "25px",
      [theme.breakpoints.down("md")]: {
        height: "200px",
        marginTop: 0,
      },
    },
  };
});
