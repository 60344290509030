/** @format */

import * as ActionTypes from "./ActionTypes";

export const getRolesPending = () => ({
  type: ActionTypes.GET_ROLES_PENDING,
});

export const getRolesError = (error) => ({
  type: ActionTypes.GET_ROLES_ERROR,
  error: error,
});

export const getRolesSuccess = (data) => ({
  type: ActionTypes.GET_ROLES_SUCCESS,
  data,
});

export const getPartnerUsersPending = () => ({
  type: ActionTypes.GET_PARTNER_USERS_PENDING,
});

export const getPartnerUsersError = (error) => ({
  type: ActionTypes.GET_PARTNER_USERS_ERROR,
  error: error,
});

export const getPartnerUsersSuccess = (data) => ({
  type: ActionTypes.GET_PARTNER_USERS_SUCCESS,
  data,
});

export const getPartnerRolesPending = () => ({
  type: ActionTypes.GET_PARTNER_ROLES_PENDING,
});

export const getPartnerRolesError = (error) => ({
  type: ActionTypes.GET_PARTNER_ROLES_ERROR,
  error: error,
});

export const getPartnerRolesSuccess = (data) => ({
  type: ActionTypes.GET_PARTNER_ROLES_SUCCESS,
  data,
});

export const rolesPending = () => ({
  type: ActionTypes.ROLES_PENDING,
});
export const rolesError = (error) => ({
  type: ActionTypes.ROLES_ERROR,
  error: error,
});
export const rolesSuccess = (data) => ({
  type: ActionTypes.ROLES_SUCCESS,
  data,
});

export const updateRolesPending = () => ({
  type: ActionTypes.UPDATE_ROLES_PENDING,
});
export const updateRolesError = (error) => ({
  type: ActionTypes.UPDATE_ROLES_ERROR,
  error: error,
});
export const updateRolesSuccess = (data) => ({
  type: ActionTypes.UPDATE_ROLES_SUCCESS,
  data,
});

export const deleteRolesPending = () => ({
  type: ActionTypes.DELETE_ROLES_PENDING,
});
export const deleteRolesError = (error) => ({
  type: ActionTypes.DELETE_ROLES_ERROR,
  error: error,
});
export const deleteRolesSuccess = (data) => ({
  type: ActionTypes.DELETE_ROLES_SUCCESS,
  data,
});

export const deletePartnerUsersPending = () => ({
  type: ActionTypes.DELETE_PARTNER_USER_PENDING,
});

export const deletePartnerUsersError = (error) => ({
  type: ActionTypes.DELETE_PARTNER_USER_ERROR,
  error: error,
});

export const deletePartnerUsersSuccess = (data) => ({
  type: ActionTypes.DELETE_PARTNER_USER_SUCCESS,
  data,
});
