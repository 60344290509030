/** @format */

import * as Actions from "../actions/ActionTypes";
const AddressReducer = (
  state = {
    isLoading: false,
    error: undefined,
    data: undefined,
    message: undefined,
    status: undefined,
    partnerAddresses: null,

    addressValue: {
      address_name: "",
      line_1: "",
      line_2: "",
      state_long_name: "",
      state_short_name: "",
      city_long_name: "",
      pin_code: "",
      address_note: "",
      is_default_billing: true,
    },
    addressId: null,
    showAdditionalDetails: false,
    errorMessage: {
      line_1: "",
      line_2: "",
      city_long_name: "",
      state_long_name: "",
      pin_code: "",
      address_name: "",
      address_note: "",
    },
    working_hours: [
      {
        day_sequence: 0,
        day: "Monday",
        is_opened: false,
        closing_hours: "",
        opening_hours: "",
      },
      {
        day_sequence: 1,
        day: "Tuesday",
        is_opened: false,
        closing_hours: "",
        opening_hours: "",
      },
      {
        day_sequence: 2,
        day: "Wednesday",
        is_opened: false,
        closing_hours: "",
        opening_hours: "",
      },
      {
        day_sequence: 3,
        day: "Thursday",
        is_opened: false,
        closing_hours: "",
        opening_hours: "",
      },
      {
        day_sequence: 4,
        day: "Friday",
        is_opened: false,
        closing_hours: "",
        opening_hours: "",
      },
      {
        day_sequence: 5,
        day: "Saturday",
        is_opened: false,
        closing_hours: "",
        opening_hours: "",
      },
      {
        day_sequence: 6,
        day: "Sunday",
        is_opened: false,
        closing_hours: "",
        opening_hours: "",
      },
    ],
    billingAddressLoader: false,
    billingAddress: null,
    billingAddressError: null,
  },
  action
) => {
  switch (action.type) {
    case Actions.TOGGLE_WORKING_DAY: {
      const workingHours = [...state.working_hours];
      workingHours[action.data].is_opened =
        !state.working_hours[action.data].is_opened;
      return Object.assign({}, state, {
        working_hours: workingHours,
      });
    }
    case Actions.EDIT_WORKING_DAY: {
      const workingHours = state.addressValue;
      workingHours.serviceHours[action.data].is_opened =
        !state.addressValue.serviceHours[action.data].is_opened;
      return Object.assign({}, state, {
        addressValue: workingHours,
      });
    }
    case Actions.SET_OPENING_HOURS: {
      const workingHours = [...state.working_hours];
      workingHours[action.data.index].opening_hours = action.data.opening_hours;
      return Object.assign({}, state, {
        working_hours: workingHours,
      });
    }
    case Actions.SET_CLOSING_HOURS: {
      const workingHours = [...state.working_hours];
      workingHours[action.data.index].closing_hours = action.data.closing_hours;
      return Object.assign({}, state, {
        working_hours: workingHours,
      });
    }
    case Actions.EDIT_OPENING_HOURS: {
      const workingHours = state.addressValue;
      workingHours.serviceHours[action.data.index].opening_hours =
        action.data.opening_hours;
      return Object.assign({}, state, {
        addressValue: workingHours,
      });
    }
    case Actions.EDIT_CLOSING_HOURS: {
      const workingHours = state.addressValue;
      workingHours.serviceHours[action.data.index].closing_hours =
        action.data.closing_hours;
      return Object.assign({}, state, {
        addressValue: workingHours,
      });
    }
    case Actions.CLEAR_WORKING_HOURS: {
      return Object.assign({}, state, {
        working_hours: [
          {
            day: "Monday",
            is_opened: false,
            closing_hours: "",
            opening_hours: "",
          },
          {
            day: "Tuesday",
            is_opened: false,
            closing_hours: "",
            opening_hours: "",
          },
          {
            day: "Wednesday",
            is_opened: false,
            closing_hours: "",
            opening_hours: "",
          },
          {
            day: "Thursday",
            is_opened: false,
            closing_hours: "",
            opening_hours: "",
          },
          {
            day: "Friday",
            is_opened: false,
            closing_hours: "",
            opening_hours: "",
          },
          {
            day: "Saturday",
            is_opened: false,
            closing_hours: "",
            opening_hours: "",
          },
          {
            day: "Sunday",
            is_opened: false,
            closing_hours: "",
            opening_hours: "",
          },
        ],
      });
    }
    case Actions.CREATE_ADDRESS_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case Actions.CREATE_ADDRESS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });
    case Actions.CREATE_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        message: action.data,
        addressValue: {
          address_name: "",
          line_1: "",
          line_2: "",
          state_long_name: "",
          state_short_name: "",
          city_long_name: "",
          pin_code: "",
          address_note: "",
          is_default_billing: true,
        },
      });
    case Actions.EDIT_ADDRESS_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case Actions.EDIT_ADDRESS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });
    case Actions.EDIT_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        message: action.data,
      });
    case Actions.UPDATE_ADDRESS_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case Actions.UPDATE_ADDRESS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });
    case Actions.UPDATE_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        message: action.data,
        addressValue: {
          address_name: "",
          line_1: "",
          line_2: "",
          state_long_name: "",
          state_short_name: "",
          city_long_name: "",
          pin_code: "",
          address_note: "",
          is_default_billing: true,
        },
      });
    case Actions.GET_ADDRESS_VALUES: {
      let addressValue = null;
      if (action.value)
        addressValue = { ...state.addressValue, ...action.value };
      return Object.assign({}, state, {
        addressValue,
        error: undefined,
      });
    }
    case Actions.GET_ADDRESS_ID:
      return Object.assign({}, state, {
        isLoading: false,
        addressId: action.addressId,
        addressValue: {
          address_name: "",
          line_1: "",
          line_2: "",
          state_long_name: "",
          state_short_name: "",
          city_long_name: "",
          pin_code: "",
          address_note: "",
          is_default_billing: true,
        },
        error: undefined,
      });
    case Actions.GET_ADDRESS_ID_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        message: action.addressData,
        error: undefined,
      });
    case Actions.GET_ADDRESS_ID_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        addressValue: {
          address_name: "",
          line_1: "",
          line_2: "",
          state_long_name: "",
          state_short_name: "",
          city_long_name: "",
          pin_code: "",
          address_note: "",
          is_default_billing: true,
        },
        error: action.error,
      });
    case Actions.SHOW_ADDITIONALDETAILS:
      return Object.assign({}, state, {
        showAdditionalDetails: action.isShow,
      });
    case Actions.SET_ERROR_MESSAGE:
      return Object.assign({}, state, {
        errorMessage: action.errorMessage,
      });
    case Actions.GET_BILLING_ADDRESS:
      return Object.assign({}, state, {
        billingAddressLoader: true,
        billingAddress: null,
        billingAddressError: null,
      });
    case Actions.GET_BILLING_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        billingAddressLoader: false,
        billingAddress: action.billingAddress,
        billingAddressError: null,
      });
    case Actions.GET_BILLING_ADDRESS_ERROR:
      return Object.assign({}, state, {
        billingAddressLoader: false,
        billingAddress: null,
        billingAddressError: action.error,
      });
    case Actions.CLEAR_ADDRESS_VALUE:
      return Object.assign({}, state, {
        addressValue: {
          address_name: "",
          line_1: "",
          line_2: "",
          state_long_name: "",
          state_short_name: "",
          city_long_name: "",
          pin_code: "",
          address_note: "",
          is_default_billing: true,
        },
        errorMessage: {
          line_1: "",
          city_long_name: "",
          state_long_name: "",
          pin_code: "",
        },
      });
    case Actions.PARTNER_PROFILE_PENDING:
    case Actions.PARTNER_PROFILE_ERROR:
      return Object.assign({}, state, {
        partnerAddresses: null,
      });
    case Actions.PARTNER_PROFILE_SUCCESS: {
      return Object.assign({}, state, {
        partnerAddresses: action.data.address,
      });
    }
    default:
      return state;
  }
};

export default AddressReducer;
