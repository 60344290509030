/** @format */

import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import * as colors from "../../assets/css/Colors";
import {
  completed_orders,
  normal,
  orders_pending,
  payment_pending,
  semiBold,
  add_new_role,
  total_orders,
} from "../../config/Constants";
import {
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { rolesError } from "../../actions/RolesAction";
import { Loader } from "../GeneralComponents";
import EditIcon from "@mui/icons-material/Edit";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import NewRole from "./AddNewRole";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  serviceActiveOrdersError,
  serviceActiveOrdersPending,
  serviceActiveOrdersSuccess,
} from "../../actions/HomeActions";
import { formatDate } from "../../config/Helper";
import AddIcon from "@mui/icons-material/Add";
import DeleteModal from "./DeleteModel";
import Tooltip from "@mui/material/Tooltip";

import { DPFContext } from "../../context";
import useMediaQuery from "@mui/material/useMediaQuery";

const PartnerRolesHistory = (props) => {
  const styles = localStyles();

  const matches = useMediaQuery("(max-width:1023px)");
  const dispatch = useDispatch();
  const [sort, setSort] = useState("");
  const [sortedOrders, setSortedOrders] = useState([]);

  const [activeSort, setActiveSort] = useState("");
  const [sortType, setSortType] = useState("new");
  const { partnerRolesList } = useSelector((state) => state.roles);
  const fields = matches
    ? ["Date", "Service Type", "Current Status"]
    : ["ID", "Slug", "Name", "Created At", "Updated At", "Permissions"];
  const { ordersSort } = useContext(DPFContext);

  const [addPartner, setAddPartner] = useState(null);
  const [selectedPartnerId, setSelectedPartner] = useState({});

  useEffect(() => {
    setSortedOrders(partnerRolesList);
  }, [partnerRolesList]);

  const addNewPartner = (event, role) => {
    setSelectedPartner(role);
    openAddPartner(event);
  };
  const openAddPartner = (event) => {
    setAddPartner(event.currentTarget);
  };
  const closeAddPartner = () => {
    setAddPartner(null);
    dispatch(rolesError(""));
  };
  const formList = Boolean(addPartner);
  const addPartnerId = formList ? "add-partner-popover" : undefined;

  const handleSort = (event) => {
    const type = event.target ? event.target.value : event;
    event !== "reverse" && setSort(type);
    switch (type) {
      case "ID":
        setActiveSort("ID");
        setSortedOrders(
          partnerRolesList.sort((a, b) => {
            return a.order_id - b.order_id;
          })
        );
        break;
      case "Slug":
        setActiveSort("Slug");
        setSortedOrders(
          partnerRolesList.sort((a, b) => {
            return a.slug.localeCompare(b.slug, {
              sensitivity: "accent",
            });
          })
        );
        break;
      case "Name":
        setActiveSort("Name");
        setSortedOrders(
          partnerRolesList.sort((a, b) => {
            return a.name.localeCompare(b.slug, {
              sensitivity: "accent",
            });
          })
        );
        break;

      case "Created At":
        setActiveSort("Created At");
        setSortedOrders(
          partnerRolesList.sort((a, b) => {
            return Date.parse(a.createdAt) - Date.parse(b.createdAt);
          })
        );
        break;
      case "Updated At":
        setActiveSort("Updated At");
        setSortedOrders(
          partnerRolesList.sort((a, b) => {
            return Date.parse(a.updatedAt) - Date.parse(b.updatedAt);
          })
        );
        break;

      case "reverse":
        sortType === "new" ? setSortType("reverse") : setSortType("new");
        setSortedOrders(sortedOrders.reduce((acc, val) => [val, ...acc], []));
        break;
      default:
        setSortedOrders([...partnerRolesList]);
    }
  };

  const handleSearch = (string) => {
    const keys = ["order_id", "total", "created_at"];
    const results = [];
    partnerRolesList.forEach((order) => {
      for (const key in order) {
        if (
          keys.includes(key) &&
          order[key].toString().indexOf(string) !== -1
        ) {
          results.push(order);
        }
      }
    });
    setSortedOrders(
      results.filter(
        (order, i, arr) =>
          i === arr.findIndex((t) => t.order_id === order?.order_id)
      )
    );
  };

  const getTableTitle = () => {
    switch (ordersSort) {
      case orders_pending:
        return "Pending Service Orders";
      case payment_pending:
        return "Pending payment";
      case completed_orders:
        return "Completed Service orders";
      case total_orders:
        return "Total Service Orders";
      default:
        return props.type;
    }
  };

  return (
    <div className={styles.container}>
      {!matches && (
        <div className={styles.headSection}>
          <span className={styles.name}>{getTableTitle()}</span>
          <input
            className={styles.search}
            placeholder='Search Roles'
            onChange={(e) => handleSearch(e.target.value)}
          />
          <FormControl>
            <Select
              labelId='select'
              id='select'
              value={sort}
              displayEmpty
              onChange={handleSort}
              sx={{ fontSize: "12px", fontWeight: normal }}
              className={styles.select}>
              <MenuItem value=''>
                <em>Sort by</em>
              </MenuItem>
              {fields.map((title) => (
                <MenuItem key={title} value={title}>
                  {title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className={styles.addButton}>
            <button
              className={styles.addCustomer}
              onClick={(e) => addNewPartner(e)}
              // onClick={handleAddCustomerModal}
            >
              <AddIcon />
              {add_new_role}
            </button>
          </div>
        </div>
      )}
      {sortedOrders?.length > 0 ? (
        <>
          <TableContainer
            sx={{
              maxHeight: props.height,
              height: props.height,
              marginTop: "10px",
            }}
            className={styles.tableWrap}>
            <Table
              className={styles.table}
              stickyHeader
              aria-label='sticky table'>
              <TableHead className={styles.tableHead}>
                <TableRow>
                  {fields.map((title) => (
                    <TableCell key={title}>
                      <div
                        className={styles.title}
                        onClick={() =>
                          handleSort(sort !== title ? title : "reverse")
                        }>
                        <span>{title}</span>
                        {activeSort === title ? (
                          sortType === "new" ? (
                            <SouthIcon className={styles.icon} />
                          ) : (
                            <NorthIcon className={styles.icon} />
                          )
                        ) : (
                          <SwapVertIcon className={styles.icon} />
                        )}
                      </div>
                    </TableCell>
                  ))}
                  <TableCell>
                    <div className={styles.title}>
                      <span>Actions</span>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedOrders.map((role) => (
                  <TableRow
                    // onClick={() => handleSelectedRow(order?.order_id)}
                    key={role?.id}
                    hover={true}>
                    {!matches && (
                      <TableCell align='center' className={styles.cell}>
                        {role?.id}
                      </TableCell>
                    )}
                    <TableCell align='center' className={styles.cell}>
                      {role?.slug}
                    </TableCell>
                    <TableCell align='center' className={styles.cell}>
                      {role?.name}
                    </TableCell>

                    <TableCell align='center' className={styles.cell}>
                      {formatDate(role.createdAt)}
                    </TableCell>

                    {!matches && (
                      <TableCell align='center' className={styles.cell}>
                        {formatDate(role.updatedAt)}
                      </TableCell>
                    )}
                    <TableCell align='center' className={styles.cell}>
                      {role?.permissions?.map((rolePermissions) => {
                        return (
                          <span
                            key={rolePermissions?.name}
                            style={{
                              color: "white",
                              backgroundColor: colors.icon_color,
                              marginRight: "10px",
                              borderRadius: "3px",
                              fontSize: "11px",
                              padding: "5px 5px 5px 5px",
                              boxShadow: "none",
                            }}>
                            {rolePermissions?.name}
                          </span>
                        );
                      })}
                    </TableCell>
                    <TableCell
                      align='center'
                      // onClick={() => handleSelectedRow(order?.order_id)}
                    >
                      <div className={styles.actionContainer}>
                        <Tooltip title='Edit Partner Role'>
                          <EditIcon
                            className={styles.infoIcon}
                            onClick={(e) => addNewPartner(e, role)}
                          />
                        </Tooltip>
                        <Tooltip title='Delete Partner Role'>
                          <DeleteModal role={role} />
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <div className={styles.noOrders}>
          {!partnerRolesList ? (
            <span>No {ordersSort ? ordersSort : " Roles"} found</span>
          ) : (
            <Loader />
          )}
        </div>
      )}
      <NewRole
        id={addPartnerId}
        open={addPartner}
        anchorEl={addPartner}
        onClose={closeAddPartner}
        anchorReference='none'
        selectedPartnerId={selectedPartnerId}
        setSelectedPartner={setSelectedPartner}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isLoading: state.myOrders.isLoading,
    error: state.myOrders.error,
    data: state.myOrders.data,
    message: state.myOrders.message,
    status: state.myOrders.status,
    orderError: state.myOrders.orderError,
    activeOrders: state.myOrders.activeOrder,
    activeOrderLoading: state.myOrders.activeOrderLoading,
    activeOrderCount: state.myOrders.activeOrderCount,
    completedOrders: state.home.activeOrder,
  };
}

const mapDispatchToProps = (dispatch) => ({
  serviceActiveOrdersPending: () => dispatch(serviceActiveOrdersPending()),
  serviceActiveOrdersError: (error) =>
    dispatch(serviceActiveOrdersError(error)),
  serviceActiveOrdersSuccess: (data) =>
    dispatch(serviceActiveOrdersSuccess(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerRolesHistory);

const localStyles = makeStyles((theme) => {
  return {
    container: {
      background: colors.theme_fg,
      gridColumnStart: 1,
      gridColumnEnd: 4,
      height: "720px",
      maxHeight: "720px",
      boxShadow: "0px 3px 6px #00000029",
      padding: "20px",
      borderRadius: "5px",
      [theme.breakpoints.down("md")]: {
        order: 0,
        marginRight: 0,
        padding: 0,
      },
      [theme.breakpoints.between("md", "lg")]: {
        marginRight: "20px",
        padding: "5px",
      },
    },
    badgeSize: {
      fontSize: "12px !important",
      height: "18px !important",
      width: "18px !important",
      minWidth: "18px !important",
      top: "7px !important",
      right: "2px !important",
      backgroundColor: "black",
      color: "white",
    },
    actionContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    name: {
      fontSize: "24px",
      fontWeight: semiBold,
      lineHeight: "35px",
      marginRight: "20px",
    },
    search: {
      fontSize: "12px",
      lineHeight: "18px",
      boxShadow: "0px 3px 6px #00000029",
      width: "210px",
      padding: "8px 10px",
      border: "none",
      borderRadius: "5px",
      outline: "none",
      marginRight: "20px",
    },
    headSection: {
      display: "flex",
      alignItems: "center",
    },
    select: {
      minWidth: "90px",
      height: "34px",
      display: "flex",
      alignItems: "center",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "5px",
      },
    },
    tableWrap: {
      [theme.breakpoints.down("md")]: {
        marginTop: 0,
      },
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-track": {
        background: colors.theme_bg,
        marginTop: "40px",
        borderRadius: "5px",
        [theme.breakpoints.down("md")]: {
          marginTop: "60px",
        },
      },
      "&::-webkit-scrollbar-thumb": {
        background: colors.icon_color,
        borderRadius: "5px",
      },
    },
    table: {
      "& .MuiTableHead-root": {
        backgroundColor: colors.bg_notification,
        borderRadius: "5px",
        boxShadow: "0px 3px 6px #00000029",
      },
    },
    tableHead: {
      justifyContent: "space-between",
      padding: "10px 13px",
      "& .MuiTableCell-head": {
        backgroundColor: colors.bg_notification,
        "&:first-child": {
          borderBottomLeftRadius: "5px",
          borderTopLeftRadius: "5px",
        },
        "&:last-child": {
          borderBottomRightRadius: "5px",
          borderTopRightRadius: "5px",
        },
      },
      "& .MuiTableCell-root": {
        padding: 0,
      },
    },
    title: {
      display: "flex",
      alignItems: "center",
      fontWeight: semiBold,
      fontSize: "13px",
      lineHeight: "20px",
      cursor: "pointer",
      padding: "10px 13px",
      justifyContent: "center",
      [theme.breakpoints.between("md", "lg")]: {
        fontSize: "10px",
        padding: "5px",
      },
    },
    icon: {
      "&.MuiSvgIcon-root": {
        color: colors.icon_color,
        fontSize: "17px",
      },
    },
    body: {
      padding: "10px 13px",
    },
    item: {
      padding: "20px 0",
      fontSize: "13px",
      lineHeight: "20px",
      borderBottom: "1px solid #EBEDF3",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    cell: {
      "&.MuiTableCell-root": {
        fontWeight: normal,
        padding: "9px",
        cursor: "pointer",
        [theme.breakpoints.between("md", "lg")]: {
          padding: "5px",
          fontSize: "10px",
          maxWidth: "100px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
      },
    },
    addCustomer: {
      backgroundColor: "#C90000",
      color: "white",
      border: "none",
      cursor: "pointer",
      fontSize: "15px",
      padding: "6px",
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
    },
    addButton: {
      position: "absolute",
      right: "50px",
      cursor: "pointer",
    },
    infoIcon: {
      "&.MuiSvgIcon-root": {
        color: colors.theme_fg,
        background: colors.icon_color,
        borderRadius: "5px",
        padding: "6px",
        cursor: "pointer",
        marginLeft: 10,
      },
    },
    noOrders: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "100px",
      fontSize: "25px",
      [theme.breakpoints.down("md")]: {
        height: "200px",
        marginTop: 0,
      },
    },
  };
});
