/** @format */

export const theme_bg = "#ededed";
export const status_bar_color = "#FFFFFF";
export const top_red_section = "#c90000";
export const icon_color = "#c90000";
export const theme_fg = "#FFFFFF";
export const theme_bg_two = "#e6e6e6";
export const theme_fg_two = "#0a0b0f";
export const theme_bg_three = "#FFFFFF";
export const theme_fg_three = "#FFFFFF";
export const theme_fg_four = "#a4a9b7";
export const theme_pink = "#e81f64";
export const theme_shade = "#1d1d2a";
export const theme_button = "#E81f64";
export const theme_description = "#888384";
export const promo_color = "#4dad4a";
export const black_layer = "rgba(0, 0, 0, 0.5)";
export const description_text_color = "#c5c5c5";
export const shopping_cart = "#a7a7a7";
export const theme_bg_red = "#c90000";
export const text_color = "#000";
export const footer_text_color = "#FFFFFF";
export const text_red = "#c90000";
export const text_white = "#FFFFFF";
export const text_light_gray = "#bababa";
export const black_text_color = "#000";
export const dark_color = "#ededed";
export const description_text_color_two = "#777777";
export const card_modal_color = "#e8e9eb";
export const close_background_color = "#c6c6c6";
export const gray_th_2 = "#a6a6a8";
export const dark_gray = "#777";
export const card_form = "#f5f5f7";
export const gray_border = "#e0e0e0";
export const green = "#40a235";
export const border_bottom_input = "#8b97a2";
export const paper_theme_background = "#e7e7e7";
export const paper_theme_border = "#8e8e8e";
export const paper_theme_label = "#6e6c6c";
export const INACTIVE_COLOR = "#e48080";
export const text_gray = "#6f6f6f";
export const text_orders = "#bebebe";
export const bg_notification = "#F3F6F9";
export const notification_desc = "#969191";
export const applyGrey = "#5D5555";
