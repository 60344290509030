import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import {createStore} from "@reduxjs/toolkit";
import allReducers from "./reducers/index"
import "react-toastify/dist/ReactToastify.css";
import DPFContextProvider from "./context";
import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container);

const store = createStore(allReducers);

root.render(
  <Provider store={store}>
    <DPFContextProvider>
      <App />
    </DPFContextProvider>
  </Provider>
);
