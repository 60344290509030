/** @format */

import React from "react";
// // import { Divider as Hr, Button } from "react-native-elements";
// // import { Icon as Ico, View, Text } from "native-base";
// // import Spinner from "react-native-loading-spinner-overlay";
// // import Snackbar from "react-native-snackbar";
import * as colors from "../assets/css/Colors";
// // import Icon from "react-native-vector-icons/dist/MaterialCommunityIcons";
import useStyles from "../GlobalStyle";
import { useNavigate } from "react-router-dom";
// // import ReCaptchaV3 from "@haskkor/react-native-recaptchav3"
import { ACUMEDIA_99DPF_URL, RECAPTCHA_CONSTANT } from "../config/Constants";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CircularProgress } from "@mui/material";
import RoomIcon from "@mui/icons-material/Room";

//
// export function Image(props) {
//   return (
//     <img
//       style={{ flex: 1, width: undefined, height: undefined }}
//       src={props.source}
//       alt=""
//     />
//   );
// }
//
// export function StatusBar(props) {
//   return (
//     <Sb
//       barStyle="light-content"
//       hidden={false}
//       backgroundColor={colors.theme_bg_red}
//       translucent={false}
//       networkActivityIndicatorVisible={true}
//     />
//   );
// }
//
// export function Divider(props) {
//   return <Hr style={{ backgroundColor: colors.theme_fg_two }} />;
// }
//
export function Loader() {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress
        color='inherit'
        sx={{ color: colors.theme_bg_red, marginTop: "10px" }}
      />
    </div>
  );
}

export function BackArrow(props) {
  const navigate = useNavigate();
  const GlobalStyles = useStyles();
  // const handleBackButtonClick = () => {
  //   props.navigation.goBack(null);
  //   if (props.onPress) return props.onPress();
  //   return false;
  // };
  return (
    <div className={GlobalStyles.arrow} onClick={() => navigate(props.link)}>
      <ArrowBackIcon sx={{ color: colors.icon_color }} fontSize={"large"} />
    </div>
  );
}

export function CommonError(props) {
  // const navigation = useNavigation();
  if (props.type === "textError") {
    return (
      <span
        style={{
          textAlign: props.fromPricing ? "left" : "center",
          fontSize: 12,
          color: colors.theme_bg_red,
          marginTop: 10,
        }}>
        {props.error}
      </span>
    );
  }
  // const redirectTo = () => {
  //   if (props.error.includes("Sorry, we do not have a $99DPF partner")) {
  //     navigation.navigate("RequestServiceAreas");
  //   }
  // }
  // return (
  //   <div style={{ flex: 1, padding: 20 }}>
  //     <span style={{ textAlign: "center", color: colors.theme_bg_red }} onClick={() => redirectTo()}>
  //       {props.error}
  //     </span>
  //   </div>
  // );
}
//
// export function showSnackbar(msg) {
//   Snackbar.show({
//     text: msg,
//     duration: Snackbar.LENGTH_LONG,
//   });
// }
//
// export function ActionHeader({ closeAction, title, subTitle }) {
//   const onPressView = () => {
//     return closeAction();
//   };
//
//   return (
//     <>
//       {/*<TouchableOpacity onPress={() => onPressView()} style={{marginTop: 10}}>
//         <View
//           style={{
//             alignSelf: 'center',
//             borderWidth: 2,
//             borderRadius: 20,
//             width: 100,
//             borderColor: colors.gray_border,
//             marginBottom: 20,
//           }}
//         />
//         </TouchableOpacity>*/}
//       <Text
//         style={[globalStyles.title, {
//           marginBottom: (subTitle === undefined) ? 15 : 25,
//           marginTop: 10
//         }]}>
//         {title}
//       </Text>
//       {(subTitle !== "" && subTitle !== undefined) && (
//         <Text style={[globalStyles.title, { marginBottom: 5, alignSelf: "flex-start", marginLeft: 20 }]}>{subTitle}</Text>
//       )}
//     </>
//   );
// }
//
// export const AddText = ({ title, onPressAddNew }) => {
//   const onAddNew = () => {
//     return onPressAddNew();
//   };
//   return (
//     <View>
//       <TouchableOpacity
//         style={{ alignItems: "center", marginTop: 10, flexDirection: "row", }}
//         onPress={() => onAddNew()}>
//         <Icon name="plus-circle" size={24} color={colors.text_red} />
//         <Text style={[globalStyles.stdText, { color: colors.text_red, left: 5, fontWeight: "bold" }]}>{title}</Text>
//       </TouchableOpacity>
//     </View>
//   );
// };
//
// export const FooterButtons = ({
//   onSave,
//   onclose,
//   okTitle,
//   cancelTitle,
//   loader,
//   disabled,
//   isStripeCall,
//   setToken,
//   isCustomwidth = undefined,
//   captchaRef,
//   callRecaptchAPI
// }) => {
//
//   const onPressSave = () => {
//     if (isStripeCall) {
//       captchaRef?._captchaRef?.refreshToken();
//       return callRecaptchAPI();
//     } else
//       return onSave();
//   };
//   const onPressClose = () => {
//     return onclose();
//   };
//   return (
//     <View style={globalStyles.btnOuterContainerGlobal}>
//       <View style={[globalStyles.btnInnerContainerGlobal, { top: 0 }]}>
//         {isStripeCall && <ReCaptchaV3
//           ref={(ref) => captchaRef = ref}
//           captchaDomain={ACUMEDIA_99DPF_URL}
//           siteKey={RECAPTCHA_CONSTANT}
//           action={"save"}
//           onReceiveToken={(token) => {
//             console.log("CAPTCHA> ", token);
//             setToken(token);
//           }}
//         />
//         }
//         <Button
//           buttonStyle={globalStyles.mainBtnGlobal}
//           onPress={() => onPressSave()}
//           title={okTitle}
//           disabled={disabled}
//           loading={loader}
//           titleStyle={globalStyles.btnTextGlobal}
//         />
//       </View>
//       <View style={[globalStyles.btnInnerContainerGlobal, { top: 0 }]}>
//         <Button
//           buttonStyle={globalStyles.secondBtnGlobal}
//           onPress={() => onPressClose()}
//           title={cancelTitle}
//           disabled={loader}
//           titleStyle={[globalStyles.btnScndTextGlobal]}
//         />
//       </View>
//     </View>
//   );
// };
//
// export const FloatingButton = ({ onPress, title }) => {
//   const onFloatingButton = () => {
//     return onPress();
//   };
//   return (
//     <View>
//       <Button
//         title={title}
//         onPress={() => onFloatingButton()}
//         buttonStyle={{
//           backgroundColor: colors.theme_bg_red,
//           width: "90%",
//           marginBottom: 40,
//           alignItems: "center",
//           alignSelf: "center",
//         }}
//       />
//     </View>
//     // <TouchableOpacity
//     //   activeOpacity={1}
//     //   style={{
//     //     alignSelf: 'flex-end',
//     //     width: 52,
//     //     height: 52,
//     //     alignContent: 'center',
//     //     alignItems: 'center',
//     //     borderRadius: 40,
//     //     backgroundColor: colors.text_color,
//     //     // padding: 10,
//     //     // marginBottom: 10,
//     //     position: 'absolute',
//     //     right: 25,
//     //     bottom: 15,
//     //   }}
//     //   onPress={() => onFloatingButton()}>
//     //   <IconButton
//     //     icon="plus"
//     //     color={colors.theme_bg_three}
//     //     size={25}
//     //     style={{
//     //       alignSelf: 'center',
//     //       alignItems: 'center',
//     //       flex: 1,
//     //     }}
//     //   />
//     // </TouchableOpacity>
//   );
// };

export const Marker = () => {
  return (
    <div>
      <RoomIcon sx={{ color: colors.icon_color }} />
    </div>
  );
};

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabPanel'
      hidden={value !== index}
      id={`tabPanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}>
      {value === index && <div>{children}</div>}
    </div>
  );
};

export const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `tabPanel-${index}`,
  };
};
