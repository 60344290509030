/** @format */
import React from "react";
import { makeStyles } from "@mui/styles";
import { Popover } from "@mui/material";
import PartnerInfo from "./RoleInfo";
const NewRole = ({
  id,
  open,
  anchorEl,
  onClose,
  selectedPartnerId,
  setSelectedPartner,
}) => {
  const styles = localStyles();
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorReference='none'
      style={{
        top: "120px",
        left: "30%",
      }}
      PaperProps={{
        style: { width: "60%" },
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}>
      <div className={styles.popoverContainer}>
        <span className={styles.head}>
          {selectedPartnerId ? "Update" : "Add"} Role Information
        </span>
      </div>
      <div>
        <PartnerInfo
          onClose={onClose}
          selectedPartnerId={selectedPartnerId}
          setSelectedPartner={setSelectedPartner}
        />
      </div>
    </Popover>
  );
};
const localStyles = makeStyles((theme) => {
  return {
    closeIcon: {
      color: "white",
      cursor: "pointer",
    },
    profileBody: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px 70px",
      [theme.breakpoints.down("md")]: {
        padding: "20px 40px",
      },
      [theme.breakpoints.between("md", "lg")]: {
        padding: "20px 30px",
      },
    },
    popoverContainer: {
      width: "100%",
      background: "#C90000",
      gridColumnStart: 1,
      gridColumnEnd: 3,
      marginRight: "40px",
      boxShadow: "0px 3px 6px #00000029",
      padding: "20px",
      borderRadius: "5px",
      marginBottom: "20px",
      [theme.breakpoints.down("md")]: {
        order: 4,
        marginRight: 0,
        padding: 0,
      },
      [theme.breakpoints.between("md", "lg")]: {
        marginRight: "20px",
        padding: "5px",
      },
    },
    head: {
      color: "white",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontSize: "20px",
      fontWeight: "bold",
      fontFamily: "Montserrat, sans-serif",
    },
  };
});
export default NewRole;
