/** @format */

import { combineReducers } from "@reduxjs/toolkit";
import AuthReducer from "./AuthReducer";
import LoginReducer from "./LoginReducer";
import ForgotReducer from "./ForgotReducer";
import CommonReducer from "./CommonReducer";
import ProfileReducer from "./ProfileReducer";
import HomeReducer from "./HomeReducer";
import MyOrdersReducer from "./MyOrdersReducer";
import AddressListReducer from "./AddressListReducer";
import AddressReducer from "./AddressReducer";
import ContactReducer from "./ContactReducer";
import ForgotPasswordReducer from "./ForgotPasswordReducer";
import RolesReducer from "./RolesReducers";
import PermissionsReducer from "./PermissonsReducers";
import PartnerReducer from "./PartnerReducer";
import CustomerReducer from "./CustomerReducer";
import StatusReducer from "./StatusReducer";
import ServiceTypeReducer from "./ServiceTypeReducer";

const allReducers = combineReducers({
  auth: AuthReducer,
  login: LoginReducer,
  forgot: ForgotReducer,
  common: CommonReducer,
  profile: ProfileReducer,
  home: HomeReducer,
  myOrders: MyOrdersReducer,
  address_list: AddressListReducer,
  forgotPassword: ForgotPasswordReducer,
  address: AddressReducer,
  contact: ContactReducer,
  roles: RolesReducer,
  permissions: PermissionsReducer,
  partner: PartnerReducer,
  customer: CustomerReducer,
  status: StatusReducer,
  serviceType: ServiceTypeReducer,
});

export default allReducers;
