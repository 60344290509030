/** @format */

import * as React from "react";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import * as colors from "../../assets/css/Colors";
import { useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { deleteRole, getAllRoles } from "../ordersHistory/services";
import { semiBold } from "../../config/Constants";

export default function DeleteModal({ role }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const styles = localStyles();

  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(false);

  const sendEmail = async () => {
    try {
      setLoading(true);
      const payload = {
        data: role,
      };
      handleClose();
      await deleteRole(dispatch, payload, false);
      setLoading(false);
      toast.success("role deleted successfully");
      await getAllRoles(dispatch, 0, 50);
    } catch (e) {
      toast.error(
        "There is a problem while deleting this role. Please try again"
      );
    }
  };

  return (
    <>
      {loading ? (
        <CircularProgress className={styles.circularProgress} />
      ) : (
        <div>
          <Tooltip title='Delete Role'>
            <DeleteIcon className={styles.infoIcon} onClick={handleOpen} />
          </Tooltip>
          <Modal open={open} onClose={handleClose}>
            <div className={styles.modal}>
              <span className={styles.modalHead}>
                Are you sure you want to delete this role?
              </span>
              <div className={styles.modalButtons}>
                <button className={styles.yesButton} onClick={sendEmail}>
                  Yes
                </button>
                <button className={styles.noButton} onClick={handleClose}>
                  No
                </button>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
}

const localStyles = makeStyles((theme) => {
  return {
    infoIcon: {
      "&.MuiSvgIcon-root": {
        color: colors.theme_fg,
        background: colors.icon_color,
        borderRadius: "5px",
        padding: "6px",
        cursor: "pointer",
        marginLeft: 10,
      },
    },
    modalConfirmButton: {
      marginRight: "30px",
      marginLeft: "150px",
      backgroundColor: "#C90000",
    },
    circularProgress: {
      marginLeft: "10px",
    },
    modalRejectButton: {
      backgroundColor: "#C90000",
    },
    buttonBox: {
      marginTop: "30px",
    },
    addCustomer: {
      backgroundColor: "#C90000",
      color: "white",
      border: "none",
      fontSize: "15px",
      padding: "6px",
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
    },
    modal: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "400px",
      background: colors.theme_fg,
      borderRadius: "5px",
      position: "absolute",
      outline: "none",
      padding: "40px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        width: "270px",
      },
    },
    modalHead: {
      fontWeight: semiBold,
      fontSize: "20px",
      marginBottom: "20px",
    },
    modalButtons: {
      marginTop: "20px",
      display: "flex",
    },
    yesButton: {
      padding: "15px 50px",
      background: colors.icon_color,
      color: colors.text_white,
      cursor: "pointer",
      border: "none",
      borderRadius: "5px",
      marginRight: "20px",
      fontSize: "20px",
    },
    noButton: {
      padding: "15px 50px",
      background: colors.dark_gray,
      color: colors.text_white,
      cursor: "pointer",
      border: "none",
      borderRadius: "5px",
      fontSize: "20px",
    },
  };
});
