/** @format */

import * as Actions from "../actions/ActionTypes";
const CustomerReducer = (
  state = {
    isLoading: false,
    customerAddErrors: undefined,
    customersCount: 0,
    customersList: [],
    getAllCustomersLoading: false,
    getAllCustomersErrors: undefined,
    deleteCustomerLoading: false,
    customerDeleteErrors: undefined,
    selectedRowId: undefined,
    selectedCustomerId: undefined,
    selectedTab: undefined,
    selectedCustomerTab: undefined,
    customerOrder: undefined,
  },
  action
) => {
  switch (action.type) {
    case Actions.GET_ALL_CUSTOMERS_PENDING:
    case Actions.ADD_CUSTOMER_PENDING: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }
    case Actions.GET_ALL_CUSTOMERS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        customersCount: action.data.totalCustomers,
        customersList: action.data.customerList,
      });
    case Actions.GET_ALL_CUSTOMERS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });

    case Actions.ADD_CUSTOMER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case Actions.RESET_ERROR:
      return Object.assign({}, state, {
        customerAddErrors: undefined,
      });
    case Actions.ADD_CUSTOMER_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        customerAddErrors: action.error,
      });
    case Actions.DELETE_CUSTOMER_PENDING: {
      return Object.assign({}, state, {
        deleteCustomerLoading: true,
      });
    }
    case Actions.DELETE_CUSTOMER_SUCCESS:
      return Object.assign({}, state, {
        deleteCustomerLoading: false,
      });
    case Actions.DELETE_CUSTOMER_ERROR:
      return Object.assign({}, state, {
        deleteCustomerLoading: false,
        customerDeleteErrors: action.error,
      });
    case Actions.SET_SELECTED_ROW:
      return Object.assign({}, state, {
        selectedRowId: action.data,
      });
    case Actions.DISCARD_SELECTED_ROW:
      return Object.assign({}, state, {
        selectedRowId: undefined,
      });
    case Actions.SET_SELECTED_CUSTOMER:
      return Object.assign({}, state, {
        selectedCustomerId: action.data,
      });
    case Actions.DISCARD_SELECTED_CUSTOMER:
      return Object.assign({}, state, {
        selectedCustomerId: undefined,
      });
    case Actions.SET_SELECTED_TAB:
      return Object.assign({}, state, {
        selectedTab: action.data,
      });
    case Actions.RESET_SELECTED_TAB:
      return Object.assign({}, state, {
        selectedTab: undefined,
      });
    case Actions.SET_SELECTED_CUSTOMER_TAB:
      return Object.assign({}, state, {
        selectedCustomerTab: action.data,
      });
    case Actions.RESET_SELECTED_CUSTOMER_TAB:
      return Object.assign({}, state, {
        selectedCustomerTab: undefined,
      });
    case Actions.GET_CUSTOMER_ORDER_SUCCESS:
      return Object.assign({}, state, {
        customerOrder: action.data,
      });
    case Actions.GET_CUSTOMER_ORDER_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        customerOrder: undefined,
      });
    default:
      return state;
  }
};

export default CustomerReducer;
