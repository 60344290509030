import * as ActionTypes from "./ActionTypes";

export const listServiceActionPending = () => ({
  type: ActionTypes.ADDRESS_LIST_PENDING,
});

export const listServiceActionError = error => ({
  type: ActionTypes.ADDRESS_LIST_ERROR,
  error: error,
});

export const listServiceActionSuccess = data => ({
  type: ActionTypes.ADDRESS_LIST_SUCCESS,
  data: data,
});

export const deleteServiceActionPending = () => ({
  type: ActionTypes.ADDRESS_DELETE_PENDING,
});

export const deleteServiceActionError = error => ({
  type: ActionTypes.ADDRESS_DELETE_ERROR,
  error: error,
});

export const deleteServiceActionSuccess = data => ({
  type: ActionTypes.ADDRESS_DELETE_SUCCESS,
  data: data,
});

export const getCustomerOrServiceAddressId = (addressInfo, addressType) => ({
  type: ActionTypes.GET_CUSTOMER_SERVICE_ADDRESS_ID,
  addressInfo,
  addressType,
});

export const locationPermissionDenied=(isLocationPermissionDenied)=>({
  type: ActionTypes.LOCATION_PERMISSION_DENIED,
  isLocationPermissionDenied,
});

export const getNearByServiceAddress=()=>({
  type: ActionTypes.GET_NEAR_BY_SERVICE_ADDRESS
});

export const getNearByServiceAddressSuccess=(nearByServiceAddress)=>({
  type: ActionTypes.GET_NEAR_BY_SERVICE_ADDRESS_SUCCESS,
  nearByServiceAddress
});

export const getNearByServiceAddressError=(error)=>({
  type: ActionTypes.GET_NEAR_BY_SERVICE_ADDRESS_ERROR,
  error
});
