/** @format */

import React from "react";

import { makeStyles } from "@mui/styles";
import * as colors from "../assets/css/Colors";
import PermissionsHistory from "../components/permissionsHistory/PermissionsHistory";

const Permissions = () => {
  const styles = localStyles();

  return (
    <div className={styles.container}>
      <PermissionsHistory height={680} type={"Permissions"} />
    </div>
  );
};

const localStyles = makeStyles((theme) => {
  return {
    container: {
      padding: "30px",
      display: "grid",
      gridTemplateColumns: "35% 35% 30%",
      gridRowGap: "40px",
      [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column",
        gridRowGap: "20px",
        padding: "30px 20px 40px",
      },
      [theme.breakpoints.between("md", "lg")]: {
        gridRowGap: "20px",
        gridTemplateRows: "85px 717px",
        padding: "50px 20px 40px",
      },
    },
    emptyContainer: {
      background: colors.theme_fg,
      boxShadow: "0px 3px 6px #00000029",
      margin: "0 30px",
      borderRadius: "5px",
    },
  };
});

export default Permissions;
