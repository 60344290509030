import * as ActionTypes from "./ActionTypes";

export const resetPasswordValue = (formValue) => ({
  type: ActionTypes.RESET_PASSWORD_VALUE,
  formValue,
});

export const resetPasswordValueError = (errorValue) => ({
  type: ActionTypes.RESET_PASSWORD_ERROR,
  errorValue,
});

export const onSubmitResetRequest = () => ({
  type: ActionTypes.RESET_PASSWORD_REQUEST,
});
export const onSubmitResetRequestSuccess = (message) => ({
  type: ActionTypes.RESET_PASSWORD_REQUEST_SUCCESS,
  message,
});
export const onSubmitResetRequestError = (error) => ({
  type: ActionTypes.RESET_PASSWORD_REQUEST_ERROR,
  error,
});
