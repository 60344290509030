/** @format */

import * as ActionTypes from "./ActionTypes";

export const discardErrors = () => ({
  type: ActionTypes.DISCARD_ERRORS,
});

export const addStatusPending = () => ({
  type: ActionTypes.ADD_STATUS_PENDING,
});

export const addStatusError = (error) => ({
  type: ActionTypes.ADD_STATUS_ERROR,
  error: error,
});

export const addStatusSuccess = (data) => ({
  type: ActionTypes.ADD_STATUS_SUCCESS,
  data,
});

export const getAllStatusesPending = () => ({
  type: ActionTypes.GET_ALL_STATUSES_PENDING,
});

export const getAllStatusesError = (error) => ({
  type: ActionTypes.GET_ALL_STATUSES_ERROR,
  error: error,
});

export const getAllStatusesSuccess = (data) => ({
  type: ActionTypes.GET_ALL_STATUSES_SUCCESS,
  data,
});

export const deleteStatusPending = () => ({
  type: ActionTypes.DELETE_STATUS_PENDING,
});

export const deleteStatusError = (error) => ({
  type: ActionTypes.DELETE_STATUS_ERROR,
  error: error,
});

export const deleteStatusSuccess = (data) => ({
  type: ActionTypes.DELETE_STATUS_SUCCESS,
  data,
});
