/** @format */

import moment from "moment";

import {
  FULL_NAME_MAX_LENGTH,
  FULL_NAME_MIN_LENGTH,
  COMPANY_NAME_MIN_LENGTH,
  COMPANY_NAME_MAX_LENGTH,
  PHONE_FIELD_LENGTH,
  EMAIL_MIN_LENGTH,
  EMAIL_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  APARTMENT_MAX_LENGTH,
  CITY_MIN_LENGTH,
  CITY_MAX_LENGTH,
  STATE_MIN_LENGTH,
  STATE_MAX_LENGTH,
  POSTAL_CODE_MIN_LENGTH,
  POSTAL_CODE_MAX_LENGTH,
  FULL_STATE_MAX_LENGTH,
  PROMO_CODE_MIN_LENGTH,
  CARD_NICKNAME_MAX_LENGTH,
  CARD_NUMBER_MIN_LENGTH,
  CVC_MIN_LENGTH,
} from "./Constants";
import {
  FULL_NAME_REGEX,
  FULL_NAME_SPECIAL_SYMBOLS_REGEX,
  COMPANY_NAME_REGEX,
  TWO_SPACES_REGEX,
  ONE_SPACES_REGEX,
  NUMBERS_REGEX,
  emailRegex,
  strongPassword,
  APARTMENT_REGEX,
  CITY_REGEX,
  LATIN_LETTERS_ONLY_REGEX,
  numberOnly,
  CARD_NAME_REGEX,
} from "./regex";
import strings from "../languages/strings";

export const VALIDATE_USER_FULL_NAME = (name) => {
  // must not be empty
  if (name === "") {
    return strings.customer_name_empty_err;
  }

  // must not start with space
  if (name.startsWith(" ")) {
    return strings.customer_name_starts_with_space_err;
  }

  // must not end with space
  // if (name.endsWith(" ")) {
  //   return strings.customer_name_ends_with_space_err;
  // }

  // min length
  if (name.trim().length && name.trim().length < FULL_NAME_MIN_LENGTH) {
    return strings.customer_name_min_length_err;
  }

  // max length
  if (name.trim().length && name.trim().length > FULL_NAME_MAX_LENGTH) {
    return strings.customer_name_max_length_err;
  }

  // must not include digits
  if (NUMBERS_REGEX.test(name)) {
    return strings.customer_name_number_err;
  }

  // two spaces in a row
  if (TWO_SPACES_REGEX.test(name)) {
    return strings.customer_name_two_spaces_in_row;
  }

  // must not include special symbols
  if (FULL_NAME_SPECIAL_SYMBOLS_REGEX.test(name)) {
    return strings.customer_name_special_symbols_err;
  }

  // regex for full name
  if (!FULL_NAME_REGEX.test(name)) {
    return strings.customer_name_not_valid_err;
  }

  return "";
};

export const VALIDATE_COMPANY_NAME = (companyName) => {
  if (!companyName.length) {
    return "";
  }

  // must not start with space
  if (companyName.startsWith(" ")) {
    return strings.company_name_starts_with_space_err;
  }

  // must not end with space
  // if (companyName.endsWith(" ")) {
  //   return strings.company_name_ends_with_space_err;
  // }

  // min length
  if (
    companyName.trim().length &&
    companyName.trim().length < COMPANY_NAME_MIN_LENGTH
  ) {
    return strings.company_name_min_length_err;
  }

  // max length
  if (
    companyName.trim().length &&
    companyName.trim().length > COMPANY_NAME_MAX_LENGTH
  ) {
    return strings.company_name_max_length_err;
  }

  // two spaces in a row
  if (TWO_SPACES_REGEX.test(companyName)) {
    return strings.company_name_two_spaces_in_row;
  }

  if (!COMPANY_NAME_REGEX.test(companyName)) {
    return strings.company_name_invalid_err;
  }
};

export const VALIDATE_QUERY = (query) => {
  if (!query.length) return strings.customer_name_empty_err;

  return "";
};

export const VALIDATE_ROLE = (state) => {
  if (state === []) {
    return strings.field_empty_err;
  }
  return "";
};

export const VALIDATE_NOT_EMPTY = (state) => {
  if (state === "") {
    return strings.field_empty_err;
  }
};

export const VALIDATE_PHONE = (phone) => {
  const reg = /^\d+$/;
  if (!phone.length) {
    return strings.empty_phone;
  }
  if (
    !phone
      .toString()
      .replace(/[- )(]/g, "")
      .match(reg)
  ) {
    return strings.only_numeric;
  }
  // Check if you pass only numbers to validate phone field. It should not accept symbols
  if (
    phone.toString().replace(/[- )(]/g, "").length < PHONE_FIELD_LENGTH ||
    phone.toString().replace(/[- )(]/g, "").length > PHONE_FIELD_LENGTH
  ) {
    return strings.phone_number_length_err;
  }

  return "";
};

export const VALIDATE_EMAIL = (email) => {
  if (email === "") {
    return strings.please_enter_email_address;
  }

  // must not start with space
  if (email.startsWith(" ")) {
    return strings.email_starts_with_space_err;
  }

  // must not end with space
  // if (email.endsWith(" ")) {
  //   return strings.email_ends_with_space_err;
  // }

  // must not end with space
  if (email.endsWith(".")) {
    return strings.email_ends_with_dot_err;
  }

  if (ONE_SPACES_REGEX.test(email)) {
    return "Email must not contain spaces";
  }

  if (!email.match(emailRegex)) {
    return strings.wrong_email_format;
  }

  if (email.length < EMAIL_MIN_LENGTH) {
    return strings.email_min_length_err;
  }

  if (email.length > EMAIL_MAX_LENGTH) {
    return strings.email_max_length_err;
  }

  return "";
};

export const VALIDATE_PASSWORD = (password, fromLogin) => {
  if (!password.length) {
    return strings.empty_password;
  }

  if (password.length < PASSWORD_MIN_LENGTH) {
    return strings.password_min_length_err;
  }

  if (password.length > PASSWORD_MAX_LENGTH) {
    return strings.password_max_length_err;
  }

  if (ONE_SPACES_REGEX.test(password)) {
    return strings.password_contain_space_err;
  }

  if (fromLogin) {
    if (!password.match(strongPassword)) {
      return strings.strong_password;
    }
  }

  return "";
};

export const VALIDATE_APARTMENT = (apartment) => {
  if (!apartment.length) {
    return "";
  }

  // must not start with space
  if (apartment.startsWith(" ")) {
    return strings.apartment_starts_space_err;
  }

  // must not end with space
  // if (apartment.endsWith(" ")) {
  //   return strings.apartment_ends_space_err;
  // }

  if (apartment.length > APARTMENT_MAX_LENGTH) {
    return strings.apartment_max_length_err;
  }

  // two spaces in a row
  if (TWO_SPACES_REGEX.test(apartment)) {
    return strings.apartment_two_spaces_err;
  }

  if (!APARTMENT_REGEX.test(apartment)) {
    return strings.apartment_only_latin_letters_err;
  }
};

export const VALIDATE_CITY = (city) => {
  // must not be empty
  if (city === "") {
    return strings.field_empty_err;
  }

  // must not start with space
  if (city.startsWith(" ")) {
    return strings.field_starts_with_space_err;
  }

  // must not end with space
  // if (city.endsWith(" ")) {
  //   return strings.field_ends_with_space_err;
  // }

  if (city.length < CITY_MIN_LENGTH) {
    return strings.field_min_length_2_err;
  }

  if (city.length > CITY_MAX_LENGTH) {
    return strings.field_max_length_28_err;
  }

  // two spaces in a row
  if (TWO_SPACES_REGEX.test(city)) {
    return strings.field_contains_two_spaces_err;
  }

  // must not include digits
  if (NUMBERS_REGEX.test(city)) {
    return strings.field_not_accepts_digits_err;
  }

  if (!CITY_REGEX.test(city)) {
    return strings.field_city_regex_err;
  }

  return "";
};

export const VALIDATE_REQUEST_FORM = (state) => {
  if (state === "") {
    return strings.field_empty_err;
  }

  return "";
};

export const VALIDATE_ROLE_NAME = (state) => {
  if (state === "") {
    return strings.field_empty_err;
  }

  return "";
};

export const VALIDATE_ROLE_SLUG = (state) => {
  if (state === "") {
    return strings.field_empty_err;
  }

  if (ONE_SPACES_REGEX.test(state)) {
    return strings.field_contains_space_err;
  }

  return "";
};

export const VALIDATE_STATE = (state, stateMaxLength = STATE_MAX_LENGTH) => {
  // must not be empty
  if (!state.length) {
    return strings.field_empty_err;
  }

  // must not include digits
  if (NUMBERS_REGEX.test(state)) {
    return strings.field_not_accepts_digits_err;
  }

  if (ONE_SPACES_REGEX.test(state)) {
    return strings.field_contains_space_err;
  }

  // must not include special symbols
  if (FULL_NAME_SPECIAL_SYMBOLS_REGEX.test(state)) {
    return strings.field_contains_spec_symbols_err;
  }

  if (!LATIN_LETTERS_ONLY_REGEX.test(state)) {
    return strings.field_accepts_latin_letters_err;
  }

  if (stateMaxLength > 2) {
    if (state.length < STATE_MIN_LENGTH) {
      return strings.field_min_length_2_err;
    }

    if (state.length > FULL_STATE_MAX_LENGTH) {
      return strings.field_max_length_52_err;
    }
  } else {
    if (state.length < STATE_MIN_LENGTH || state.length > STATE_MAX_LENGTH) {
      return strings.field_min_max_length_2;
    }
  }

  return "";
};

export const VALIDATE_ZIP_CODE = (postalCode) => {
  if (!postalCode.length) {
    return strings.field_empty_err;
  }

  if (
    postalCode.length < POSTAL_CODE_MIN_LENGTH ||
    postalCode.length > POSTAL_CODE_MAX_LENGTH
  ) {
    return strings.field_postal_code_accepts_digits_err;
  }

  if (!numberOnly.test(postalCode)) {
    return strings.pin_code_symbol_error;
  }

  return "";
};

export const VALIDATE_ADDRESS_CARD = (addressName) => {
  if (addressName === undefined) {
    return strings.field_empty_err;
  }
};

export const VALIDATE_ADDRESS_NAME = (addressName) => {
  if (!addressName.length) {
    return strings.field_empty_err;
  }

  // must not start with space
  if (addressName.startsWith(" ")) {
    return strings.field_starts_with_space_err;
  }

  // must not end with space
  // if (addressName.endsWith(" ")) {
  //   return strings.field_ends_with_space_err;
  // }

  // max length
  if (addressName.length > APARTMENT_MAX_LENGTH) {
    return strings.apartment_max_length_err;
  }

  // two spaces in a row
  if (TWO_SPACES_REGEX.test(addressName)) {
    return strings.field_contains_two_spaces_err;
  }

  if (!APARTMENT_REGEX.test(addressName)) {
    return strings.field_accepts_latin_and_symbols;
  }
};

export const VALIDATE_ADDRESS_NOTE = (addressNote, minLength = 0) => {
  if (!addressNote.length && !minLength) {
    return "";
  }

  if (minLength && addressNote.length < minLength) {
    return strings.field_min_length_10_err;
  }

  if (minLength && addressNote.length > EMAIL_MAX_LENGTH) {
    return strings.field_address_note_max_length;
  }

  // must not start with space
  if (addressNote.startsWith(" ")) {
    return strings.field_starts_with_space_err;
  }

  // must not end with space
  // if (addressNote.endsWith(" ")) {
  //   return strings.field_ends_with_space_err;
  // }

  // max length
  if (addressNote.length > EMAIL_MAX_LENGTH) {
    return strings.field_address_note_max_length;
  }

  // two spaces in a row
  if (TWO_SPACES_REGEX.test(addressNote)) {
    return strings.field_contains_two_spaces_err;
  }

  if (!COMPANY_NAME_REGEX.test(addressNote)) {
    return strings.field_address_note_accepts_latin_digits;
  }
};

export const VALIDATE_PROMO_CODE = (promoCode) => {
  if (promoCode.length < PROMO_CODE_MIN_LENGTH) {
    return strings.promo_code_min_length_err;
  }

  if (!APARTMENT_REGEX.test(promoCode)) {
    return strings.promo_code_invalid_err;
  }

  return "";
};

export const VALIDATE_ORDER_NAME = (string = "") => {
  if (!string.length) {
    return "";
  }

  if (string && string.startsWith(" ")) {
    return strings.order_name_starts_with_space_err;
  }

  // if (string && string.endsWith(" ")) {
  //   return strings.order_name_ends_with_space_err;
  // }

  if (TWO_SPACES_REGEX.test(string)) {
    return strings.order_name_two_spaces_in_row;
  }

  if (!APARTMENT_REGEX.test(string)) {
    return strings.order_name_invalid_err;
  }

  return "";
};

export const VALIDATE_ORDER_NOTES = (string = "") => {
  if (!string.length) {
    return "";
  }

  if (!COMPANY_NAME_REGEX.test(string)) {
    return strings.order_notes_invalid_err;
  }

  if (string && string.startsWith(" ")) {
    return strings.order_notes_starts_with_space_err;
  }

  // if (string && string.endsWith(" ")) {
  //   return strings.order_notes_ends_with_space_err;
  // }

  if (TWO_SPACES_REGEX.test(string)) {
    return strings.order_notes_two_spaces_in_row;
  }

  return "";
};

export const VALIDATE_CARD_NICKNAME = (nickname) => {
  if (!nickname.length) {
    return "";
  }

  // must not start with space
  if (nickname.startsWith(" ")) {
    return strings.field_starts_with_space_err;
  }

  // must not end with space
  // if (nickname.endsWith(" ")) {
  //   return strings.field_ends_with_space_err;
  // }

  if (nickname.length > CARD_NICKNAME_MAX_LENGTH) {
    return strings.field_max_length_20_err;
  }

  // two spaces in a row
  if (TWO_SPACES_REGEX.test(nickname)) {
    return strings.field_contains_two_spaces_err;
  }

  if (!APARTMENT_REGEX.test(nickname)) {
    return strings.field_accepts_latin_and_one_space;
  }
};

export const VALIDATE_CARD_NAME = (cardName) => {
  // must not be empty
  if (cardName === "") {
    return strings.field_empty_err;
  }

  // must not start with space
  if (cardName.startsWith(" ")) {
    return strings.field_starts_with_space_err;
  }

  // must not end with space
  // if (cardName.endsWith(" ")) {
  //   return strings.field_ends_with_space_err;
  // }

  // min length
  if (cardName.length < FULL_NAME_MIN_LENGTH) {
    return strings.customer_name_min_length_err;
  }

  // max length
  if (cardName.length > FULL_NAME_MAX_LENGTH) {
    return strings.customer_name_max_length_err;
  }

  // must not include digits
  if (NUMBERS_REGEX.test(cardName)) {
    return strings.field_not_accepts_digits_err;
  }

  // two spaces in a row
  if (TWO_SPACES_REGEX.test(cardName)) {
    return strings.field_contains_two_spaces_err;
  }

  // must not include special symbols
  if (FULL_NAME_SPECIAL_SYMBOLS_REGEX.test(cardName)) {
    return strings.field_contains_spec_symbols_err;
  }

  // regex for full name
  if (!CARD_NAME_REGEX.test(cardName)) {
    return strings.field_accepts_latin_letters_only;
  }

  return "";
};

export const VALIDATE_CARD_NUMBER = (cardNumber) => {
  if (!cardNumber.length) {
    return strings.field_empty_err;
  }

  if (cardNumber.length < CARD_NUMBER_MIN_LENGTH) {
    return "Cannot be less than 16 digits";
  }

  return "";
};

export const VALIDATE_MM = (mm) => {
  if (!mm.length) {
    return strings.field_empty_err;
  }

  const mmNum = parseInt(mm, 10);

  if (mm.length !== 2) {
    return strings.card_mm_only_two_digits_err;
  }

  if (!numberOnly.test(mm)) {
    return strings.field_accepts_digits_err;
  }

  if (mmNum < 1 || mmNum > 12) {
    return strings.card_mm_range_err;
  }

  return "";
};

export const VALIDATE_YY = (yy) => {
  const todayYear = moment().format("YY");
  const maxYear = moment().add(10, "years").format("YY");

  if (!yy.length) {
    return strings.field_empty_err;
  }

  if (yy.length !== 2) {
    return strings.card_mm_only_two_digits_err;
  }

  if (!numberOnly.test(yy)) {
    return strings.field_accepts_digits_err;
  }

  if (yy < todayYear) {
    return strings.card_wrong_year;
  }

  if (yy > maxYear) {
    return strings.card_year_too_big_err;
  }

  return "";
};

export const VALIDATE_CVC = (cvc) => {
  if (!cvc.length) {
    return strings.field_empty_err;
  }

  if (cvc.length < CVC_MIN_LENGTH) {
    return "Cannot be less than 3 digits";
  }

  if (!numberOnly.test(cvc)) {
    return "Only numeric values are allowed";
  }
};

export const VALIDATE_EXPIRY_DATE = (expiryDate) => {
  if (!expiryDate) {
    return strings.field_empty_err;
  }

  if (expiryDate.length < 5) {
    return "Cannot be less than 5 characters";
  }

  if (!numberOnly.test(expiryDate)) {
    return strings.field_accepts_digits_err;
  }
};

export const VALIDATE_DELETE_REASON = (reason) => {
  if (reason.length < PHONE_FIELD_LENGTH) {
    return strings.field_min_length_10_err;
  }

  if (!COMPANY_NAME_REGEX.test(reason)) {
    return strings.reason_invalid_err;
  }

  if (reason.startsWith(" ")) {
    return strings.reason_starts_with_space_err;
  }

  // if (reason.endsWith(" ")) {
  //   return strings.reason_ends_with_space_err;
  // }

  if (TWO_SPACES_REGEX.test(reason)) {
    return strings.reason_two_spaces_in_row;
  }

  return "";
};
