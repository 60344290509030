/** @format */

import * as Actions from "../actions/ActionTypes";
const PermissionsReducer = (
  state = {
    isLoading: false,
    addPermissionLoading: false,
    deletePermissionLoading: false,
    error: undefined,
    permissionList: [],
    permissionCount: 0,
    partnerPermissionList: [],
    partnerPermissionCount: 0,
    permissionAddErrors: undefined,
    permissionDeleteErrors: undefined,
  },
  action
) => {
  switch (action.type) {
    case Actions.GET_PERMISSIONS_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        error: undefined,
        permissionList: [],
        permissionCount: 0,
      });
    case Actions.GET_PERMISSIONS_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        error: undefined,
        permissionList: action.data.permissions,
        permissionCount: action.data.count,
      });
    }
    case Actions.GET_PERMISSIONS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        permissionList: [],
        permissionCount: 0,
      });
    case Actions.GET_PARTNER_PERMISSIONS_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        error: undefined,
        partnerPermissionList: [],
        partnerPermissionCount: 0,
      });
    case Actions.GET_PARTNER_PERMISSIONS_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        error: undefined,
        partnerPermissionList: action.data.permissions,
        partnerPermissionCount: action.data.count,
      });
    }
    case Actions.GET_PARTNER_PERMISSIONS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        partnerPermissionList: [],
        partnerPermissionCount: 0,
      });
    case Actions.ADD_PERMISSION_PENDING: {
      return Object.assign({}, state, {
        addPermissionLoading: true,
      });
    }
    case Actions.ADD_PERMISSION_SUCCESS:
      return Object.assign({}, state, {
        addPermissionLoading: false,
      });
    case Actions.ADD_PERMISSION_ERROR:
      return Object.assign({}, state, {
        addPermissionLoading: false,
        permissionAddErrors: action.error,
      });
    case Actions.DELETE_PERMISSION_PENDING: {
      return Object.assign({}, state, {
        deletePermissionLoading: true,
      });
    }
    case Actions.DELETE_PERMISSION_SUCCESS:
      return Object.assign({}, state, {
        deletePermissionLoading: false,
      });
    case Actions.DELETE_PERMISSION_ERROR:
      return Object.assign({}, state, {
        deletePermissionLoading: false,
        permissionDeleteErrors: action.error,
      });
    default:
      return state;
  }
};

export default PermissionsReducer;
