/** @format */

import axios from "axios";
import { API, GET_INVOICE } from "./Constants";

let header = {
  "Content-Type": "application/json",
};

export const fetchPost = async (url, body) => {
  try {
    const API_URL = `${API}${url}`;

    const userData = localStorage.getItem("user_data");
    const auth_token = localStorage.getItem("auth_token");
    if (userData && auth_token) {
      header = { ...header, token: auth_token };
    }
    const response = await axios.post(API_URL, body, { headers: header });
    if (response.status === 200) return response.data;
    return null;
  } catch (e) {
    return getErrorMessage(e.response);
  }
};

export const fetchGet = async (url) => {
  try {
    const API_URL = `${API}${url}`;

    const userData = localStorage.getItem("user_data");
    const auth_token = localStorage.getItem("auth_token");

    if (userData && auth_token) {
      if (url.includes("getOrderItems")) {
        header = { ...header };
      } else {
        header = { ...header, token: auth_token };
      }
    }

    let response;
    if (url.includes(GET_INVOICE)) {
      response = await axios(
        `${API_URL}`,
        {
          method: "GET",
          responseType: "blob", //Force to receive data in a Blob Format
        },
        { headers: header }
      );
    } else {
      response = await axios.get(API_URL, { headers: header });
    }
    return response.data;
  } catch (e) {
    return getErrorMessage(e.response);
  }
};

export const fetchDelete = async (url) => {
  try {
    const API_URL = `${API}${url}`;
    const userData = localStorage.getItem("user_data");
    if (userData && JSON.parse(userData).auth_token) {
      header = { ...header, token: JSON.parse(userData).auth_token };
    }
    const response = await axios.delete(API_URL, { headers: header });

    if (response.status === 200) return response.data;

    return null;
  } catch (e) {
    if (e.response.data.blocked || e.response.data.logout) {
      localStorage.clear();
      window.location.reload();
    }
    return getErrorMessage(e.response);
  }
};

export const fetchPatch = async (url, body) => {
  try {
    const API_URL = `${API}${url}`;
    const userData = localStorage.getItem("user_data");
    if (userData && JSON.parse(userData).auth_token) {
      header = { ...header, token: JSON.parse(userData).auth_token };
    }
    const response = await axios.patch(API_URL, body, { headers: header });
    if (response.status === 200) return response.data;

    return null;
  } catch (e) {
    if (e.response.data.blocked || e.response.data.logout) {
      localStorage.clear();
      window.location.reload();
    }
    return getErrorMessage(e.response);
  }
};

const getErrorMessage = (errorResponse) => {
  if (
    errorResponse?.data?.error &&
    typeof errorResponse?.data?.error !== "object"
  )
    return { error: errorResponse?.data?.error };
  if (errorResponse?.data?.raw?.message)
    return { error: errorResponse?.data?.raw?.message };
  return { error: "Error. Please try again." };
};
