import * as Actions from "../actions/ActionTypes";
const ForgotPasswordReducer = (
  state = {
    error: undefined,
    status: undefined,
    isLoading: false,
    forgotPasswordForm: {
      new_password: "",
      confirm_password: "",
    },
    forgotPasswordError: {
      new_password: "",
      confirm_password: "",
      api_error:"",
    },
  },
  action,
) => {
  switch (action.type) {
    case Actions.FORGOT_PASSWORD_VALUE:
      return Object.assign({}, state, {
        forgotPasswordForm: action.formValue,
      });
    case Actions.FORGOT_PASSWORD_ERROR:
      return Object.assign({}, state, {
        forgotPasswordError: action.errorValue,
      });
    case Actions.FORGOT_PASSWORD_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case Actions.FORGOT_PASSWORD_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        forgotPasswordForm: {
          new_password: "",
          confirm_password: "",
        },
      });
    case Actions.FORGOT_PASSWORD_REQUEST_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
};

export default ForgotPasswordReducer;
