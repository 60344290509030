/** @format */

import * as Actions from "../actions/ActionTypes";
const PartnerReducer = (
  state = {
    isLoading: false,
    partnerAddErrors: undefined,
    partnersCount: 0,
    partnersList: [],
    getAllPartnersLoading: false,
    getAllPartnersErrors: undefined,
  },
  action
) => {
  switch (action.type) {
    case Actions.ADD_PARTNER_PENDING: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }
    case Actions.DISCARD_ERRORS: {
      return Object.assign({}, state, {
        partnerAddErrors: undefined,
      });
    }
    case Actions.ADD_PARTNER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case Actions.ADD_PARTNER_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        partnerAddErrors: action.error,
      });
    case Actions.GET_ALL_PARTNERS_PENDING: {
      return Object.assign({}, state, {
        getAllPartnersLoading: true,
      });
    }
    case Actions.GET_ALL_PARTNERS_SUCCESS:
      return Object.assign({}, state, {
        getAllPartnersLoading: false,
        partnersCount: action.data.partnerCount,
        partnersList: action.data.partners,
      });
    case Actions.GET_ALL_PARTNERS_ERROR:
      return Object.assign({}, state, {
        getAllPartnersLoading: false,
        getAllPartnersErrors: action.error,
      });
    default:
      return state;
  }
};

export default PartnerReducer;
