import * as Actions from "../actions/ActionTypes";
const AuthReducer = (state = { isAuth: false, screenName: "Home" }, action) => {
  switch (action.type) {
    case Actions.IS_AUTH:
      return Object.assign({}, state, {
        isAuth: false,
      });
    case Actions.IS_AUTH_SUCCESS:
      return Object.assign({}, state, {
        isAuth: true,
      });
    case Actions.IS_AUTH_ERROR:
      return Object.assign({}, state, {
        isAuth: false,
      });
    case Actions.INIT_SCREEN_NAME:
      return Object.assign({}, state, {
        screenName: action.screenName,
      });
    default:
      return state;
  }
};

export default AuthReducer;
