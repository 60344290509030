import * as Actions from "../actions/ActionTypes"
const ForgotReducer = (state = { isLoading: false, error: undefined, data: undefined, message: undefined, status: undefined }, action) => {
    switch (action.type) {
        case Actions.FORGOT_SERVICE_PENDING:
            return Object.assign({}, state, {
                isLoading: true,
            });
        case Actions.FORGOT_SERVICE_ERROR:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error
            });
        case Actions.FORGOT_SERVICE_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
            });

        default:
            return state;
    }
}

export default ForgotReducer;
