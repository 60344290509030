/** @format */

import * as Actions from "../actions/ActionTypes";
const StatusReducer = (
  state = {
    isLoading: false,
    statusAddErrors: undefined,
    statusCount: 0,
    statusList: [],
    getAllStatusLoading: false,
    getAllStatusErrors: undefined,
    deleteStatusLoading: false,
    statusDeleteErrors: undefined,
  },
  action
) => {
  switch (action.type) {
    case Actions.ADD_STATUS_PENDING: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }
    case Actions.DISCARD_ERRORS: {
      return Object.assign({}, state, {
        statusAddErrors: undefined,
        getAllStatusErrors: undefined,
        statusDeleteErrors: undefined,
      });
    }
    case Actions.ADD_STATUS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case Actions.ADD_STATUS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        statusAddErrors: action.error,
      });
    case Actions.GET_ALL_STATUSES_PENDING: {
      return Object.assign({}, state, {
        getAllStatusLoading: true,
      });
    }
    case Actions.GET_ALL_STATUSES_SUCCESS:
      return Object.assign({}, state, {
        getAllStatusLoading: false,
        statusCount: action.data.data.length,
        statusList: action.data.data,
      });
    case Actions.GET_ALL_STATUSES_ERROR:
      return Object.assign({}, state, {
        getAllStatusLoading: false,
        statusCount: 0,
        getAllStatusErrors: action.error,
      });
    case Actions.DELETE_STATUS_PENDING: {
      return Object.assign({}, state, {
        deleteStatusLoading: true,
      });
    }
    case Actions.DELETE_STATUS_SUCCESS:
      return Object.assign({}, state, {
        deleteStatusLoading: false,
      });
    case Actions.DELETE_STATUS_ERROR:
      return Object.assign({}, state, {
        deleteStatusLoading: false,
        statusDeleteErrors: action.error,
      });
    default:
      return state;
  }
};

export default StatusReducer;
