/** @format */

import * as ActionTypes from "./ActionTypes";

export const toggleModal = () => ({
  type: ActionTypes.TOGGLE_MODAL,
});

export const notesToggleModal = () => ({
  type: ActionTypes.NOTES_TOGGLE_MODAL,
});

export const getBannerImages = () => ({
  type: ActionTypes.GET_BANNER_IMAGES,
});

export const getBannerImagesSuccess = (bannerList) => ({
  type: ActionTypes.GET_BANNER_IMAGES_SUCCESS,
  bannerList,
});

export const getBannerImagesError = (error) => ({
  type: ActionTypes.GET_BANNER_IMAGES_ERROR,
  error,
});

export const setDropdownOpen = (isDropdownOpen) => ({
  type: ActionTypes.SET_DROPDOWN_OPEN,
  isDropdownOpen,
});

export const setDropdownSetValue = (isDropdownSetValue) => ({
  type: ActionTypes.SET_DROPDOWN_VALUE,
  isDropdownSetValue,
});

export const onSubmitFeatureRequest = () => ({
  type: ActionTypes.ON_SUBMIT_FEATURE_REQUEST,
});
export const onSubmitFeatureRequestSuccess = (mesage) => ({
  type: ActionTypes.ON_SUBMIT_FEATURE_REQUEST_SUCCESS,
  mesage,
});
export const onSubmitFeatureRequestError = (error) => ({
  type: ActionTypes.ON_SUBMIT_FEATURE_REQUEST_ERROR,
  error,
});
export const featureRequestFormValue = (formValue) => ({
  type: ActionTypes.FEATURE_REQUEST_FORM_VALUE,
  formValue,
});
export const clearFeatureRequestFormValue = () => ({
  type: ActionTypes.CLEAR_FEATURE_REQUEST_FORM_VALUE,
});

export const featureRequestFormError = (errorValue) => ({
  type: ActionTypes.FEATURE_REQUEST_FORM_ERROR,
  errorValue,
});

export const isInputTrue = () => ({
  type: ActionTypes.IS_INPUT_TRUE,
});
export const isInputFalse = () => ({
  type: ActionTypes.IS_INPUT_FALSE,
});

export const getFormCopy = () => ({
  type: ActionTypes.GET_FORM_COPY,
});
export const getFormCopySuccess = (formCopyList) => ({
  type: ActionTypes.GET_FORM_COPY_SUCCESS,
  formCopyList,
});
export const getFormCopyError = (error) => ({
  type: ActionTypes.GET_FORM_COPY_ERROR,
  error,
});

export const getFormCopyByName = () => ({
  type: ActionTypes.GET_FORM_COPY_BY_NAME,
});
export const getFormCopyByNameSuccess = (formCopy) => ({
  type: ActionTypes.GET_FORM_COPY_BY_NAME_SUCCESS,
  formCopy,
});
export const getFormCopyByNameError = (error) => ({
  type: ActionTypes.GET_FORM_COPY_BY_NAME_ERROR,
  error,
});

export const setCustomerNotes = (customerData) => ({
  type: ActionTypes.CUSTOMER_NOTES_DATA,
  customerData,
});

export const getPrivacyPolicy = () => ({
  type: ActionTypes.GET_PRIVACY_POLICY,
});
export const getPrivacyPolicySuccess = (privacyData) => ({
  type: ActionTypes.GET_PRIVACY_POLICY_SUCCESS,
  privacyData,
});
export const getPrivacyPolicyError = (error) => ({
  type: ActionTypes.GET_PRIVACY_POLICY_ERROR,
  error,
});

export const getTermsCondition = () => ({
  type: ActionTypes.GET_TERMS_CONDITION,
});
export const getTermsConditionSuccess = (termsData) => ({
  type: ActionTypes.GET_TERMS_CONDITION_SUCCESS,
  termsData,
});
export const getTermsConditionError = (error) => ({
  type: ActionTypes.GET_TERMS_CONDITION_ERROR,
  error,
});
export const isFromNotification = (notificationStatus) => ({
  type: ActionTypes.IS_FROM_NOTIFICATION,
  notificationStatus,
});
export const goToOrderTab = (screenStatus) => ({
  type: ActionTypes.GO_TO_ORDER_TAB,
  screenStatus,
});

export const getNotificationsPending = () => ({
  type: ActionTypes.GET_NOTIFICATIONS_PENDING,
});

export const getNotificationsSuccess = (data) => ({
  type: ActionTypes.GET_NOTIFICATIONS_SUCCESS,
  data,
});

export const getNotificationsError = (error) => ({
  type: ActionTypes.GET_NOTIFICATIONS_ERROR,
  error,
});

export const getNotificationsOnClickPending = () => ({
  type: ActionTypes.GET_NOTIFICATIONS_ON_CLICK_PENDING,
});

export const getNotificationsOnClickSuccess = (data) => ({
  type: ActionTypes.GET_NOTIFICATIONS_ON_CLICK_SUCCESS,
  data,
});

export const getNotificationsOnClickError = (error) => ({
  type: ActionTypes.GET_NOTIFICATIONS_ON_CLICK_ERROR,
  error,
});

export const getUnseenNotificationCountSuccess = (data) => ({
  type: ActionTypes.GET_NOTIFICATION_COUNT_SUCCESS,
  data,
});

export const getUnseenNotificationCountError = (error) => ({
  type: ActionTypes.GET_NOTIFICATION_COUNT_ERROR,
  error,
});

export const updateNotificationCountSuccess = (data) => ({
  type: ActionTypes.UPDATE_NOTIFICATION_COUNT_SUCCESS,
  data,
});

export const updateNotificationCountError = (error) => ({
  type: ActionTypes.UPDATE_NOTIFICATION_COUNT_ERROR,
  error,
});

export const setLoadMore = (data) => ({
  type: ActionTypes.SET_LOAD_MORE,
  data,
});

export const sendMessageSuccess = (data) => ({
  type: ActionTypes.SEND_MESSAGE_SUCCESS,
  data,
});

export const sendMessageError = () => ({
  type: ActionTypes.SEND_MESSAGE_ERROR,
});

export const receiveMessageSuccess = (data) => ({
  type: ActionTypes.RECEIVE_MESSAGE_SUCCESS,
  data,
});

export const receiveMessageError = (error) => ({
  type: ActionTypes.RECEIVE_MESSAGE_ERROR,
  error,
});

export const getMessagesByOrderIdPending = () => ({
  type: ActionTypes.GET_MESSAGE_BY_ORDER_ID_PENDING,
});

export const getMessagesByOrderIdSuccess = (data) => ({
  type: ActionTypes.GET_MESSAGE_BY_ORDER_ID_SUCCESS,
  data,
});

export const getMessagesByOrderIdError = (error) => ({
  type: ActionTypes.GET_MESSAGE_BY_ORDER_ID_ERROR,
  error,
});

export const logoutApiCall = () => ({
  type: ActionTypes.LOGOUT_API_CALL,
});

export const logoutApiSuccess = (data) => ({
  type: ActionTypes.LOGOUT_API_SUCCESS,
  data,
});
export const logoutApiError = (error) => ({
  type: ActionTypes.LOGOUT_API_ERROR,
  error,
});

export const clearNotificationsPending = () => ({
  type: ActionTypes.CLEAR_NOTIFICATIONS_PENDING,
});

export const clearNotificationsSuccess = (data) => ({
  type: ActionTypes.CLEAR_NOTIFICATIONS_SUCCESS,
  data,
});

export const clearNotificationsError = (error) => ({
  type: ActionTypes.CLEAR_NOTIFICATIONS_ERROR,
  error,
});

export const updateUnseenMsgCount = (data) => ({
  type: ActionTypes.UPDATE_UNSEEN_MSG_COUNT,
  data,
});

export const openedOrder = (data) => ({
  type: ActionTypes.OPENED_ORDER,
  data,
});

export const roomJoin = () => ({
  type: ActionTypes.JOIN_ROOM,
});

export const leaveRoom = () => ({
  type: ActionTypes.LEAVE_ROOM,
});

export const setActiveMenu = (data) => ({
  type: ActionTypes.SET_ACTIVE_MENU,
  data,
});
